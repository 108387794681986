/**
 * Evaluates if the message event input was fired by Devtools, returns errors as false.
 * @param e Message Event, either fired by Redux Devtools or the Window
 * @returns Boolean, True if the message event was fired by Redux Devtools
 */
export function isDevtoolsMessage(e: MessageEvent): boolean {
  const messageSource = e.data?.source;
  const devToolsRegex = new RegExp(/devtools/i);
  return devToolsRegex.test(messageSource);
}
