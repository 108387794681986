import L from "leaflet";
import { Marker, Polyline, Tooltip } from "react-leaflet";
import { useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";
import { selectNetworkCreator } from "./networkCreatorSlice";
import { NetworkAsset, LatLngObj } from "../../models/models";
import { matchNetworkAsset } from "../../utils/networkUtils";
import { iconMarker } from "../../utils/iconMarker";

const tools = {
  markers: ["meter", "cabinet"],
  polys: ["new-domestic", "new-non-domestic", "new-mixed", "cable", "site"],
};

interface IHints {
  currentLatLng: LatLngObj;
  withinBounds: boolean;
}

const Hints = ({ currentLatLng, withinBounds }: IHints) => {
  const app = useAppSelector(selectApp);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { mouseOverControl } = app;
  const { network, vertices } = networkCreator;
  const activeTool = networkCreator.activeTool;

  const getIconSize = (network: NetworkAsset[], latlng: LatLngObj) => {
    const marker = matchNetworkAsset(network, latlng);
    return marker && marker.name ? 1 : 1;
  };

  const stylePolyIcon = () => {
    const iconSize = getIconSize(network, currentLatLng as LatLngObj);
    const iconDanger =
      ["site", "cable"].includes(activeTool as string) || withinBounds
        ? ""
        : "iconDanger";
    return iconMarker(iconSize, `iconBorder iconGlow ${iconDanger}`);
  };

  const styleMarkerIcon = () => {
    const iconDanger = withinBounds ? "" : "iconDanger";
    return iconMarker(1, `iconBorder iconGlow ${iconDanger}`, activeTool);
  };

  const segmentLength = () => {
    const lastVertex = L.latLng(vertices[vertices.length - 1]);
    const _currentLatLng = L.latLng(currentLatLng);

    return lastVertex.distanceTo(_currentLatLng).toFixed(1);
  };

  return (
    <>
      {!mouseOverControl && currentLatLng && (
        <>
          {tools.markers.includes(activeTool as string) && (
            <Marker position={currentLatLng} icon={styleMarkerIcon()}></Marker>
          )}
          {tools.polys.includes(activeTool as string) && (
            <>
              {vertices.length > 0 && (
                <Polyline
                  positions={[vertices[vertices.length - 1], currentLatLng]}
                  dashArray="5 10"
                ></Polyline>
              )}
              <Marker
                position={currentLatLng}
                icon={stylePolyIcon()}
                zIndexOffset={1000}
              >
                {vertices.length > 0 && (
                  <Tooltip offset={[20, 0]} opacity={1} permanent>
                    {segmentLength()}m
                  </Tooltip>
                )}
              </Marker>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Hints;
