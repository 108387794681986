import { ButtonGroup, Button, Modal } from "react-bootstrap";

import { useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";
import { isEmpty } from "../../utils/isEmpty";

const DialogModal = ({
  affirmDialogAction,
  dismissDialogAction,
}: {
  affirmDialogAction: (props?: any[]) => void;
  dismissDialogAction: () => void;
}) => {
  const app = useAppSelector(selectApp);

  const { affirmLabel, dismissLabel, messages, props } = app.dialog;

  const affirmAction = () => {
    affirmLabel && affirmDialogAction(props);
  };

  const dismissAction = () => {
    dismissLabel && dismissDialogAction();
  };

  return (
    <Modal show={!isEmpty(app.dialog)} onHide={() => {}} backdrop="static">
      <Modal.Body
        className="bg-warning text-light d-flex justify-content-between"
        style={{ fontSize: ".875rem", fontWeight: "normal" }}
      >
        {messages && messages.length > 1 ? (
          <ul>
            {messages.map((message) => (
              <li>{message.description}</li>
            ))}
          </ul>
        ) : (
          messages && messages[0].description
        )}
        <ButtonGroup>
          {affirmLabel && (
            <Button variant="outline-light" size="sm" onClick={affirmAction}>
              {affirmLabel}
            </Button>
          )}
          <Button variant="outline-light" size="sm" onClick={dismissAction}>
            {dismissLabel || "Close"}
          </Button>
        </ButtonGroup>
      </Modal.Body>
    </Modal>
  );
};

export default DialogModal;
