export type Unit = "m" | "A" | "kVA" | "MVA" | "%" | "mΩ" | "V" | "kW";

export const formatString = (property: string | null | undefined): string => {
  if (property == null || property === undefined) {
    return "Null";
  }

  return property;
};

export const formatBoolean = (property: boolean | null | undefined): string => {
  if (property === null || property === undefined) {
    return "Null";
  }

  return property ? "True" : "False";
};

export const formatNumber = (
  property: number | null | undefined,
  decimalPlaces: number,
  unit: Unit | null
): string => {
  if (property === null || property === undefined) {
    return "Null";
  }

  const multiplier = Math.pow(10, decimalPlaces);
  const rounded = Math.round(property * multiplier) / multiplier;
  const roundedProperty = rounded.toFixed(decimalPlaces);

  if (unit === null) {
    return roundedProperty;
  }

  return `${roundedProperty} ${unit}`;
};

export const formatDecimalPlaces = (
  property: number,
  decimalPlaces: number
): string => {
  const multiplier = Math.pow(10, decimalPlaces);
  const rounded = Math.round(property * multiplier) / multiplier;
  return rounded.toFixed(decimalPlaces);
};

export const stringPhasingToNumber = (phasing: string): number => {
  switch (phasing.toLowerCase()) {
    case "one":
      return 1;
    case "three":
      return 3;
    default:
      return 0;
  }
};
