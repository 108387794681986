import { LeafletMouseEvent } from "leaflet";
import { useMapEvents } from "react-leaflet";

import { point, Units } from "@turf/helpers";
import destination from "@turf/destination";
import { useAppDispatch } from "../../../app/hooks";
import { v4 as uuid } from "uuid";
import { addShape } from "../quotationPlanSlice";
import { Position } from "geojson";

const size = 5;

const DrawShape = ({ type }: { type: string }) => {
  const dispatch = useAppDispatch();

  const insulatedEnd = (e: LeafletMouseEvent) => {
    const _point = point([e.latlng.lng, e.latlng.lat]);
    const distance = size;
    const options: { units: Units } = { units: "meters" };

    const coords: Position[] = [];
    [0, -120, 120].forEach((bearing) => {
      const _destination = destination(_point, distance, bearing, options);
      coords.push([
        _destination.geometry.coordinates[1],
        _destination.geometry.coordinates[0],
      ]);
    });

    dispatch(
      addShape({
        id: uuid(),
        positions: [coords],
        type: "insulated-end",
        color: "purple",
      })
    );
  };

  const joint = (e: LeafletMouseEvent) => {
    const _point = point([e.latlng.lng, e.latlng.lat]);
    const distance = size;
    const options: { units: Units } = { units: "meters" };

    const coords: Position[] = [];
    [-135, -45, 45, 135].forEach((bearing) => {
      const _destination = destination(_point, distance, bearing, options);
      coords.push([
        _destination.geometry.coordinates[1],
        _destination.geometry.coordinates[0],
      ]);
    });

    dispatch(
      addShape({
        id: uuid(),
        positions: [coords],
        type: "joint",
        color: "black",
      })
    );
  };

  const circle = (e: LeafletMouseEvent) => {
    const _point = point([e.latlng.lng, e.latlng.lat]);
    const distance = size;
    const options: { units: Units } = { units: "meters" };

    const coords: Position[] = [];

    for (let bearing = -180; bearing <= 180; bearing += 0.01) {
      const _destination = destination(_point, distance, bearing, options);
      coords.push([
        _destination.geometry.coordinates[1],
        _destination.geometry.coordinates[0],
      ]);
    }

    dispatch(
      addShape({
        id: uuid(),
        positions: [coords],
        type: "circle",
        color: "black",
      })
    );
  };

  const disconnectedCable = (e: LeafletMouseEvent, length: number) => {
    const _point = point([e.latlng.lng, e.latlng.lat]);
    const options: { units: Units } = { units: "meters" };

    const allCoords: Position[][] = [];

    const disconnectedCableLines =
      length === 5 ? [4, 2, 0, -2, -4] : [8, 6, 4, 2, 0, -2, -4, -6, -8];

    disconnectedCableLines.forEach((f) => {
      const position = destination(_point, f, 0, options);

      const coords: Position[] = [];
      [
        { bearing: -70, distance: size * 0.6 },
        { bearing: 105, distance: size * 0.6 },
        { bearing: 115, distance: size * 0.6 },
        { bearing: -80, distance: size * 0.6 },
      ].forEach((coord) => {
        const _destination = destination(
          position,
          coord.distance,
          coord.bearing,
          options
        );
        coords.push([
          _destination.geometry.coordinates[1],
          _destination.geometry.coordinates[0],
        ]);
      });
      allCoords.push(coords);
    });

    dispatch(
      addShape({
        id: uuid(),
        positions: allCoords,
        type: "disconnectedCable",
        color: "purple",
      })
    );
  };

  useMapEvents({
    click(e) {
      switch (type) {
        case "insulated-end":
          return insulatedEnd(e);
        case "short-disconnected-cable":
          return disconnectedCable(e, 5);
        case "long-disconnected-cable":
          return disconnectedCable(e, 9);
        case "joint":
          return joint(e);
        case "circle":
          return circle(e);
        default:
          return;
      }
    },
  });

  return null;
};

export default DrawShape;
