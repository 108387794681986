import { useState } from "react";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

interface ISchematicsModal {
  pages: number;
  onSubmit: (pageNum: number) => void;
}

const SchematicsModal = ({ pages, onSubmit }: ISchematicsModal) => {
  const [pageNum, setPageNum] = useState<number>(1);

  const handleClose = () => {
    onSubmit(pageNum);
  };

  return (
    <Modal size="sm" show={pages > 1} onHide={handleClose}>
      <Modal.Body>
        <Form.Row>
          <Col xs="6">
            <Form.Label>Select Page</Form.Label>
          </Col>
          <Col xs="auto">
            <Form.Control
              type="number"
              size="sm"
              min={1}
              max={pages}
              value={pageNum}
              autoFocus
              onChange={(e) => setPageNum(parseInt(e.target.value))}
            />
          </Col>
          <Col xs="3">
            <Button variant="primary" size="sm" onClick={handleClose}>
              Submit
            </Button>
          </Col>
        </Form.Row>
      </Modal.Body>
    </Modal>
  );
};

export default SchematicsModal;
