import { useMap } from "react-leaflet";
import { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  AppMode,
  selectApp,
  setAppMode,
  setSubmit,
  updateDialog,
} from "../../app/appSlice";
import {
  selectNetworkCreator,
  replaceNetwork,
} from "../../features/networkCreator/networkCreatorSlice";
import { NetworkAsset } from "../../models/models";
import {
  getPackage,
  getPackageWithoutAssessment,
} from "../../services/lvAceService";
import "leaflet-simple-map-screenshoter";
import { completeNetworkPolygons } from "../../utils/networkUtils";
import useNetwork from "../../hooks/useNetwork";
import Spinner from "../spinner/Spinner";
import useScreenshot from "../../hooks/useScreenshot";
import { selectQuotationPlan } from "../../features/quotationPlan/quotationPlanSlice";
import { selectExistingNetwork } from "../../features/existingNetwork/existingNetworkSlice";
import { usePersistor } from "../../hooks/usePersistor";
import { saveStudyToWebEnquiryReference } from "../../services/persistService";
import {
  Feature,
  PowerTransformerMounting,
  UserRole,
} from "../../models/enums";
import { isFeatureEnabled } from "../../services/featureSwitchService";
import { useQuotationPlanScreenshot } from "../../hooks/useQuotationPlanScreenshot";
import { fileToBase64 } from "../../services/screenshotService";
import { useStudyResult } from "../../hooks/useStudyResult";
import { ConnectLite2Response } from "../../models/connectLite2Response";

const RequestBuilder = () => {
  const { takeScreenshot } = useScreenshot();
  const { isNetworkConnected } = useNetwork();
  const map = useMap();
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const quotationPlan = useAppSelector(selectQuotationPlan);

  const {
    submit,
    calculate,
    inputs,
    lvAceMessages,
    errorMsgs,
    appMode,
    lvAceActive,
  } = app;
  const { userRole } = inputs;
  const { quotationPlanScreenshot } = quotationPlan;
  const {
    network,
    intersectingSegments,
    radii,
    topographies,
    ragConductingSectionsAndPoles,
  } = networkCreator;
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const { powerTransformers } = existingNetwork;
  const poleMountedTransformers = powerTransformers.filter(
    (powerTransformer) =>
      powerTransformer.properties?.Mounting === PowerTransformerMounting.pmt
  );
  const { saveNetworkState } = usePersistor();
  const { saveStudyResult } = useStudyResult();
  const prevIsNetworkConnectedRef = useRef<number>(0);
  const [isProcessedStudy, setIsProcessedStudy] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { generateQuotationPlanScreenshot } = useQuotationPlanScreenshot(map);

  useEffect(() => {
    if (
      (isNetworkConnected > 0 && prevIsNetworkConnectedRef.current > 0) ||
      appMode === AppMode.screenshot ||
      appMode === AppMode.loading
    )
      return;
    if (appMode === AppMode.exitQuotationPlan) {
      dispatch(setAppMode(undefined));
      return;
    }
    isNetworkConnected && calculateStudy(false);
    prevIsNetworkConnectedRef.current = isNetworkConnected;
  }, [isNetworkConnected]);

  useEffect(() => {
    if (appMode === AppMode.screenshot) return;
    if (appMode === AppMode.exitQuotationPlan) {
      dispatch(setAppMode(undefined));
      return;
    }
    calculate && calculateStudy(true);
  }, [calculate]);

  useEffect(() => {
    submit && requestHandler();
  }, [submit]);

  const hasExistingPoleCheck = (response: any) => {
    const hasExistingPole =
      response.connectLite2Response.processedStudy.network.find(
        (n: any) => n.pointOfConnectionType === "existingpole"
      );

    if (!hasExistingPole) return;
    const connectedPole = poleMountedTransformers.find(
      (o) =>
        o.id === hasExistingPole.firstConnectedAsset ||
        o.id === hasExistingPole.secondConnectedAsset
    );

    if (!connectedPole) return;

    if (connectedPole) {
      dispatch(
        updateDialog({
          props: [],
          type: "warning",
          className: "warning",
          dismissLabel: "OK",
          messages: [
            {
              description:
                "A meter point is directly connected to an O/H transformer, please ensure that an HV shutdown isn't required.",
            },
          ],
        })
      );
    }
  };

  const calculateStudy = async (rerun: boolean) => {
    if (appMode === AppMode.loading) return;
    setShowSpinner(true);
    let completedNetwork: NetworkAsset[] = completeNetworkPolygons(network);

    const study = {
      network: completedNetwork,
      inputs,
      intersectingSegments,
      rerun,
    };

    const response = await getPackageWithoutAssessment(study);

    hasExistingPoleCheck(response);

    const _processedNetwork =
      response.connectLite2Response.processedStudy.network;
    if (_processedNetwork.length > 0) {
      dispatch(replaceNetwork(_processedNetwork));
      setIsProcessedStudy(true);
    } else {
      setIsProcessedStudy(false);
    }

    setShowSpinner(false);
  };

  const saveCompletedStudy = () => {
    saveNetworkState(saveStudyToWebEnquiryReference, true);
  };

  const getQuotationPlanOrDefault = async () => {
    if (quotationPlanScreenshot) {
      return quotationPlanScreenshot;
    } else if (
      isFeatureEnabled(Feature.autoQuotationPlan, userRole as UserRole)
    ) {
      return await generateQuotationPlanScreenshot();
    } else {
      return null;
    }
  };

  const requestHandler = async () => {
    dispatch(setAppMode(AppMode.screenshot));
    const mapScreenshot = await takeScreenshot(map);

    const processedNetwork: NetworkAsset[] = [];

    const quotationPlanFile = await getQuotationPlanOrDefault();
    const quotationPlanScreenshot = quotationPlanFile
      ? await fileToBase64(quotationPlanFile)
      : undefined;

    dispatch(setAppMode(undefined));

    let messageObj = {
      connectLite2Response: {
        connectLv: {},
        billOfMaterials: {},
        design: {},
        screenshots: {
          design: mapScreenshot,
          quotationPlan: quotationPlanScreenshot,
        },
        lvAceMessages: lvAceMessages,
        messages: errorMsgs,
        study: {
          network,
          inputs,
          intersectingSegments,
          radii,
          topographies,
          ragConductingSectionsAndPoles,
        },
        processedStudy: { network: processedNetwork },
      },
    };

    if (!errorMsgs.length) {
      let completedNetwork: NetworkAsset[] = isProcessedStudy
        ? network
        : completeNetworkPolygons(network);

      const study = {
        network: completedNetwork,
        inputs,
        intersectingSegments,
        rerun: true,
      };
      const response = await getPackage(study);
      if (response.data && response.data.errors) {
        const messages = Object.values(response.data.errors).map(
          (message) => `E402 - ${message}`
        );
        response.connectLite2Response = { messages };
      }
      messageObj.connectLite2Response = {
        ...messageObj.connectLite2Response,
        ...response.connectLite2Response,
      };

      messageObj.connectLite2Response.messages = [
        ...response.connectLite2Response.messages,
        ...messageObj.connectLite2Response.messages,
      ];
    }

    const _processedNetwork =
      messageObj.connectLite2Response.processedStudy.network;
    if (_processedNetwork.length > 0) {
      dispatch(replaceNetwork(_processedNetwork));
      setIsProcessedStudy(true);
      saveCompletedStudy();
    } else {
      setIsProcessedStudy(false);
    }

    dispatch(setAppMode(AppMode.saveStudyResults));
    await saveStudyResult(
      messageObj.connectLite2Response as unknown as ConnectLite2Response
    ).then(
      () => {
        console.log("Results Saved");
        dispatch(setAppMode(undefined));
      },
      () => {
        console.error("Results Not Saved");
        dispatch(setAppMode(undefined));
      }
    );
    window.parent.postMessage(
      {
        message: JSON.stringify(messageObj, null, "\t"),
      },
      "*"
    );

    dispatch(setSubmit(false));
  };

  return <>{showSpinner && !lvAceActive && <Spinner text="Loading..." />}</>;
};

export default RequestBuilder;
