import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setSelectedAsset } from "../existingNetwork/existingNetworkSlice";
import GeoServerConductingSections from "../geoServerConductingSections/geoServerConductingSections";
import OSFeatures from "../osFeatures/OSFeatures";
import { setOSFeature } from "../osFeatures/osFeaturesSlice";
import { MapFeature } from "../../models/enums";
import {
  selectSelectMapFeatures,
  setSelectedFeature,
} from "./selectMapFeaturesSlice";

const SelectMapFeaturesToggle = () => {
  const dispatch = useAppDispatch();
  const { selectedFeature } = useAppSelector(selectSelectMapFeatures);

  const updateSelectedFeature = (selectedFeature: MapFeature) => {
    dispatch(setSelectedAsset(undefined));
    dispatch(setOSFeature(undefined));
    dispatch(setSelectedFeature(selectedFeature));
  };

  const getIcon = (feature: MapFeature) => {
    return selectedFeature === feature ? "check-solid" : "cross-solid";
  };

  return (
    <>
      <div
        className="bg-light d-flex flex-column p-2"
        style={{ border: "2px solid black" }}
      >
        <ToggleButtonGroup
          type="radio"
          size="sm"
          vertical
          name="mapFeaturesToggle"
          value={selectedFeature}
          onChange={updateSelectedFeature}
        >
          <ToggleButton
            type="radio"
            id={MapFeature.ragAssets}
            name="ragAssets"
            variant="outline-secondary"
            value={MapFeature.ragAssets}
          >
            <i className={`icon-${getIcon(MapFeature.ragAssets)} mr-2`}></i>
            Assets
          </ToggleButton>
          <ToggleButton
            type="radio"
            id={MapFeature.osFeatures}
            name="osFeatures"
            variant="outline-secondary"
            value={MapFeature.osFeatures}
          >
            <i className={`icon-${getIcon(MapFeature.osFeatures)} mr-2`}></i>
            OS Features
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {selectedFeature === MapFeature.ragAssets ? (
        <GeoServerConductingSections />
      ) : (
        <OSFeatures />
      )}
    </>
  );
};

export default SelectMapFeaturesToggle;
