import { useAppSelector } from "../../app/hooks";
import Accordion from "react-bootstrap/Accordion";
import StepItem from "./StepItem";
import styles from "./StepBody.module.css";
import { Fragment } from "react";
import StepToolBtn from "./StepToolBtn";
import { selectApp } from "../../app/appSlice";
import { ConnectionPoint } from "../../models/models";

interface IStepBody {
  disabled?: boolean;
}

const StepBody = ({ disabled = false }: IStepBody) => {
  const { inputs, isReadOnly } = useAppSelector(selectApp);

  return (
    <div className={styles.stepBody}>
      <Accordion>
        {inputs.connectionPoints.map((connectionPoint: ConnectionPoint) => (
          <Fragment key={connectionPoint.id}>
            <StepItem item={connectionPoint} toggle={true}>
              <StepToolBtn
                item={connectionPoint}
                cssClasses="roundBtn"
                disabled={isReadOnly || disabled}
              />
            </StepItem>
            <Accordion.Collapse eventKey={connectionPoint.id}>
              <>
                <ul className={`${styles.stepProperties} list-inline mb-0`}>
                  <li className="list-inline-item">
                    {connectionPoint.phases} Phase(s) |
                  </li>
                  <li className="list-inline-item">
                    {connectionPoint.undiversifiedQuantity !== undefined
                      ? connectionPoint.undiversifiedQuantity
                      : connectionPoint.quantity}{" "}
                    kVA
                  </li>
                </ul>
                {connectionPoint.subConnectionPoints.length > 0 && (
                  <div
                    className="mx-2"
                    style={{
                      backgroundColor: "rgb(245, 246, 247)",
                      padding: ".25rem",
                    }}
                  >
                    <span
                      className={`${styles.stepBodyItem} mx-2`}
                      style={{
                        paddingLeft: "1.75rem",
                      }}
                    >
                      Sub Connections
                    </span>
                    <ul
                      className={`${styles.stepProperties} list-inline mb-0 mx-2`}
                      style={{
                        paddingLeft: "1.75rem",
                      }}
                    >
                      {connectionPoint.subConnectionPoints.map(
                        (subConnectionPoint) => (
                          <>
                            <li className="list-inline-item">
                              {subConnectionPoint.type.replace("-", " ")} |
                            </li>
                            <li className="list-inline-item">
                              {subConnectionPoint.undiversifiedQuantity !==
                              undefined
                                ? subConnectionPoint.undiversifiedQuantity
                                : subConnectionPoint.quantity}{" "}
                              {subConnectionPoint.unit}
                            </li>
                          </>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </>
            </Accordion.Collapse>
          </Fragment>
        ))}
      </Accordion>
    </div>
  );
};

export default StepBody;
