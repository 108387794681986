import { ChangeEvent } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { Map } from "leaflet";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  removeAnnotation,
  removeLine,
  removeShape,
  selectObject,
  selectQuotationPlan,
  updateAnnotationData,
  updateLineData,
  updateShapeData,
} from "../../features/quotationPlan/quotationPlanSlice";
import "./QuotationSettings.css";
import "./editor/styles.css";
import Editor from "./editor/Editor";
import ColorDropdown from "./editor/TextEditorDropDown";
import WidthDropDown from "./editor/WidthDropDown";

const BACKGROUND_COLOR_OPTIONS: [string, string][] = [
  ["white", "White"],
  ["red", "Red"],
  ["transparent", "None"],
];

const BORDER_COLOR_OPTIONS: [string, string][] = [
  ["black", "Black"],
  ["transparent", "None"],
];

export interface IQuotationSettings {
  map: Map;
}

const QuotationSettings = ({ map }: IQuotationSettings) => {
  const dispatch = useAppDispatch();
  const quotationPlan = useAppSelector(selectQuotationPlan);
  const { annotations, lines, shapes, selectedObject } = quotationPlan;

  const convertInputType = (
    event: ChangeEvent<HTMLInputElement>,
    type: string = ""
  ) => {
    const target = event.target;
    const value = type ? event : target.value;
    const name = type ? type : target.name;
    return { value, name };
  };

  const handleAnnotationInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string = ""
  ): void => {
    const { value, name } = convertInputType(event, type);
    if (selectedObject === undefined) return;
    dispatch(updateAnnotationData({ id: selectedObject, key: name, value }));
  };

  const handleAnnotationContent = ({
    html,
    lexical,
  }: {
    html: string;
    lexical: string;
  }): void => {
    if (selectedObject === undefined) return;
    dispatch(
      updateAnnotationData({ id: selectedObject, key: "text", value: html })
    );
    dispatch(
      updateAnnotationData({
        id: selectedObject,
        key: "lexical",
        value: lexical,
      })
    );
  };

  const handleLineInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string = ""
  ): void => {
    const { value, name } = convertInputType(event, type);
    if (selectedObject === undefined) return;
    dispatch(updateLineData({ id: selectedObject, key: name, value }));
  };

  const handleRadioChange = (key: string = "", value: string = ""): void => {
    if (selectedObject === undefined) return;
    dispatch(updateAnnotationData({ id: selectedObject, key, value }));
  };

  const handleLineClick = (id: string) => {
    dispatch(removeLine(id));
  };

  const handleAnnotationClick = (id: string) => {
    dispatch(removeAnnotation(id));
  };

  const handleShapeClick = (id: string) => {
    dispatch(selectObject({ obj: undefined, map }));
    dispatch(removeShape(id));
  };

  const handleShapeRadioChange = (
    key: string = "",
    value: string = ""
  ): void => {
    if (selectedObject === undefined) return;
    dispatch(updateShapeData({ id: selectedObject, key, value }));
  };

  const annotation = annotations.find(
    (annotation) => annotation.id === selectedObject
  );

  const line = lines.find((line) => line.id === selectedObject);
  const shape = shapes.find((shape) => shape.id === selectedObject);

  return (
    <>
      {annotation && (
        <Form
          className="iosBoxShadow p-2"
          style={{
            backgroundColor: "#ffffff",
            width: "21rem",
            height: "100%",
          }}
        >
          <div className="mb-2">
            <h6 style={{ fontWeight: "bold" }}>Text Box</h6>
            <ButtonGroup size="sm" className="mr-1">
              <WidthDropDown
                value={annotation.width}
                onChange={handleAnnotationInputChange}
              />
              <ColorDropdown
                label="Background"
                value={annotation.color}
                icon={true}
                array={BACKGROUND_COLOR_OPTIONS}
                onClick={(e) => handleRadioChange("color", e)}
              />
              <ColorDropdown
                label="Border"
                value={annotation.borderColor}
                icon={true}
                array={BORDER_COLOR_OPTIONS}
                onClick={(e) => handleRadioChange("borderColor", e)}
              />
            </ButtonGroup>
          </div>
          <div className="mb-2">
            <h6 style={{ fontWeight: "bold" }}>Text Editor</h6>
            <Editor
              annotation={annotation}
              onChange={handleAnnotationContent}
            />
          </div>
          <Button
            variant="danger"
            className="w-100"
            onClick={() => handleAnnotationClick(annotation.id)}
          >
            Remove
          </Button>
        </Form>
      )}
      {line && (
        <Form
          className="iosBoxShadow p-2"
          style={{
            backgroundColor: "#ffffff",
            width: "16rem",
            height: "100%",
          }}
        >
          {line.type === "arrow-line" && (
            <Form.Group className="mb-2" controlId="arrowheadSize">
              <Form.Label>Arrowhead Size</Form.Label>
              <Form.Control
                type="range"
                name="arrowheadSize"
                value={line.arrowheadSize}
                min="0.5"
                max="10"
                step="0.5"
                onChange={handleLineInputChange}
              />
            </Form.Group>
          )}
          <Button
            variant="danger"
            className="w-100"
            onClick={() => handleLineClick(line.id)}
          >
            Remove
          </Button>
        </Form>
      )}
      {shape && (
        <Form
          className="iosBoxShadow p-2"
          style={{
            backgroundColor: "#ffffff",
            width: "16rem",
            height: "100%",
          }}
        >
          <Form.Group className="mb-2" controlId="shapeColor">
            <Form.Label>Color</Form.Label>
            <ToggleButtonGroup
              type="radio"
              name="color"
              size="sm"
              value={shape.color}
              onChange={(e) => handleShapeRadioChange("color", e)}
              className="w-100"
            >
              <ToggleButton value="green" variant="outline-secondary">
                <span className="item-green">Green</span>
              </ToggleButton>
              <ToggleButton value="purple" variant="outline-secondary">
                <span className="item-purple">Purple</span>
              </ToggleButton>
              <ToggleButton value="black" variant="outline-secondary">
                <span className="item-black">Black</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Form.Group>
          <Button
            variant="danger"
            className="w-100"
            onClick={() => handleShapeClick(shape.id)}
          >
            Remove
          </Button>
        </Form>
      )}
    </>
  );
};

export default QuotationSettings;
