import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { layerOptions } from "./constants";

export interface LayerToggleState {
  layers: string[];
}

const initialLayers = Object.values(layerOptions)
  .filter((layer) => layer.visible && layer.ragLayer)
  .map((v) => v.value);

export const initialState: LayerToggleState = {
  layers: [...initialLayers],
};

export const layerToggleSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateLayers: (state, action: PayloadAction<string[]>) => {
      state.layers = action.payload;
    },
  },
});

export const { updateLayers } = layerToggleSlice.actions;

export const selectLayerToggle = (state: RootState) => state.layerToggle;

export default layerToggleSlice.reducer;
