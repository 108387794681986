import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import {
  Button,
  ButtonGroup,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";

import * as PDFJS from "pdfjs-dist";
import { PDFDocumentProxy } from "pdfjs-dist";
import * as PDFJSWorker from "pdfjs-dist/build/pdf.worker.entry";
import { point } from "@turf/helpers";
import destination from "@turf/destination";
import { Schematic } from "../../models/models";
import {
  addSchematic,
  deleteSchematic,
  resetSchematic,
  selectSchematics,
  selectSchematic,
  updateSchematicOpacity,
  updateSchematicUniformScaling,
  updateSchematicVisibility,
} from "./schematicsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { removeTransformPathLayers } from "./helpers";
import SchematicsModal from "./SchematicsModal";
import { updateDialog } from "../../app/appSlice";
import "./schematics.css";

const SchematicsForm = () => {
  const map = useMap();
  const fileRef = useRef(null);
  const [pages, setPages] = useState<number>(0);
  const [schematic, setSchematic] = useState<PDFDocumentProxy | undefined>(
    undefined
  );
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [opacity, setOpacity] = useState<number>(0.5);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const schematics = useAppSelector(selectSchematics);
  const { selectedSchematic, schematics: schematics_ } = schematics;

  useEffect(() => {
    if (selectedSchematic) {
      let existingOpacity = selectedSchematic.opacity;
      setOpacity(existingOpacity);
    }
  }, [selectedSchematic]);

  const importSchematicPDF = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setLoading(true);
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      let fileName: string;
      if (file) {
        fileName = file.name;
        let fileFormat = fileName.split(".").pop();
        if (fileFormat === "pdf") {
          await showPdf(file);
        } else if (["png", "jpg", "jpeg"].includes(fileFormat!)) {
          await showImage(file);
        }
      } else {
        dispatch(
          updateDialog({
            props: [],
            type: "danger",
            className: "danger",
            dismissLabel: "OK",
            messages: [
              {
                description: "The file is not a PDF, PNG or JPG",
              },
            ],
          })
        );
        return;
      }
    }
    setLoading(false);
  };

  async function showPdf(file: File) {
    PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker;

    const uri = URL.createObjectURL(file);
    let loadingTask = PDFJS.getDocument({ url: uri });
    await loadingTask.promise
      .then((schematic) => {
        if (schematic.numPages > 1) {
          setPages(schematic.numPages);
          setSchematic(schematic);
          setFileName(file.name);
        } else {
          renderPage(schematic, file.name);
        }
      })
      .catch((error) => {
        dispatch(
          updateDialog({
            props: [],
            type: "danger",
            className: "danger",
            dismissLabel: "OK",
            messages: [
              {
                description: error,
              },
            ],
          })
        );
      });
  }

  async function showImage(file: File) {
    const fileName = file.name;
    const image = await getBase64FromImageFile(file);
    const viewport = await getHeightAndWidthFromImageFile(file);
    const bearings = getBearings(viewport.width, viewport.height);
    const positions = getPositions(bearings);

    createSchematic(image, fileName, positions);
  }

  const getBase64FromImageFile = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });

  const getHeightAndWidthFromImageFile = (
    file: File
  ): Promise<{ width: number; height: number }> =>
    new Promise((resolve) => {
      const dataUrl = window.URL.createObjectURL(file);
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.src = dataUrl;
    });

  async function renderPage(
    schematic: PDFDocumentProxy,
    fileName: string,
    pageNum: number = 1
  ) {
    const canvas = document.createElement("canvas");

    var page = await schematic.getPage(pageNum);
    var viewport = page.getViewport({ scale: 1 });
    const bearings = getBearings(viewport.width, viewport.height);
    const positions = getPositions(bearings);
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    var render_context = {
      canvasContext: canvas.getContext("2d")!,
      viewport: viewport,
    };
    await page.render(render_context).promise;
    const image = canvas.toDataURL("image/png");

    createSchematic(image, fileName, positions);
  }

  const createSchematic = (
    image: string,
    fileName: string,
    positions: number[][]
  ) => {
    const schematic: Schematic = {
      id: uuid(),
      image,
      fileName,
      opacity: 0.75,
      uniformScaling: true,
      visible: true,
      positions,
    };

    dispatch(addSchematic(schematic));
  };

  const getBearings = (width: number, height: number) => {
    const hypotenuse = Math.hypot(width, height);
    const sine = width / hypotenuse;
    const sinAngle = Math.asin(sine) * (180 / Math.PI);
    return [
      -Math.abs(sinAngle),
      sinAngle,
      180 - sinAngle,
      -Math.abs(180 - sinAngle),
    ];
  };

  const getPositions = (bearings: number[]) => {
    const { lat, lng } = map.getCenter();
    var pt = point([lng, lat]);
    var distance = 100;

    return bearings.map((m) =>
      destination(pt, distance, m, {
        units: "meters",
      }).geometry.coordinates.reverse()
    );
  };

  const _updateSchematicOpacity = (e: ChangeEvent<HTMLInputElement>) => {
    const newOpacity = parseFloat(e.target.value);
    setOpacity(newOpacity);
    dispatch(
      updateSchematicOpacity({ id: selectedSchematic?.id!, newOpacity })
    );
  };

  const _updateSchematicUniformScaling = () => {
    const newUniformScaling = !selectedSchematic?.uniformScaling;
    removeTransformPathLayers(map);

    dispatch(
      updateSchematicUniformScaling({
        id: selectedSchematic?.id!,
        newUniformScaling,
      })
    );
  };

  const _resetSchematic = () => {
    removeTransformPathLayers(map);
    dispatch(
      resetSchematic({
        id: selectedSchematic?.id!,
      })
    );
  };
  const _deleteSchematic = () => {
    removeTransformPathLayers(map);
    dispatch(
      deleteSchematic({
        id: selectedSchematic?.id!,
      })
    );
  };

  const handleSubmit = (pageNum: number) => {
    renderPage(schematic!, fileName!, pageNum);
    setPages(0);
    setSchematic(undefined);
    setFileName(undefined);
  };

  const uploadFile = (
    <Form.Group className="mb-0">
      <Form.Control
        type="file"
        multiple
        ref={fileRef}
        id="uploadSchematic"
        onChange={(e: ChangeEvent<HTMLInputElement>) => importSchematicPDF(e)}
      />
      {loading ? (
        <div className="d-flex align-items-center">
          <div className="spinner-border spinner-border-sm m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <span>Loading...</span>
        </div>
      ) : null}
    </Form.Group>
  );

  // create a list of all currently loaded schematics with visibility toggle and delete
  const schematicsList = schematics.schematics.map((schematic) => {
    return (
      <li
        className="schematic-list-item"
        onClick={() => {
          dispatch(selectSchematic(schematic));
          //update overlay pane to front
          const overlayPane = document.querySelector(".leaflet-overlay-pane");
          overlayPane?.classList.add("overlay-pane-to-front");
        }}
      >
        <span className="d-inline-block text-truncate w-50 m-1">
          {schematic.fileName}
        </span>
        <div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="visibility-toggle">Toggle Visibility</Tooltip>
            }
            delay={{ show: 500, hide: 500 }}
          >
            <button
              type="button"
              id={schematic.id}
              className={
                schematic.visible
                  ? "btn btn-secondary m-1 rounded"
                  : "btn btn-outline-secondary m-1 rounded"
              }
              value="1"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  updateSchematicVisibility({
                    id: schematic.id,
                    visible: !schematic.visible,
                  })
                );
              }}
            >
              {schematic.visible ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="delete">Delete</Tooltip>}
            delay={{ show: 500, hide: 500 }}
          >
            <button
              className="dno-primary-button"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(deleteSchematic({ id: schematic.id }));
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </OverlayTrigger>
        </div>
      </li>
    );
  });

  return (
    <div
      className="bg-light d-flex flex-column p-2"
      style={{ border: "2px solid black", minWidth: "16rem" }}
    >
      <h5 className="text-center">Site Plan Schematic</h5>
      <Form>
        {selectedSchematic ? (
          <>
            <Form.Group controlId="formBasicRange">
              <Form.Label>Opacity</Form.Label>
              <Form.Control
                type="range"
                min={0.25}
                max={1}
                step={0.05}
                onChange={_updateSchematicOpacity}
                value={opacity}
              />
            </Form.Group>
            <Form.Group>
              <Button
                variant="outline-secondary"
                block
                onClick={() => _updateSchematicUniformScaling()}
                className={
                  selectedSchematic.uniformScaling
                    ? "toggle-button active"
                    : "toggle-button"
                }
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span>Uniform Scaling</span>
                  <i
                    className={`icon-${
                      selectedSchematic.uniformScaling ? "check" : "cross"
                    }-solid`}
                  ></i>
                </div>
              </Button>
            </Form.Group>
            <ButtonGroup className="w-100">
              <Button
                variant="outline-secondary"
                onClick={_resetSchematic}
                className="control-button"
              >
                Reset
              </Button>
              <button
                onClick={_deleteSchematic}
                className="control-button dno-primary-button"
              >
                Delete
              </button>
            </ButtonGroup>
          </>
        ) : schematics.schematics.length > 0 ? (
          <div className="d-flex flex-column p-1 align-items-between">
            <h6>Select a Schematic</h6>
            <ul className="pl-0 mb-1">{schematicsList}</ul>
            <h6>Or Upload Files</h6>
            {uploadFile}
          </div>
        ) : (
          <>{uploadFile}</>
        )}
      </Form>
      <SchematicsModal pages={pages} onSubmit={handleSubmit} />
    </div>
  );
};

export default SchematicsForm;
