import { useAppSelector } from "../app/hooks";
import { selectNetworkCreator } from "../features/networkCreator/networkCreatorSlice";

const useNetwork = () => {
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { network } = networkCreator;

  const filteredNetwork = [...network].filter((a) => a.type !== "site");
  const isNetworkConnected = !filteredNetwork.some((a) => !a.isConnected)
    ? filteredNetwork.length
    : 0;

  return {
    isNetworkConnected,
  };
};

export default useNetwork;
