import Button from "react-bootstrap/Button";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectApp, setSubmit } from "../../app/appSlice";

interface IIconButton {
  size?: "sm" | "lg" | undefined;
  color: string;
  icon: string;
  disabled?: boolean;
}
const IconButton = ({ color, icon, disabled }: IIconButton) => {
  const app = useAppSelector(selectApp);
  const { submit } = app;
  const dispatch = useAppDispatch();

  return (
    <Button
      variant={color}
      onClick={() => dispatch(setSubmit(true))}
      disabled={disabled || submit}
    >
      <i className={`icon-${icon}`}></i>
    </Button>
  );
};

export default IconButton;
