import { Map } from "leaflet";

export const removeTransformPathLayers = (map: Map) => {
  map.eachLayer(function (layer: any) {
    if (layer.transform && layer.transform._handlersGroup) {
      const transformLayers = Object.values(
        layer.transform._handlersGroup._layers
      ).map((l) => l);
      transformLayers.forEach((lyr: any) => {
        map.removeLayer(lyr);
      });
    }
  });
};
