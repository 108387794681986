import { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectObject,
  selectQuotationPlan,
  setActiveTool,
  setMapScale,
  setQuotationPlanScreenshot,
} from "../../features/quotationPlan/quotationPlanSlice";
import {
  setAppMode,
  setScreenshot,
  setShowLayerToggle,
  selectApp,
} from "../../app/appSlice";
import "./QuotationPlanToolbar.css";
import { Map } from "leaflet";
import useScreenshot from "../../hooks/useScreenshot";
import QuotationSettings from "./QuotationSettings";
import { updateLayers } from "../../features/layerToggle/layerToggleSlice";
import { FeatureSwitch } from "../featureSwitch/featureSwitch";
import { Feature } from "../../models/enums";
import {
  addQuotationPlanLegend,
  fileToBase64,
  fileToUrl,
  getBannerSvg,
} from "../../services/screenshotService";

const scaleRatios = [250, 500, 750, 1000, 1500, 2000, 2500];

export interface IQuotationPlanTools {
  map: Map;
  size?: "sm" | "lg" | undefined;
  color: string;
  cssClasses?: string;
}

const QuotationPlanToolbar = ({
  map,
  size,
  color,
  cssClasses,
}: IQuotationPlanTools) => {
  const { takeScreenshot, isLoading, clear } = useScreenshot();
  const dispatch = useAppDispatch();
  const quotationPlan = useAppSelector(selectQuotationPlan);
  const { inputs } = useAppSelector(selectApp);
  const { author, searchValue, studyTitle } = inputs;
  const { activeTool, selectedObject, mapScale, gridReference } = quotationPlan;

  const app = useAppSelector(selectApp);
  const userRole = app.inputs.userRole;

  useEffect(() => {
    dispatch(setScreenshot(isLoading));
  }, [isLoading]);

  const handleActiveTool = (tool: string | undefined) => {
    dispatch(selectObject({ obj: undefined, map }));

    const _tool = activeTool === tool ? undefined : tool;
    dispatch(setActiveTool(_tool));
  };

  const handleSave = async () => {
    dispatch(setShowLayerToggle(false));
    dispatch(selectObject({ obj: undefined, map }));

    const quotationPlanScreenshot = await takeScreenshot(map);

    if (!quotationPlanScreenshot) return;

    const quotationPlanSvg = await getBannerSvg(
      author,
      searchValue,
      mapScale,
      gridReference,
      studyTitle
    );
    const bannerUrl = await fileToUrl(quotationPlanSvg);
    const bannerUrlBase64 = await fileToBase64(bannerUrl);

    const quotationPlan = await addQuotationPlanLegend(
      quotationPlanScreenshot,
      bannerUrlBase64
    );

    if (!quotationPlan) return;

    dispatch(setQuotationPlanScreenshot(quotationPlan));
    dispatch(setShowLayerToggle(true));

    clear();
    handleExit();
  };

  const handleExit = () => {
    dispatch(selectObject({ obj: undefined, map }));

    handleActiveTool(undefined);
    dispatch(
      updateLayers([
        "conductingSections",
        "overheadConductingSections",
        "overheadPoles",
      ])
    );
    dispatch(setAppMode(undefined));
  };

  return (
    <div
      className="position-fixed m-2"
      style={{
        zIndex: 400,
        fontWeight: "normal",
      }}
    >
      <div
        className="float-left"
        style={{
          position: "absolute",
          marginLeft: "2.5rem",
        }}
      >
        {selectedObject && <QuotationSettings map={map} />}
      </div>
      <ButtonGroup
        id="toolbar"
        vertical
        size={size}
        className={cssClasses}
        aria-label="tool buttons"
      >
        <FeatureSwitch
          feature={Feature.quotationBasicTools}
          userRole={userRole}
        >
          <DropdownButton
            as={ButtonGroup}
            drop="right"
            variant="dark"
            className="mb-2"
            id="dropdown-custom-components"
            title={<i className="icon-compass-drafting"></i>}
            onClick={(e) => e.stopPropagation()}
            aria-label="Plotter"
          >
            {scaleRatios.map((scale) => (
              <Dropdown.Item
                key={scale}
                eventKey={scale}
                onSelect={() => dispatch(setMapScale(scale))}
              >
                1:{scale}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </FeatureSwitch>
        <FeatureSwitch
          feature={Feature.quotationBasicTools}
          userRole={userRole}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="annotation">Annotate</Tooltip>}
          >
            <Button
              variant={color}
              className={activeTool === "annotation" ? "active" : ""}
              onClick={() => handleActiveTool("annotation")}
            >
              <i className="icon-i-cursor-solid"></i>
            </Button>
          </OverlayTrigger>
        </FeatureSwitch>
        <FeatureSwitch
          feature={Feature.quotationBasicTools}
          userRole={userRole}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="arrow-line">Arrow</Tooltip>}
          >
            <Button
              variant={color}
              className={activeTool === "arrow-line" ? "active" : ""}
              onClick={() => handleActiveTool("arrow-line")}
            >
              <i className="icon-arrow-line-solid"></i>
            </Button>
          </OverlayTrigger>
        </FeatureSwitch>
        <FeatureSwitch
          feature={Feature.quotationAdvancedTools}
          userRole={userRole}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="duct-line">Duct</Tooltip>}
          >
            <Button
              variant={color}
              className={activeTool === "duct-line" ? "active" : ""}
              onClick={() => handleActiveTool("duct-line")}
            >
              <i className="icon-duct-line-solid"></i>
            </Button>
          </OverlayTrigger>
        </FeatureSwitch>
        <FeatureSwitch
          feature={Feature.quotationAdvancedTools}
          userRole={userRole}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="save">Insulated End</Tooltip>}
          >
            <Button
              variant={color}
              className={activeTool === "insulated-end" ? "active" : ""}
              onClick={() => handleActiveTool("insulated-end")}
            >
              <i className="icon-triangle-solid"></i>
            </Button>
          </OverlayTrigger>
        </FeatureSwitch>
        <FeatureSwitch
          feature={Feature.quotationAdvancedTools}
          userRole={userRole}
        >
          <DropdownButton
            as={ButtonGroup}
            drop="right"
            variant={color}
            className={
              activeTool?.endsWith("disconnected-cable") ? "active" : ""
            }
            id="dropdown-custom-components"
            title={<i className="icon-disconnected-cable"></i>}
            onClick={(e) => e.stopPropagation()}
            aria-label="disconnected-cable"
          >
            {["short-disconnected-cable", "long-disconnected-cable"].map(
              (tool) => (
                <Dropdown.Item
                  key={tool}
                  eventKey={tool}
                  onSelect={() => handleActiveTool(tool)}
                >
                  {tool}
                </Dropdown.Item>
              )
            )}
          </DropdownButton>
        </FeatureSwitch>
        <FeatureSwitch
          feature={Feature.quotationAdvancedTools}
          userRole={userRole}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="save">Joint</Tooltip>}
          >
            <Button
              variant={color}
              className={activeTool === "joint" ? "active" : ""}
              onClick={() => handleActiveTool("joint")}
            >
              <i className="icon-square-solid1"></i>
            </Button>
          </OverlayTrigger>
        </FeatureSwitch>
        <FeatureSwitch
          feature={Feature.quotationAdvancedTools}
          userRole={userRole}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="save">Circle</Tooltip>}
          >
            <Button
              variant={color}
              className={activeTool === "circle" ? "active" : ""}
              onClick={() => handleActiveTool("circle")}
            >
              <i className="icon-circle-solid"></i>
            </Button>
          </OverlayTrigger>
        </FeatureSwitch>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="save-exit">Save &amp; Exit</Tooltip>}
        >
          <Button
            aria-label="Exit Site Plan"
            variant="success"
            className="mt-2"
            onClick={handleSave}
          >
            <i className="icon-save-solid"></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="save-exit">Exit</Tooltip>}
        >
          <Button
            aria-label="Exit Site Plan"
            variant="danger"
            onClick={handleExit}
          >
            <i className="icon-cross-solid"></i>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </div>
  );
};

export default QuotationPlanToolbar;
