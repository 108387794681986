import axios from "axios";
import { LVAceRequest } from "../models/models";
import { appConfig } from "./configService";
import { CalculateRequest } from "../models/calculateRequest";

const {
  apiBaseUrl,
  lvAceBaseUrl,
  calculate,
  shortestPath,
  calculateNoAssessment,
} = appConfig;

export const getShortestPath = async (requestBody: LVAceRequest) => {
  const url = `${lvAceBaseUrl}${shortestPath}`;

  return axios
    .post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getPackage = async (requestBody: CalculateRequest) => {
  const url = `${apiBaseUrl}${calculate}`;

  return axios
    .post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};

export const getPackageWithoutAssessment = async (
  requestBody: CalculateRequest
) => {
  const url = `${apiBaseUrl}${calculateNoAssessment}`;

  return axios
    .post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return console.log(error);
    });
};
