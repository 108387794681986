import { ActionCreators } from "redux-undo";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

import ConditionalWrapper from "../../components/conditionalWrapper/ConditionalWrapper";

import { AppMode, selectApp, setIsLvAceComplete } from "../../app/appSlice";
import { selectSteps } from "../steps/stepsSlice";
import { hasPast, hasFuture } from "../networkCreator/networkCreatorSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

export interface IUndoRedoBtns {
  size?: "sm" | "lg" | undefined;
  color: string;
  cssClasses: string;
}

const UndoRedoBtns = ({
  size = undefined,
  color,
  cssClasses,
}: IUndoRedoBtns) => {
  const past = useAppSelector(hasPast);
  const future = useAppSelector(hasFuture);
  const dispatch = useAppDispatch();

  const app = useAppSelector(selectApp);
  const { appMode, isReadOnly } = app;

  const steps = useAppSelector(selectSteps);
  const { mode } = steps;

  const handleUndo = () => {
    dispatch(setIsLvAceComplete(false));
    dispatch(ActionCreators.undo());
  };

  return (
    <>
      {
        //Conditionally wrap the undo button with a tooltip, this avoids the tooltips 'sticking' when the button is disabled
      }
      <ConditionalWrapper
        condition={past}
        wrapper={(children: React.ReactElement) => (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="undo">Undo</Tooltip>}
          >
            {children}
          </OverlayTrigger>
        )}
        children={
          <Button
            variant={color}
            aria-label="undo"
            className="my-2 ml-2 mr-0"
            onClick={handleUndo}
            disabled={!past || isReadOnly}
          >
            <i className="icon-undo-solid"></i>
          </Button>
        }
      />
      {(mode === "expanded" || appMode === AppMode.edit) && (
        //Conditionally wrap the redo button with a tooltip, this avoids the tooltips 'sticking' when the button is disabled
        <ConditionalWrapper
          condition={future}
          wrapper={(children: React.ReactElement) => (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="redo">Redo</Tooltip>}
            >
              {children}
            </OverlayTrigger>
          )}
          children={
            <Button
              variant={color}
              aria-label="redo"
              className="my-2 mr-2 ml-0"
              onClick={() => dispatch(ActionCreators.redo())}
              disabled={!future || isReadOnly}
            >
              <i className="icon-redo-solid"></i>
            </Button>
          }
        />
      )}
    </>
  );
};

export default UndoRedoBtns;
