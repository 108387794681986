import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

// Define a type for the slice state
export interface StepsState {
  mode: string;
  mouseOverControl: boolean;
  activePremise?: string;
}

// Define the initial state using that type
export const initialState: StepsState = {
  mode: "expanded",
  mouseOverControl: true,
  activePremise: undefined,
};

export const stepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setMouseOverControl: (state, action: PayloadAction<boolean>) => {
      state.mouseOverControl = action.payload;
    },
  },
});

export const { setMode, setMouseOverControl } = stepsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSteps = (state: RootState) => state.steps;

export default stepsSlice.reducer;
