import { LatLng } from "leaflet";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import booleanIntersects from "@turf/boolean-intersects";
import {
  featureCollection,
  lineString,
  point,
  polygon,
  Properties,
} from "@turf/helpers";
import { Feature, FeatureCollection, Polygon, Position } from "geojson";

import { FeatureType } from "../models/enums";

export const useFeatureCollection = function () {
  const getFeatureCollection = (
    coords: any,
    featureType: FeatureType,
    groundTypes: Feature<Polygon>[]
  ) => {
    let featureCollection: FeatureCollection | "" | undefined;
    switch (featureType) {
      case FeatureType.lineString:
        featureCollection = getLineString(coords, groundTypes);
        break;
      case FeatureType.polygon:
        featureCollection = getPolygon(coords, groundTypes);
        break;
      case FeatureType.point:
        featureCollection = getPoint(coords, groundTypes);
        break;
      default:
        featureCollection = "";
        break;
    }
    return featureCollection;
  };

  const getLineString = (line: Position[], groundTypes: Feature<Polygon>[]) => {
    const features: Feature<Polygon>[] = groundTypes
      .filter((groundType) =>
        booleanIntersects(
          lineString(line),
          polygon(groundType.geometry.coordinates)
        )
      )
      .map((m) =>
        polygon(m.geometry.coordinates, {
          ...(m.properties as Properties),
        })
      );
    return featureCollection(features);
  };

  const getPolygon = (poly: Position[][], groundTypes: Feature<Polygon>[]) => {
    const features: Feature<Polygon>[] = groundTypes
      .filter((groundType) =>
        booleanIntersects(
          polygon(poly),
          polygon(groundType.geometry.coordinates)
        )
      )
      .map((m) =>
        polygon(m.geometry.coordinates, {
          ...(m.properties as Properties),
        })
      );
    return featureCollection(features);
  };

  const getPoint = (pt: LatLng, groundTypes: Feature<Polygon>[]) => {
    const feature: Feature<Polygon> | undefined = groundTypes.find(
      (groundType) =>
        booleanPointInPolygon(
          point([pt.lng, pt.lat]),
          polygon(groundType.geometry.coordinates)
        )
    );
    if (!feature) return;
    return featureCollection([
      polygon(feature.geometry.coordinates, {
        ...(feature.properties as Properties),
      }),
    ]);
  };
  return { getFeatureCollection };
};
