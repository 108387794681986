import { ButtonGroup, Button, Modal } from "react-bootstrap";

import { useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";
import { Message } from "../../models/models";

const LVAceModal = ({
  messages,
  showModal,
  dismissDialogAction,
}: {
  messages: Message[];
  showModal: boolean;
  dismissDialogAction: () => void;
}) => {
  const app = useAppSelector(selectApp);

  const dismissAction = () => {
    dismissDialogAction();
  };

  return (
    <Modal show={showModal} onHide={() => {}} backdrop="static">
      <Modal.Body
        className="bg-warning text-light d-flex justify-content-between"
        style={{ fontSize: ".875rem", fontWeight: "normal" }}
      >
        {messages && messages.length > 1 ? (
          <ul>
            {messages.map((message) => (
              <li>{message.description}</li>
            ))}
          </ul>
        ) : (
          messages.length && messages[0].description
        )}
        <ButtonGroup>
          <Button variant="outline-light" size="sm" onClick={dismissAction}>
            Close
          </Button>
        </ButtonGroup>
      </Modal.Body>
    </Modal>
  );
};

export default LVAceModal;
