import { LeafletEvent } from "leaflet";
import { useMemo, useRef } from "react";
import { Marker } from "react-leaflet";
import { LatLngObj } from "../../models/models";
import { iconMarker } from "../../utils/iconMarker";

interface IAddMarker {
  coord: LatLngObj;
  onAddMarker: (e: LeafletEvent) => void;
}
const AddMarker = ({ coord, onAddMarker }: IAddMarker) => {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletEvent) {
        const marker = markerRef.current;
        if (marker != null) {
          onAddMarker(e);
        }
      },
    }),
    []
  );

  const styleIcon = () => {
    return iconMarker(0.75, "iconEdit");
  };

  return (
    <Marker
      icon={styleIcon()}
      eventHandlers={eventHandlers}
      position={coord}
      ref={markerRef}
      zIndexOffset={9999}
    />
  );
};

export default AddMarker;
