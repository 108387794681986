import {
  createPathComponent,
  LeafletContextInterface,
  PathProps,
} from "@react-leaflet/core";
import {
  LatLngExpression,
  Polyline as LeafletPolyline,
  PolylineOptions,
  MeasurePathOptions,
} from "leaflet";
import "leaflet-measure-path";
import "leaflet-measure-path/leaflet-measure-path.css";
import { PropsWithChildren } from "react";

interface ReactLeafletMeasurePathBaseProps {
  /**
   * The positions to use for the polyline.
   */
  positions: LatLngExpression[];
}

export type ReactLeafletMeasurePathProps = PolylineOptions &
  MeasurePathOptions &
  PathProps &
  PropsWithChildren<ReactLeafletMeasurePathBaseProps>;

const createLeafletElement = (
  {
    // Required MeasurePath attributes.
    positions,
    // MeasurePath options.
    showOnHover,
    showTotalDistance,
    minDistance,
    // PolyLine options.
    showMeasurements,
    ...options
  }: ReactLeafletMeasurePathProps,
  ctx: LeafletContextInterface
) => {
  const instance = new LeafletPolyline(positions, {
    showMeasurements,
    ...options,
  });
  return { instance, context: { ...ctx, overlayContainer: instance } };
};

const updateLeafletElement = (
  instance: LeafletPolyline,
  {
    // Required MeasurePath attributes.
    positions,
    // MeasurePath options.
    showOnHover,
    showTotalDistance,
    minDistance,
    // PolyLine options.
    showMeasurements,
    ...options
  }: ReactLeafletMeasurePathProps,
  prevProps: ReactLeafletMeasurePathProps
) => {
  // Set null first, to reset the text displayed.
  if (positions !== prevProps.positions) instance.setLatLngs(positions);
};

const MeasuredPolyline = createPathComponent(
  createLeafletElement,
  updateLeafletElement
);

export default MeasuredPolyline;
