import "./Spinner.scss";

interface SpinnerInput {
  text: string;
  variant?: "transparent" | "opaque";
}

const Spinner = ({ text, variant = "transparent" }: SpinnerInput) => {
  return (
    <div className={`loading-spinner loading-spinner--${variant}`}>
      <i className="icon-spinner-solid spinner"></i>
      <h4 style={{ fontWeight: 400 }}>{text}</h4>
    </div>
  );
};

export default Spinner;
