import lineIntersect from "@turf/line-intersect";
import distance from "@turf/distance";
import along from "@turf/along";
import pointToLineDistance from "@turf/point-to-line-distance";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import {
  lineString,
  Position,
  polygon,
  point,
  LineString,
  Properties,
  Point,
} from "@turf/helpers";
import { Feature, Polygon } from "geojson";
import {
  LatLngObj,
  NetworkAsset,
  EditableJoint,
  TransposedAsset,
} from "../models/models";
import { GroundTypeProperties } from "../models/featureProperties";

export const getIntersectingLinesFromPolygon = (
  feature: Feature,
  coords: Position[]
) => {
  const poly = featureToPolygon(feature);
  const line = lineString(coords);
  const lineStartCoord = line.geometry.coordinates[0];
  const lineEndCoord = line.geometry.coordinates[1];
  const isLineStartInFeature = booleanPointInPolygon(lineStartCoord, poly);
  const isLineEndInFeature = booleanPointInPolygon(lineEndCoord, poly);
  const intersectingLines = lineIntersect(poly, line).features;

  const intersectingLineCoords: Position[] = intersectingLines.map((f) => [
    f.geometry.coordinates[1],
    f.geometry.coordinates[0],
  ]);

  isLineStartInFeature &&
    intersectingLineCoords.unshift([lineStartCoord[1], lineStartCoord[0]]);
  isLineEndInFeature &&
    intersectingLineCoords.push([lineEndCoord[1], lineEndCoord[0]]);

  return intersectingLineCoords;
};

export const featureToPolygon = (feature: Feature) => {
  const _feature = feature as Feature<Polygon>;
  return polygon([
    _feature.geometry.coordinates[0].map((m) => [
      Object.values(m)[1],
      Object.values(m)[0],
    ]),
  ]);
};

export const getGroundType = (feature: Feature) => {
  const { GroundType } = feature.properties as GroundTypeProperties;

  if (!isGroundTypeValid(GroundType)) {
    return "Invalid Route";
  }

  return GroundType;
};

export const isGroundTypeValid = (groundType: string | null | undefined) => {
  return !(
    groundType === null ||
    groundType === undefined ||
    groundType.toLowerCase() === "null"
  );
};

export const getLength = (feature: Position[]) => {
  if (!feature.length) return 0;
  return (
    distance(feature[1], feature[0], {
      units: "kilometers",
    }) * 1000
  );
};

export const getMidPointOfLinestring = (
  polyGeometry: LatLngObj,
  polyIndex: number,
  array: LatLngObj[]
) => {
  const from = point([polyGeometry.lng, polyGeometry.lat]);
  const to = point([array[polyIndex + 1].lng, array[polyIndex + 1].lat]);
  const _distance = distance(from, to);
  const fromCoords = from.geometry.coordinates;
  const toCoords = to.geometry.coordinates;
  const line = lineString([fromCoords, toCoords]);
  return along(line, _distance / 2);
};

export const getEditableJoints = (
  latlng: LatLngObj,
  marker: string,
  cable: NetworkAsset,
  connectedAssets: TransposedAsset[]
) => {
  let arr: EditableJoint[] = [];
  connectedAssets.forEach((a) => {
    const assetPt =
      a.asset.type === "cable" && (a.asset.polyGeometry as LatLngObj[]);
    if (assetPt && cable.polyGeometry) {
      const pt = assetPt[a.polyIndex as number];
      const segment = connectedSegment(cable.polyGeometry as LatLngObj[], pt);
      if (segment) {
        const segmentCoords = segment.geometry.coordinates;
        const vertex1 = point([segmentCoords[0][0], segmentCoords[0][1]]);
        const vertex2 = point([segmentCoords[1][0], segmentCoords[1][1]]);
        const joint = point([pt.lng, pt.lat]);
        const _ratio = ratio(vertex1, vertex2, joint);
        arr.push({
          latlng: calcLatLngByRatio(marker, latlng, segment, _ratio),
          assets: [a],
        });
      }
    }
  });
  return arr;
};

const calcLatLngByRatio = (
  marker: string,
  latlng: LatLngObj,
  segment: Feature<LineString, Properties>,
  ratio: number
) => {
  const markerCoord = marker.split(",").map(Number);
  const segmentCoord = segment.geometry.coordinates.map((coord) =>
    markerCoord[1] === coord[0] && markerCoord[0] === coord[1]
      ? [latlng.lng, latlng.lat]
      : coord
  );
  const vertex1 = point([segmentCoord[0][0], segmentCoord[0][1]]);
  const vertex2 = point([segmentCoord[1][0], segmentCoord[1][1]]);

  const newSegment = lineString(segmentCoord);

  const dV1V2 = distance(vertex1, vertex2);
  return pointToLatLngObj(along(newSegment, dV1V2 * ratio), true);
};

const pointToLatLngObj = (
  point: Feature<Point, Properties>,
  reverseCoords: boolean
): LatLngObj => {
  return {
    lat: point.geometry.coordinates[reverseCoords ? 1 : 0],
    lng: point.geometry.coordinates[reverseCoords ? 0 : 1],
  };
};

const ratio = (
  vertex1: Feature<Point, Properties>,
  vertex2: Feature<Point, Properties>,
  joint: Feature<Point, Properties>
) => {
  const dV1V2 = distance(vertex1, vertex2);
  const dV1J = distance(vertex1, joint);

  return dV1J / dV1V2;
};

const connectedSegment = (geometry: LatLngObj[], pt: LatLngObj) => {
  let segment: Feature<LineString, Properties> | undefined;
  geometry.forEach((poly, i, arr) => {
    if (i >= arr.length - 1) return;
    const _pt = point([pt.lng, pt.lat]);
    const _segment = lineString([
      [poly.lng, poly.lat],
      [arr[i + 1].lng, arr[i + 1].lat],
    ]);
    const distance = pointToLineDistance(_pt, _segment);

    // tolerance < 1mm
    if (distance * 1000000 < 1) {
      segment = _segment;
    }
  });
  return segment;
};
