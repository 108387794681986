import { UserRole } from "../models/enums";
import { appConfig } from "../services/configService";

const {
  publicRagConnectivity,
  icpRagConnectivity,
  ngedRagConnectivity,
  developerRagConnectivity,
} = appConfig;

const roleMap: { [key in UserRole]: string } = {
  [UserRole.public]: publicRagConnectivity,
  [UserRole.icp]: icpRagConnectivity,
  [UserRole.nged]: ngedRagConnectivity,
  [UserRole.developer]: developerRagConnectivity,
};

export const useRagConnectivity = (userRole: string) => {
  const roleRAG = roleMap[userRole as UserRole];

  return roleRAG ? roleRAG.split(",") : [];
};
