import L, { LatLngTuple, LeafletEvent } from "leaflet";
import { useMemo, useRef } from "react";
import { Marker } from "react-leaflet";
import { useAppDispatch } from "../../../app/hooks";
import { DrawableAnnotation } from "../../../models/models";
import { updateAnnotationData } from "../quotationPlanSlice";
import "./Annotation.css";

interface IAnnotation {
  annotation: DrawableAnnotation;
  onClick: () => void;
}

const Annotation = ({ annotation, onClick }: IAnnotation) => {
  const markerRef = useRef<L.Marker>(null);
  const dispatch = useAppDispatch();

  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletEvent) {
        L.DomEvent.stopPropagation(e);
        const marker = markerRef.current;
        if (marker != null) {
          onClick();
        }
      },
      dragend(e: LeafletEvent) {
        const position = e.target.getLatLng();
        dispatch(
          updateAnnotationData({
            id: annotation.id,
            key: "position",
            value: [position.lat, position.lng],
          })
        );
      },
    }),
    []
  );

  const str = `<div class="p-2" style="background-color: ${annotation.color}; border: 1px solid ${annotation.borderColor}; min-width:${annotation.width}px; width: fit-content; font-size: ${annotation.fontSize}px; color: ${annotation.textColor}; overflow: auto">${annotation.text}<div>`;

  return (
    <Marker
      ref={markerRef}
      draggable={true}
      eventHandlers={eventHandlers}
      position={annotation.position as LatLngTuple}
      icon={L.divIcon({
        html: str,
      })}
    />
  );
};

export default Annotation;
