import { LatLng } from "leaflet";
import proj4 from "proj4";

export enum TransformFormat {
  transformlatlngreverse,
  transformlatlng,
  reverselatlng,
  latlng,
  reverse,
}

export const transformCoords: any = (
  coords: LatLng[],
  levelsDeep: number,
  _coordsToLatLng: Function
) => {
  var latlngs = [];

  for (var i = 0, len = coords.length, latlng; i < len; i++) {
    latlng = levelsDeep
      ? transformCoords(coords[i], levelsDeep - 1, _coordsToLatLng)
      : (_coordsToLatLng || transformCoordToLatLngAndReverse)(coords[i]);

    latlngs.push(latlng);
  }

  return latlngs;
};

export const transformCoord = function (
  coords: number[],
  inputSRS: string = "EPSG:27700",
  outputSRS: string = "EPSG:4326"
) {
  return proj4(inputSRS, outputSRS, coords);
};

const transformCoordToLatLngAndReverse = function (coords: number[]) {
  const transformedCoords = transformCoord(coords);
  return { lat: transformedCoords[1], lng: transformedCoords[0] };
};

const transformCoordToLatLng = function (coords: number[]) {
  const transformedCoords = transformCoord(coords);
  return { lat: transformedCoords[0], lng: transformedCoords[1] };
};

const coordToLatLng = function (coords: number[]) {
  return { lat: coords[0], lng: coords[1] };
};

const reverseCoordToLatLng = function (coords: number[]) {
  return { lat: coords[1], lng: coords[0] };
};

const reverseCoord = function (coords: number[]) {
  return [coords[1], coords[0]];
};

const getCoords = (
  feature: any,
  nestingLevel: number,
  format: TransformFormat | undefined
) => {
  return transformCoords(
    feature.geometry.coordinates,
    nestingLevel,
    format === TransformFormat.transformlatlngreverse
      ? transformCoordToLatLngAndReverse
      : format === TransformFormat.transformlatlng
      ? transformCoordToLatLng
      : format === TransformFormat.reverselatlng
      ? reverseCoordToLatLng
      : format === TransformFormat.latlng
      ? coordToLatLng
      : format === TransformFormat.reverse
      ? reverseCoord
      : transformCoord
  );
};

const getCoord = (feature: any, format: TransformFormat | undefined) => {
  return format === TransformFormat.transformlatlngreverse
    ? transformCoordToLatLngAndReverse(feature.geometry.coordinates)
    : format === TransformFormat.transformlatlng
    ? transformCoordToLatLng(feature.geometry.coordinates)
    : format === TransformFormat.reverselatlng
    ? reverseCoordToLatLng(feature.geometry.coordinates)
    : format === TransformFormat.latlng
    ? coordToLatLng(feature.geometry.coordinates)
    : format === TransformFormat.reverse
    ? reverseCoord(feature.geometry.coordinates)
    : transformCoord(feature.geometry.coordinates);
};

export const convertCoords = (
  featureCollection: any,
  format: TransformFormat | undefined = undefined
) => {
  featureCollection.features.forEach((feature: any) => {
    const coords =
      feature.geometry.type === "LineString"
        ? getCoords(feature, 0, format)
        : feature.geometry.type === "Polygon" ||
          feature.geometry.type === "MultiPolygon"
        ? getCoords(feature, 1, format)
        : getCoord(feature, format);
    feature.geometry.coordinates = coords;
  });
  return featureCollection;
};
