import L from "leaflet";

export function atcIcon(colour: string | undefined): L.DivIcon {
  const svg = `<?xml version="1.0" encoding="utf-8"?>
<svg viewBox="0 0 420 420" xmlns="http://www.w3.org/2000/svg">
  <ellipse style="stroke: ${colour}; fill: ${colour}; fill-opacity: 0; stroke-width: 20px; transform-origin: 210px 210px;" cx="202" cy="202" rx="200" ry="200"/>
  <path fill=${colour} d="M 211.313 55.512 C 208.618 52.64 204.926 51 201 51 C 197.074 51 193.382 52.64 190.688 55.512 L 129.749 121.137 C 125.941 125.237 124.944 131.213 127.171 136.311 C 129.398 141.41 134.496 144.752 140.062 144.752 L 172.874 144.752 L 172.874 257.25 L 140.062 257.25 C 134.496 257.25 129.398 260.591 127.171 265.689 C 124.944 270.788 125.941 276.764 129.749 280.863 L 190.688 346.489 C 193.324 349.361 197.074 351 201 351 C 204.926 351 208.618 349.361 211.313 346.489 L 272.251 280.863 C 276.06 276.764 277.055 270.788 274.828 265.689 C 272.602 260.591 267.504 257.25 261.938 257.25 L 229.125 257.25 L 229.125 144.752 L 261.938 144.752 C 267.504 144.752 272.602 141.41 274.828 136.311 C 277.055 131.213 276.06 125.237 272.251 121.137 L 211.313 55.512 Z" style="" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 7.105427357601002e-15)"/>
</svg>`;

  return L.divIcon({
    html: svg,
    iconSize: [24, 24],
  });
}
