import { Marker } from "react-leaflet";
import { ExistingNetworkAsset } from "../../../models/models";
import { atcIcon } from "./atcIcon";
import { useDispatch } from "react-redux";
import { setSelectedAsset } from "../existingNetworkSlice";
import { layerOptionValues } from "../../layerToggle/constants";

interface ATCRequestProps {
  acceptedToConnect: ExistingNetworkAsset;
}

export const ATCRequest = ({ acceptedToConnect }: ATCRequestProps) => {
  const dispatch = useDispatch();
  const { markerGeometry, properties } = acceptedToConnect;

  if (!markerGeometry || !properties) {
    return null;
  }
  return (
    <Marker
      icon={atcIcon("blue")}
      position={markerGeometry}
      eventHandlers={{
        click: () => {
          dispatch(
            setSelectedAsset({
              ...acceptedToConnect,
              ...{ assetType: layerOptionValues.acceptedToConnect },
            })
          );
        },
      }}
    ></Marker>
  );
};
