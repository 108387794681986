import destination from "@turf/destination";
import { point, Units } from "@turf/helpers";
import { LatLngBoundsExpression } from "leaflet";
import { useMap, CircleMarker, Polygon, Polyline } from "react-leaflet";
import { layerOptionValues } from "../layerToggle/constants";

const layerMap: {
  ehvs: string;
  hvs: string;
  thirdparties: string;
  lvmains: string;
  wayleaves: string;
  serviceCables: string;
} = {
  ehvs: "#a9da75",
  hvs: "#c30c0c",
  thirdparties: "yellow",
  lvmains: "#0000FF",
  wayleaves: "magenta",
  serviceCables: "#15b6f1",
};

interface IGeometryTypeFilter {
  showAsset: boolean;
  features: any[];
  layer:
    | layerOptionValues.ehvs
    | layerOptionValues.hvs
    | layerOptionValues.thirdParties
    | layerOptionValues.lvMains
    | layerOptionValues.wayleaves
    | layerOptionValues.lvServices;
  onClick?: any;
}

const GeometryTypeFilter = ({
  showAsset,
  features,
  layer,
  onClick,
}: IGeometryTypeFilter) => {
  const styleFeature = (feature: any) => {
    if (feature.id.startsWith("duct.")) {
      return "green";
    }

    if (
      layer === layerOptionValues.ehvs &&
      feature.properties.layer === "transformer" &&
      feature.properties.type === "mr_t_smt"
    ) {
      return "gray";
    }

    if (feature.id?.startsWith("ufs")) {
      return "gray";
    }

    return layerMap[layer];
  };

  const mapZoom = useMap().getZoom();

  const getPotEnd = (feature: any) => {
    const _bearing = feature.properties.orientation;
    const _point = point([
      feature.markerGeometry.lng,
      feature.markerGeometry.lat,
    ]);
    const potEndSize = Math.pow(2, 16 - mapZoom) / 12;
    const options: { units: Units } = { units: "meters" };

    const coords: LatLngBoundsExpression = [];
    [30 - _bearing, 150 - _bearing, 270 - _bearing].forEach((bearing) => {
      if (!potEndSize) return;
      const _destination = destination(_point, potEndSize, bearing, options);
      coords.push([
        _destination.geometry.coordinates[1],
        _destination.geometry.coordinates[0],
      ]);
    });

    return coords;
  };

  return (
    <>
      {showAsset && features.length > 0 && (
        <>
          {features
            .filter((asset: any) => asset.properties.geometryType === "Polygon")
            .map((feature: any) => (
              <Polygon
                key={feature.id}
                positions={feature.polyGeometry}
                color={styleFeature(feature)}
                weight={2}
                dashArray={feature.properties.is_overhead ? [5, 5] : undefined}
                eventHandlers={{
                  click: () => {
                    onClick(feature, layer);
                  },
                }}
              ></Polygon>
            ))}
          {features
            .filter(
              (asset: any) => asset.properties.geometryType === "LineString"
            )
            .map((feature: any) => (
              <Polyline
                key={feature.id}
                positions={feature.polyGeometry}
                color={styleFeature(feature)}
                weight={2}
                dashArray={
                  feature.properties.isoverhead ||
                  feature.properties.is_overhead
                    ? [5, 5]
                    : undefined
                }
                eventHandlers={{
                  click: () => {
                    onClick(feature, layer);
                  },
                }}
              ></Polyline>
            ))}
          {features
            .filter(
              (asset: any) =>
                asset.properties.geometryType === "Point" &&
                !asset.properties.ispotend
            )
            .map((feature: any) => (
              <CircleMarker
                key={feature.id}
                center={feature.markerGeometry}
                radius={layer === layerOptionValues.wayleaves ? 6 : 4}
                color={styleFeature(feature)}
                fillColor={styleFeature(feature)}
                opacity={1}
                fillOpacity={layer === layerOptionValues.wayleaves ? 0 : 1}
                eventHandlers={{
                  click: () => {
                    onClick(feature, layer);
                  },
                }}
              ></CircleMarker>
            ))}
          {features
            .filter(
              (asset: any) =>
                asset.properties.geometryType === "Point" &&
                asset.properties.ispotend
            )
            .map((feature: any) => (
              <Polygon
                positions={getPotEnd(feature)}
                pathOptions={{ color: styleFeature(feature) }}
                fillOpacity={1}
                eventHandlers={{
                  click: () => {
                    onClick(feature, layer);
                  },
                }}
              ></Polygon>
            ))}
        </>
      )}
    </>
  );
};

export default GeometryTypeFilter;
