import proj4 from "proj4";

export const xYToLatLng = (x: number, y: number) => {
  proj4.defs([
    [
      "EPSG:27700",
      "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs",
    ],
  ]);

  const latLng = proj4("EPSG:27700", "EPSG:4326", [x, y]);

  return { lat: latLng[1], lng: latLng[0] };
};
