import { useRef, useState } from "react";
import { LatLngTuple, LeafletEvent, LeafletMouseEvent } from "leaflet";
import { Marker, Polyline, useMapEvents } from "react-leaflet";
import { Position } from "geojson";
import { v4 as uuid } from "uuid";

import { useAppDispatch } from "../../../app/hooks";
import { iconMarker } from "../../../utils/iconMarker";
import { addLine } from "../quotationPlanSlice";

const DrawLine = ({ type }: { type: string }) => {
  const markerRef = useRef(null);
  const dispatch = useAppDispatch();
  const [currentPosition, setCurrentPosition] = useState<Position>();
  const [vertices, setVertices] = useState<Position[]>([]);

  const handleLine = (e: LeafletMouseEvent) => {
    const position = [e.latlng.lat, e.latlng.lng];
    setVertices([...vertices, position]);
    if (vertices.length && type === "arrow-line") {
      dispatch(
        addLine({
          id: uuid(),
          positions: [...vertices, position],
          type,
          arrowheadSize: 1,
        })
      );
      setVertices([]);
    }
  };

  const styleMarkerIcon = (styles: string = "") => {
    return iconMarker(1, styles);
  };

  const eventHandlers = {
    click(e: LeafletEvent) {
      const marker = markerRef.current;
      if (marker != null) {
        dispatch(
          addLine({
            id: uuid(),
            positions: [...vertices],
            type,
          })
        );
        setVertices([]);
      }
    },
  };

  useMapEvents({
    mousemove(e) {
      const position = [e.latlng.lat, e.latlng.lng];
      setCurrentPosition(position);
    },
    click(e) {
      handleLine(e);
    },
  });

  return (
    <>
      {currentPosition && vertices.length && (
        <>
          <Marker
            position={currentPosition as LatLngTuple}
            icon={styleMarkerIcon("iconBorder iconGlow")}
          ></Marker>
          <Polyline
            positions={
              [vertices[vertices.length - 1], currentPosition] as LatLngTuple[]
            }
            dashArray="5 10"
          ></Polyline>
        </>
      )}
      {vertices.length > 1 && (
        <>
          <Polyline positions={[...vertices] as LatLngTuple[]}></Polyline>
          <Marker
            ref={markerRef}
            position={[...vertices[vertices.length - 1]] as LatLngTuple}
            icon={styleMarkerIcon()}
            eventHandlers={eventHandlers}
            zIndexOffset={10000}
          ></Marker>
        </>
      )}
    </>
  );
};

export default DrawLine;
