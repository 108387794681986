export enum UserRole {
  public = "public",
  icp = "icp",
  nged = "nged",
  developer = "developer",
}

export enum Feature {
  fullScreen = "FULL_SCREEN",
  userGuidance = "USER_GUIDANCE",
  debugger = "DEBUGGER",
  selectFeature = "SELECT_FEATURE",
  editPlan = "EDIT_PLAN",
  editMainsCable = "EDIT_MAINS_CABLE",
  quotationPlan = "QUOTATION_PLAN",
  quotationBasicTools = "QUOTATION_BASIC_TOOLS",
  quotationAdvancedTools = "QUOTATION_ADVANCED_TOOLS",
  layers = "LAYERS",
  atcLocation = "LAYERS_ATC_LOCATION",
  schematic = "SCHEMATIC",
  autoQuotationPlan = "AUTO_QUOTATION_PLAN",
}

export enum FeatureType {
  lineString,
  polygon,
  point,
  bbox,
}

export enum MapFeature {
  ragAssets = "rag-assets",
  osFeatures = "os-features",
}

export enum PowerTransformerMounting {
  pmt = "pmt",
  gmt = "gmt",
}

export enum FeatureClass {
  substationGmt = "substation-gmt",
  substationPmt = "substation-pmt",
  undergroundCable = "underground-cable",
  overheadLine = "overhead-line",
}

export enum RagStatus {
  red = "red",
  green = "green",
  amber = "orange",
  unknown = "unknown",
}

export enum Zoom {
  max = 14,
  min = 10,
  default = 11,
}
