import { LatLngExpression } from "leaflet";
import {
  CircleMarker,
  GeoJSON,
  Pane,
  Polygon,
  Polyline,
  Popup,
} from "react-leaflet";
import { Col, Row } from "react-bootstrap";

import { selectDebugging } from "../debugging/debuggingSlice";
import { useAppSelector } from "../../app/hooks";
import Asset from "../../components/asset/Asset";
import { NetworkAsset } from "../../models/models";
import DrawingTool from "./DrawingTool";
import { AppMode, selectApp } from "../../app/appSlice";
import { selectNetworkCreator } from "./networkCreatorSlice";
import { GeoJsonObject } from "geojson";
import Feeders from "../../containers/Feeders";
import Edit from "./Edit";

const NetworkCreator = () => {
  const debugging = useAppSelector(selectDebugging);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { debug } = debugging;
  const {
    network,
    intersectingSegments,
    radii,
    topographies,
    ragConductingSectionsAndPoles,
    lvAceGrid,
  } = networkCreator;
  const app = useAppSelector(selectApp);

  const { appMode } = app;

  const setColor = (asset: NetworkAsset) => {
    const color: string = asset.markerGeometry ? "#343a40" : "#3388ff";
    return color;
  };

  const colorRandomiser = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };

  return (
    <>
      <Pane name="debugging-assets" style={{ zIndex: 9997 }}>
        {debug.includes("LVAce Grid") && (
          <>
            {lvAceGrid && (
              <GeoJSON
                data={lvAceGrid as GeoJsonObject}
                style={{
                  weight: 1,
                  opacity: 0.2,
                  color: "purple",
                  dashArray: "0",
                }}
              />
            )}
          </>
        )}
        {debug.includes("Marker Radius Intersections") && (
          <>
            {radii.map((radius) => (
              <GeoJSON data={radius}></GeoJSON>
            ))}
            {topographies.map((topography) => (
              <Polygon
                positions={
                  topography.geometry.coordinates as LatLngExpression[][]
                }
                color="red"
              ></Polygon>
            ))}
          </>
        )}
        {debug.includes("Intersecting Segments") && (
          <>
            {intersectingSegments.map((intersectingSegment) =>
              intersectingSegment.data.map((m) => (
                <Polyline
                  positions={m.coords as LatLngExpression[]}
                  color={colorRandomiser()}
                >
                  <Popup>
                    <Row>
                      <Col xs={2}>
                        <b>Coords:</b>
                      </Col>
                      <Col>{JSON.stringify(m.coords)}</Col>
                    </Row>
                    <Row>
                      <Col xs={2}>
                        <b>Length:</b>
                      </Col>
                      <Col>{m.length}</Col>
                    </Row>
                  </Popup>
                </Polyline>
              ))
            )}
          </>
        )}
        {debug.includes("RAG Assets") && (
          <>
            {ragConductingSectionsAndPoles.map((feature: any) => (
              <>
                {feature.polyGeometry ? (
                  <Polyline
                    key={feature.id}
                    positions={feature.polyGeometry}
                    color={colorRandomiser()}
                    weight={4}
                    dashArray={feature.properties.dashArray}
                  ></Polyline>
                ) : (
                  <CircleMarker
                    key={feature.id}
                    center={feature.markerGeometry}
                    radius={8}
                    color={colorRandomiser()}
                    opacity={1}
                    fillOpacity={1}
                  ></CircleMarker>
                )}
              </>
            ))}
          </>
        )}
      </Pane>
      <Pane name="network-assets" style={{ zIndex: 9998 }}>
        <Feeders />
        {!appMode && <DrawingTool />}
        {appMode === AppMode.edit && <Edit />}
        {appMode !== AppMode.edit &&
          network.map((asset) => (
            <Asset key={asset.id} asset={asset} color={setColor(asset)}></Asset>
          ))}
      </Pane>
    </>
  );
};

export default NetworkCreator;
