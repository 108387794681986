import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import buffer from "@turf/buffer";
import { point } from "@turf/helpers";
import { Feature, Position } from "geojson";
import { LatLng } from "leaflet";
import { v4 as uuid } from "uuid";
import {
  NetworkAsset,
  LatLngObj,
  KeyValuePair,
  ExistingNetworkAsset,
  GeoServerCable,
  ConnectionPoint,
} from "../models/models";
import { getCableRag, getTransformerRag } from "../services/cableService";
import {
  featureToPolygon,
  getGroundType,
  getIntersectingLinesFromPolygon,
  getLength,
} from "./geoSpatialHelpers";
import {
  ConductingSegmentProperties,
  TransformerProperties,
} from "../models/featureProperties";
import { FeatureClass } from "../models/enums";

export const matchNetworkAsset = (
  network: NetworkAsset[],
  latlng: LatLngObj,
  types = ["marker", "cable", "site"]
) => {
  return network
    .filter((f) => f.markerGeometry)
    .find(
      (n) =>
        latLngArray(latlng, n.markerGeometry as LatLngObj) &&
        types.includes(n.type)
    );
};

export const latLngArray = (e: LatLngObj, f: LatLngObj) => {
  if (!e || !f) return;
  return e.lat === f.lat && e.lng === f.lng;
};

export const matchNetworkAssetGeometry = (
  network: NetworkAsset[],
  latlng: LatLngObj,
  types = ["marker", "cable", "site"]
) => {
  const matchedNetworkAsset = matchNetworkAsset(network, latlng, types);
  return matchedNetworkAsset ? matchedNetworkAsset.markerGeometry : null;
};

export const asset = (
  id: string = uuid(),
  type: string,
  markerGeometry?: LatLngObj,
  polyGeometry?: LatLngObj[],
  name?: string,
  ngedId?: string,
  topographyId?: string,
  firstConnectedAsset?: string,
  secondConnectedAsset?: string,
  isConnected: boolean = false,
  pointOfConnectionType?: string,
  isConsumer: boolean = false,
  cableRag?: string
) => {
  return {
    id,
    type,
    markerGeometry,
    polyGeometry,
    name,
    ngedId,
    topographyId,
    firstConnectedAsset,
    secondConnectedAsset,
    isConnected,
    pointOfConnectionType,
    isConsumer,
    cableRag,
  };
};

const existingAsset = (
  id: string,
  type: string,
  markerGeometry?: LatLngObj,
  polyGeometry?: LatLngObj[] | LatLngObj[][],
  name?: string,
  ngedId?: string,
  firstConnectedAsset?: string,
  secondConnectedAsset?: string,
  isConnected: boolean = false,
  pointOfConnectionType?: string,
  properties?: KeyValuePair,
  isConsumer: boolean = false
): ExistingNetworkAsset => {
  return {
    id,
    type,
    markerGeometry,
    polyGeometry,
    name,
    ngedId,
    firstConnectedAsset,
    secondConnectedAsset,
    isConnected,
    pointOfConnectionType,
    properties,
    isConsumer,
  };
};

export const convertFeatures = (
  features: any[],
  totalkVA: number,
  connectionPoints: ConnectionPoint[]
) => {
  const assets: ExistingNetworkAsset[] = [];
  const maxConsumerPhase = getMaxConsumerPhase(connectionPoints);

  features.forEach((feature) => {
    const coords = convertCoords(feature.geometry.coordinates);
    const properties = {
      ...feature.properties,
      geometryType: feature.geometry.type,
      ...getStyle(feature, totalkVA, maxConsumerPhase),
    };
    assets.push(
      existingAsset(
        feature.id,
        feature.properties.layer
          ? ""
          : Array.isArray(coords)
          ? "existingcable"
          : "existingpole",
        !Array.isArray(coords) ? coords : undefined,
        Array.isArray(coords) ? coords : undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        properties,
        true
      )
    );
  });
  return assets;
};

export const getMaxConsumerPhase = (connectionPoints: ConnectionPoint[]) => {
  const connectionPointPhases = connectionPoints.map((x) => {
    let phase;
    switch (x.phases.toLowerCase()) {
      case "single": {
        phase = 1;
        break;
      }
      case "three": {
        phase = 3;
        break;
      }
      default:
        phase = 0;
    }
    return phase;
  });

  return Math.max(...connectionPointPhases);
};

export const convertCoords = (coords: LatLng | LatLng[] | LatLng[][]) => {
  const type = Array.isArray(coords)
    ? Array.isArray(coords[0])
      ? "polygon"
      : "lineString"
    : "point";

  switch (type) {
    case "point":
      return {
        lat: (coords as LatLng).lat,
        lng: (coords as LatLng).lng,
      } as LatLngObj;
    case "lineString":
      return (coords as LatLng[]).map((coord: LatLng) => ({
        lat: coord.lat,
        lng: coord.lng,
      })) as LatLngObj[];
    case "polygon":
      return (coords as LatLng[][]).map((coord) =>
        (coord as LatLng[]).map((c: LatLng) => ({
          lat: c.lat,
          lng: c.lng,
        }))
      ) as LatLngObj[][];
  }
};

export const getStyle = (
  feature: Feature,
  totalKva: number,
  maxConsumerPhase: number
) => {
  const properties = feature.properties as ConductingSegmentProperties;

  const isTransformer =
    properties.Class === FeatureClass.substationGmt ||
    properties.Class === FeatureClass.substationPmt;

  const ragColor = isTransformer
    ? getTransformerRag(properties as TransformerProperties)
    : getCableRag(
        feature.properties as GeoServerCable,
        totalKva,
        maxConsumerPhase
      );

  const styles: {
    color: string | undefined;
    dashArray: number[] | undefined;
  } = { color: ragColor, dashArray: undefined };
  if (ragColor === "grey") {
    styles.dashArray = [5, 5];
  }
  return styles;
};

export const completeNetworkPolygons = (network: NetworkAsset[]) => {
  return network.map((n) => ({
    ...n,
    polyGeometry:
      n.type === "cable"
        ? n.polyGeometry
        : n.polyGeometry && n.polyGeometry.length
        ? [...n.polyGeometry, n.polyGeometry[0]]
        : undefined,
  }));
};

export const getFeatureData = (feature: Feature, coords: Position[]) => {
  const intersectingFeature = getIntersectingLinesFromPolygon(feature, coords);

  return {
    coords: intersectingFeature,
    properties: feature.properties,
    groundType: getGroundType(feature),
    length: getLength(intersectingFeature),
    pointOfConnection: booleanPointInPolygon(
      coords[1],
      featureToPolygon(feature)
    ),
  };
};

export const isMarkerClicked = (snap: LatLngObj, marker: LatLngObj) => {
  const snapPoint = point([snap.lng, snap.lat]);
  const pt = point([marker.lng, marker.lat]);
  const buffered = buffer(pt, 0.00075, { units: "kilometers" });
  return booleanPointInPolygon(snapPoint, buffered);
};
