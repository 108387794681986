import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useAppSelector } from "../../app/hooks";
import usePremises from "../../hooks/usePremises";
import useNetwork from "../../hooks/useNetwork";
import { selectSteps } from "./stepsSlice";
import { selectApp } from "../../app/appSlice";
import { selectNetworkCreator } from "../networkCreator/networkCreatorSlice";
import Step from "./Step";
import StepBody from "./StepBody";
import StepCounter from "./StepCounter";
import StepToolBtn from "./StepToolBtn";
import IconButton from "../../components/iconBtn/IconButton";
import styles from "./Steps.module.css";
import { UserRole } from "../../models/enums";

const Steps = () => {
  const networkCreator = useAppSelector(selectNetworkCreator);
  const steps = useAppSelector(selectSteps);
  const { network } = networkCreator;
  const { mode } = steps;
  const nodeRef = useRef(null);
  const { premisesCountOfTotal, allPremisesInNetwork } = usePremises();
  const { isNetworkConnected } = useNetwork();
  const { inputs, isReadOnly } = useAppSelector(selectApp);
  const { userRole, isInPublic } = inputs;

  const canUseInternalTools = userRole !== UserRole.public;

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={mode !== "closed"}
      timeout={150}
      classNames="steps"
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className={`${styles.steps} d-flex align-items-start flex-column bg-light`}
        aria-label="steps"
      >
        {!isInPublic && (
          <Step
            stepNumber={1}
            name="Draw Site Boundary"
            cssClasses="w-100"
            overlay={isReadOnly}
          >
            <StepToolBtn
              item={{ id: "", name: "site" }}
              disabled={isReadOnly}
            />
          </Step>
        )}
        <Step
          stepNumber={isInPublic ? 1 : 2}
          name="Draw premises / Place meter position"
          cssClasses="w-100 h-100"
          overlay={
            isReadOnly ||
            (isInPublic
              ? false
              : network.find((n) => n.type === "site")
              ? false
              : true)
          }
          stepBody={
            <StepBody
              disabled={
                isReadOnly ||
                (isInPublic
                  ? false
                  : network.find((n) => n.type === "site")
                  ? false
                  : true)
              }
            />
          }
        >
          <StepCounter tracker={premisesCountOfTotal} />
        </Step>
        {canUseInternalTools && (
          <Step
            stepNumber={isInPublic ? 2 : 3}
            name="Connection"
            cssClasses="w-100"
            overlay={isReadOnly || !allPremisesInNetwork}
          >
            <StepToolBtn
              item={{ id: "", name: "cable" }}
              disabled={isReadOnly || !allPremisesInNetwork}
            />
          </Step>
        )}
        <Step
          stepNumber={
            isInPublic
              ? canUseInternalTools
                ? 3
                : 2
              : canUseInternalTools
              ? 4
              : 3
          }
          name="Submit"
          cssClasses="w-100"
          overlay={isReadOnly || !isNetworkConnected}
        >
          <IconButton
            color="primary"
            icon="paper-plane-solid"
            disabled={isReadOnly || !isNetworkConnected}
          />
        </Step>
      </div>
    </CSSTransition>
  );
};

export default Steps;
