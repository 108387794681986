import { Map } from "leaflet";

function pxToMm(px: any) {
  var heightRef: any = document.createElement("div");
  heightRef.setAttribute("style", "height:1mm;");
  heightRef.setAttribute("id", "heightRef");

  document.body.appendChild(heightRef);

  heightRef = document.getElementById("heightRef");
  var pxPermm = heightRef.getBoundingClientRect().height;
  heightRef.parentNode!.removeChild(heightRef);
  return px / pxPermm;
}

export function calculateScaleRatio(map: Map): number {
  var CenterOfMap = map.getSize().y / 2;

  var RealWorlMetersPer100Pixels = map.distance(
    map.containerPointToLatLng([0, CenterOfMap]),
    map.containerPointToLatLng([100, CenterOfMap])
  );

  var ScreenMetersPer100Pixels = pxToMm(100) / 1000;

  var scaleFactor = RealWorlMetersPer100Pixels / ScreenMetersPer100Pixels;
  return Math.round(scaleFactor);
}

export function ratioToZoomLevel(targetRatio: number): number {
  const zoomLevels: number[] = [];
  zoomLevels[10] = 3292;
  zoomLevels[11] = 1646;
  zoomLevels[12] = 823;
  zoomLevels[13] = 412;

  let closestLevel = zoomLevels.indexOf(
    zoomLevels.reduce(function (prev, curr) {
      return Math.abs(curr - targetRatio) < Math.abs(prev - targetRatio)
        ? curr
        : prev;
    })
  );

  const isLower = zoomLevels[closestLevel] > targetRatio || targetRatio === 250;
  if (!isLower) {
    closestLevel -= 1;
  }

  const closestRatio = zoomLevels[closestLevel];

  return closestLevel + (closestRatio - targetRatio) / targetRatio;
}
