import L, { LatLng } from "leaflet";
import { LatLngTuple, LeafletEvent } from "leaflet";
import { useEffect, useMemo, useRef } from "react";
import { Polygon } from "react-leaflet";
import { useAppDispatch } from "../../../app/hooks";
import { DrawableShape } from "../../../models/models";
import { updateShape } from "../quotationPlanSlice";

interface IShape {
  shape: DrawableShape;
  selectedObject: boolean;
  onClick: (e: any) => void;
}

const Shape = ({ shape, selectedObject, onClick }: IShape) => {
  const dispatch = useAppDispatch();
  const shapeRef = useRef(null);

  useEffect(() => {
    const _shape = shapeRef.current as L.Polygon | null;
    if (_shape != null) {
      if (_shape.transform) {
        _shape.transform.enable();
        _shape.dragging.enable();
      }
    }
  }, []);

  const onTransformed = (e: LeafletEvent) => {
    const newPositions = e.target
      .getLatLngs()
      .map((m: LatLng[]) => m.map((n: LatLng) => [n.lat, n.lng]));

    dispatch(updateShape({ id: shape.id, newPositions }));
  };

  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletEvent) {
        L.DomEvent.stopPropagation(e);
        const shape = shapeRef.current;
        if (shape != null) {
          onClick(shape);
        }
      },
      transformed(e: LeafletEvent) {
        L.DomEvent.stopPropagation(e);
        const shape = shapeRef.current;
        if (shape != null) {
          onTransformed(e);
        }
      },
    }),
    []
  );

  return (
    <Polygon
      ref={shapeRef}
      positions={shape.positions as LatLngTuple[]}
      pathOptions={{
        weight: shape.type === "circle" ? 3 : 0,
        color: shape.color,
        fillColor: shape.color,
      }}
      fill={shape.type === "circle" ? false : true}
      fillOpacity={1}
      smoothFactor={shape.type === "circle" ? 0 : 1}
      eventHandlers={eventHandlers}
      transform={selectedObject}
      draggable={selectedObject}
    ></Polygon>
  );
};

export default Shape;
