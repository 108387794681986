import {
  TypedStartListening,
  createListenerMiddleware,
  isAnyOf,
} from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../app/store";
import { saveStudyToWebEnquiryReference } from "../services/persistService";
import {
  addToNetworkAndDebuggingAssets,
  completeAsset,
  completeAsset2,
  removeFromNetwork,
  saveEditableNetwork,
  updateNetworkAssetAndDebuggingAssets,
} from "../features/networkCreator/networkCreatorSlice";

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const saveMiddleware = createListenerMiddleware();

const startSaveMiddlewareListening =
  saveMiddleware.startListening as AppStartListening;

startSaveMiddlewareListening({
  matcher: isAnyOf(
    completeAsset,
    completeAsset2,
    addToNetworkAndDebuggingAssets,
    updateNetworkAssetAndDebuggingAssets,
    removeFromNetwork,
    saveEditableNetwork
  ),
  effect: async (_, listenerApi) => {
    const state = listenerApi.getState();
    saveStudyToWebEnquiryReference(state.app.inputs, state.networkCreator);
  },
});
