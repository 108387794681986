import { useEffect, useState } from "react";
import {
  selectApp,
  setSubmit,
  setLVAceActive,
  setLVAceMessages,
  setIsLvAceComplete,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectNetworkCreator,
  addLVAceGrid,
  completeAsset2,
} from "../../features/networkCreator/networkCreatorSlice";
import {
  LatLngObj,
  LVAceRequest,
  Message,
  NetworkAsset,
} from "../../models/models";
import { getShortestPath } from "../../services/lvAceService";
import {
  asset,
  completeNetworkPolygons,
  getMaxConsumerPhase,
} from "../../utils/networkUtils";
import LVAceModal from "./LVAceModal";
import lineIntersect from "@turf/line-intersect";
import { lineString, Position } from "@turf/helpers";
import Spinner from "../spinner/Spinner";
import { selectExistingNetwork } from "../../features/existingNetwork/existingNetworkSlice";
import { UserRole } from "../../models/enums";

type FetchCableOptions = "fetching" | "success" | "error";

const LVAce = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [cableFetch, setCablesFetched] =
    useState<FetchCableOptions>("fetching");
  const dispatch = useAppDispatch();

  const app = useAppSelector(selectApp);
  const { totalkVA, userRole, isInPublic, connectionPoints } = app.inputs;
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { network } = networkCreator;

  const existingNetwork = useAppSelector(selectExistingNetwork);
  const { conductingSections, overheadPoles } = existingNetwork;

  const canUseInternalTools = userRole !== UserRole.public;

  useEffect(() => {
    fetchLVAce();
  }, []);

  const addCables = (cables: any[]) => {
    const assets: NetworkAsset[] = [];

    cables.forEach((cable: any) => {
      const coords: LatLngObj[] = cable.geometry;
      const pointOfConnectionType = cable.startAssetId
        ? "existingcable"
        : undefined;

      const connectedAsset: any = [
        ...conductingSections,
        ...overheadPoles,
      ].find((c) => c.id === cable.startAssetId);
      const connectedColor = connectedAsset?.properties?.color
        ? connectedAsset?.properties?.color
        : undefined;

      assets.push(
        asset(
          undefined,
          "cable",
          undefined,
          coords,
          undefined,
          undefined,
          undefined,
          cable.endAssetId === null || cable.endAssetId === "null"
            ? ""
            : cable.endAssetId,
          cable.startAssetId === null || cable.startAssetId === "null"
            ? ""
            : cable.startAssetId,
          true,
          pointOfConnectionType,
          false,
          connectedColor
        )
      );

      pointOfConnectionType &&
        assets.push(
          asset(
            undefined,
            "marker",
            coords[0],
            undefined,
            "joint",
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            false
          )
        );
    });

    setConnectedAssetsForNewCables(cables, assets);
    const connectedAssets = connectNetwork(assets);

    dispatch(
      completeAsset2({
        connectedAssets,
        assets,
      })
    );
  };

  const setConnectedAssetsForNewCables = (
    cables: any[],
    assets: NetworkAsset[]
  ) => {
    const cableLineStrings = cables.flatMap((c) => {
      var positions = c.geometry.map((x: LatLngObj) => {
        return [x.lat, x.lng] as Position;
      });

      return lineString(positions);
    });

    cables.forEach((cable: any, index) => {
      const cableAssets = assets.filter((x) => x.type === "cable");
      const currentCable = cableLineStrings[index];
      const intersectingCableIndex = cableLineStrings.findIndex(
        (x) =>
          !cable.startAssetId &&
          x !== currentCable &&
          lineIntersect(currentCable, x).features.length > 0
      );
      if (intersectingCableIndex !== -1) {
        const intersectingAsset = cableAssets[intersectingCableIndex];
        cableAssets[index].secondConnectedAsset = intersectingAsset.id;
      }
    });
  };

  const connectNetwork = (assets: NetworkAsset[]) => {
    const connectedNetwork = network
      .filter((a) => a.type !== "site")
      .map((m) => {
        const connectedAsset = assets.find(
          (a) => m.id === a.firstConnectedAsset
        );
        return {
          ...m,
          isConnected: true,
          firstConnectedAsset: connectedAsset?.id,
        };
      });

    return connectedNetwork;
  };

  const convertToFeatureCollection = (gridmap: any[]) => {
    const grid = gridmap.map((g: any) => ({
      type: "Feature" as const,
      properties: {},
      geometry: {
        type: "LineString" as const,
        coordinates: [
          [g.geometry[0].lng, g.geometry[0].lat],
          [g.geometry[1].lng, g.geometry[1].lat],
        ],
      },
    }));
    return { type: "FeatureCollection" as const, features: grid };
  };

  const submitLVAceMessages = (lvAceMsgs: Message[]) => {
    dispatch(setLVAceMessages(lvAceMsgs));
    dispatch(setSubmit(true));
  };

  const fetchLVAce = async () => {
    setShowSpinner(true);
    dispatch(setLVAceMessages([]));

    const study = completeNetworkPolygons(network);
    const requestBody: LVAceRequest = {
      totalkVA: +totalkVA,
      maxConsumerPhase: getMaxConsumerPhase(connectionPoints),
      isInPublic: isInPublic,
      study,
    };
    const response = await getShortestPath(requestBody);
    if (response) {
      if (response.cables?.length) {
        dispatch(setIsLvAceComplete(true));
        addCables(response.cables);
        const collection = convertToFeatureCollection(response.map);
        dispatch(addLVAceGrid(collection));
        dispatch(setLVAceActive(false));
      } else if (response.messages) {
        if (canUseInternalTools) {
          setMessages(response.messages);
          setShowModal(true);
        } else {
          submitLVAceMessages(response.messages);
          setCablesFetched("error");
        }
      } else if (response.data && response.data.errors) {
        const errors = Object.values(response.data.errors).map(
          (error: any) => ({
            code: "",
            level: null,
            link: "",
            description: error,
          })
        );
        if (canUseInternalTools) {
          submitLVAceMessages(errors);
          setMessages(errors);
        } else {
          submitLVAceMessages(errors);
          setCablesFetched("error");
        }
        setShowSpinner(false);
      }
    } else {
      const errors = [
        {
          code: "",
          description: "unknown error",
          level: null,
          link: "",
        },
      ];
      if (canUseInternalTools) {
        submitLVAceMessages(errors);
        setMessages(errors);
        setShowModal(true);
      } else {
        submitLVAceMessages(errors);
        setCablesFetched("error");
      }
    }
  };

  const handleDialogDismissAction = () => {
    dispatch(setLVAceActive(false));
  };

  const getSpinnerText = () => {
    return cableFetch === "fetching"
      ? "Fetching Cables"
      : cableFetch === "success"
      ? "Cable routing successful"
      : cableFetch === "error"
      ? "Finalising"
      : "Loading";
  };

  return (
    <>
      {showSpinner && <Spinner text={getSpinnerText()} />}
      <LVAceModal
        messages={messages}
        showModal={showModal}
        dismissDialogAction={handleDialogDismissAction}
      />
    </>
  );
};

export default LVAce;
