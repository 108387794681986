const getEnvironmentVariable = (key: string): string => {
  const value = process.env[key];

  if (value === undefined) {
    throw new Error(`Environment variable ${key} is not defined`);
  }

  return value;
};

export const appConfig = {
  version: getEnvironmentVariable("REACT_APP_VERSION"),
  name: getEnvironmentVariable("REACT_APP_NAME"),
  apiBaseUrl: getEnvironmentVariable("REACT_APP_API_BASE_URL"),
  lvAceBaseUrl: getEnvironmentVariable("REACT_APP_LVACE_BASE_URL"),
  geoserverBaseUrl: getEnvironmentVariable("REACT_APP_GEOSERVER_BASE_URL"),
  calculate: getEnvironmentVariable("REACT_APP_CALCULATE"),
  calculateNoAssessment: getEnvironmentVariable(
    "REACT_APP_CALCULATE_NO_ASSESSMENT"
  ),
  shortestPath: getEnvironmentVariable("REACT_APP_SHORTEST_PATH"),
  layerUrl: getEnvironmentVariable("REACT_APP_LAYER_URL"),
  groundTypesUrl: getEnvironmentVariable("REACT_APP_GROUNDTYPES_URL"),
  osApiKey: getEnvironmentVariable("REACT_APP_OS_API_KEY"),
  manualCables: getEnvironmentVariable("REACT_APP_MANUAL_CABLES"),
  adminMode: getEnvironmentVariable("REACT_APP_ADMIN_MODE"),
  publicRagConnectivity: getEnvironmentVariable(
    "REACT_APP_PUBLIC_RAG_CONNECTIVITY"
  ),
  icpRagConnectivity: getEnvironmentVariable("REACT_APP_ICP_RAG_CONNECTIVITY"),
  ngedRagConnectivity: getEnvironmentVariable(
    "REACT_APP_NGED_RAG_CONNECTIVITY"
  ),
  developerRagConnectivity: getEnvironmentVariable(
    "REACT_APP_DEVELOPER_RAG_CONNECTIVITY"
  ),
  redColor: getEnvironmentVariable("REACT_APP_RED_COLOR"),
  amberColor: getEnvironmentVariable("REACT_APP_AMBER_COLOR"),
  greenColor: getEnvironmentVariable("REACT_APP_GREEN_COLOR"),
  unknownColor: getEnvironmentVariable("REACT_APP_UNKNOWN_COLOR"),
  rvdt: getEnvironmentVariable("REACT_APP_RVDT"),
  avdt: getEnvironmentVariable("REACT_APP_AVDT"),
  rut: getEnvironmentVariable("REACT_APP_RUT"),
  aut: getEnvironmentVariable("REACT_APP_AUT"),
  hmct: getEnvironmentVariable("REACT_APP_HMCT"),
  mcpf: getEnvironmentVariable("REACT_APP_MCPF"),
  uplt: getEnvironmentVariable("REACT_APP_UPLT"),
  cableTypeIdBlacklist: getEnvironmentVariable(
    "REACT_APP_CABLE_TYPE_ID_BLACKLIST"
  ),
  featureFullScreen: getEnvironmentVariable("REACT_APP_FEATURE_FULL_SCREEN"),
  featureUserGuidance: getEnvironmentVariable(
    "REACT_APP_FEATURE_USER_GUIDANCE"
  ),
  featureDebugger: getEnvironmentVariable("REACT_APP_FEATURE_DEBUGGER"),
  featureSelectFeature: getEnvironmentVariable(
    "REACT_APP_FEATURE_SELECT_FEATURE"
  ),
  featureEditMainsCable: getEnvironmentVariable(
    "REACT_APP_FEATURE_EDIT_MAINS_CABLE"
  ),
  featureEditPlan: getEnvironmentVariable("REACT_APP_FEATURE_EDIT_PLAN"),
  featureQuotationPlan: getEnvironmentVariable(
    "REACT_APP_FEATURE_QUOTATION_PLAN"
  ),
  featureLayers: getEnvironmentVariable("REACT_APP_FEATURE_LAYERS"),
  featureAddImage: getEnvironmentVariable("REACT_APP_FEATURE_SCHEMATIC"),
  quotationText1: getEnvironmentVariable(
    "REACT_APP_CONFIG_QUOTATION_PLAN_TEXT1"
  ),
  quotationText2: getEnvironmentVariable(
    "REACT_APP_CONFIG_QUOTATION_PLAN_TEXT2"
  ),
  quotationText3: getEnvironmentVariable(
    "REACT_APP_CONFIG_QUOTATION_PLAN_TEXT3"
  ),
  quotationLogo: getEnvironmentVariable("REACT_APP_CONFIG_QUOTATION_PLAN_LOGO"),
  startLocation: JSON.parse(
    getEnvironmentVariable("REACT_APP_CONFIG_START_LOCATION")
  ),
  visibleLayers: JSON.parse(
    getEnvironmentVariable("REACT_APP_CONFIG_LAYERS_VISIBLE")
  ),
};
