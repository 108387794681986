import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  AppMode,
  selectApp,
  setAppMode,
  setConsoleMode,
  setFullScreen,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Debugging from "../../features/debugging/Debugging";
import { layerOptions } from "../../features/layerToggle/constants";
import LayerToggle from "../../features/layerToggle/LayerToggle";
import { updateLayers } from "../../features/layerToggle/layerToggleSlice";
import { setActiveTool } from "../../features/networkCreator/networkCreatorSlice";
import { resetFeatures } from "../../features/osFeatures/osFeaturesSlice";
import SelectMapFeaturesToggle from "../../features/selectMapFeatures/selectMapFeaturesToggle";
import useNetwork from "../../hooks/useNetwork";
import { Feature } from "../../models/enums";
import { FeatureSwitch } from "../featureSwitch/featureSwitch";
import UserGuidance from "../userGuidance/UserGuidance";
import SchematicForm from "../../features/schematic/SchematicsForm";
import DataList from "../dataList/DataList";

enum ConsoleOption {
  userGuidance,
  debugger,
  selectMapFeatures,
  edit,
  quotationPlan,
  layerToggle,
  schematic,
}

const layers = Object.values(layerOptions)
  .filter((layer) => layer.visible && layer.value !== "wayleaves")
  .map((v) => v.value);

const Console = () => {
  const dispatch = useAppDispatch();
  const { isNetworkConnected } = useNetwork();

  const { consoleMode, fullScreen, isReadOnly, inputs } =
    useAppSelector(selectApp);
  const userRole = inputs.userRole;

  const handleChange = async (value: ConsoleOption) => {
    if (value === ConsoleOption.selectMapFeatures && value !== consoleMode) {
      dispatch(
        setActiveTool({
          activeTool: undefined,
          ngedId: undefined,
        })
      );
    } else if (value === ConsoleOption.edit) {
      dispatch(setAppMode(AppMode.edit));
      dispatch(
        setActiveTool({
          activeTool: undefined,
          ngedId: undefined,
        })
      );
    } else if (value === ConsoleOption.quotationPlan) {
      dispatch(setAppMode(AppMode.quotationPlan));
      dispatch(
        setActiveTool({
          activeTool: undefined,
          ngedId: undefined,
        })
      );
      dispatch(updateLayers(layers));
    } else {
      if (value === consoleMode) {
        dispatch(setConsoleMode(undefined));
        return;
      }
      dispatch(setAppMode(undefined));
      dispatch(resetFeatures());
    }
    dispatch(setConsoleMode(value));
  };

  const getActiveClass = (option: ConsoleOption) => {
    return consoleMode === option ? "active" : "";
  };

  const handleFullScreen = (_fullScreen: boolean) => {
    window.parent.postMessage(
      {
        message: JSON.stringify({ fullScreen: _fullScreen }, null, "\t"),
      },
      "*"
    );
    dispatch(setFullScreen(_fullScreen));
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="position-relative">
        {consoleMode === ConsoleOption.userGuidance && <UserGuidance />}
        {consoleMode === ConsoleOption.debugger && <Debugging />}
        {consoleMode === ConsoleOption.selectMapFeatures && (
          <SelectMapFeaturesToggle />
        )}
        {consoleMode === ConsoleOption.layerToggle && <LayerToggle />}
        {consoleMode === ConsoleOption.schematic && <SchematicForm />}
        <DataList />
      </div>
      <div className="mb-2 ml-2">
        <ButtonGroup vertical>
          <FeatureSwitch feature={Feature.fullScreen} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="full-screen">Full Screen</Tooltip>}
            >
              <Button
                aria-label="Full Screen"
                variant="light"
                onClick={() => handleFullScreen(!fullScreen)}
                className="mb-2"
              >
                <i
                  className={
                    fullScreen ? "icon-contract-screen" : "icon-expand-screen"
                  }
                ></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>

          <FeatureSwitch feature={Feature.userGuidance} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="user-guidance">User Guidance</Tooltip>}
            >
              <Button
                id="0"
                variant="light"
                value="user-guidance"
                className={getActiveClass(ConsoleOption.userGuidance)}
                onClick={() => handleChange(ConsoleOption.userGuidance)}
              >
                <i className="icon-question-circle-solid"></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>

          <FeatureSwitch feature={Feature.debugger} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="debugger">Debugger</Tooltip>}
            >
              <Button
                id="1"
                variant="light"
                value="debugger"
                className={getActiveClass(ConsoleOption.debugger)}
                onClick={() => handleChange(ConsoleOption.debugger)}
              >
                <i className="icon-bug-solid"></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>

          <FeatureSwitch feature={Feature.selectFeature} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="os-features">Select Feature</Tooltip>}
            >
              <Button
                id="2"
                variant="light"
                value="os-features"
                className={getActiveClass(ConsoleOption.selectMapFeatures)}
                onClick={() => handleChange(ConsoleOption.selectMapFeatures)}
              >
                <i className="icon-eye"></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>

          <FeatureSwitch feature={Feature.editPlan} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip
                  id="edit"
                  className={isNetworkConnected ? "d-inline" : "d-none"}
                >
                  Edit
                </Tooltip>
              }
            >
              <Button
                id="3"
                variant="light"
                value="edit"
                className={getActiveClass(ConsoleOption.edit)}
                onClick={() => handleChange(ConsoleOption.edit)}
                disabled={!isNetworkConnected}
              >
                <i className="icon-edit"></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>

          <FeatureSwitch feature={Feature.quotationPlan} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip
                  id="siteplan"
                  className={isNetworkConnected ? "d-inline" : "d-none"}
                >
                  Quotation Plan
                </Tooltip>
              }
            >
              <Button
                id="4"
                variant="light"
                value="siteplan"
                className={getActiveClass(ConsoleOption.quotationPlan)}
                onClick={() => handleChange(ConsoleOption.quotationPlan)}
                disabled={!isNetworkConnected}
              >
                <i className="icon-edit-solid"></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>

          <FeatureSwitch feature={Feature.layers} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="layers">Layers</Tooltip>}
            >
              <Button
                id="5"
                variant="light"
                value="layer-toggle"
                className={getActiveClass(ConsoleOption.layerToggle)}
                onClick={() => handleChange(ConsoleOption.layerToggle)}
              >
                <i className="icon-layer-group-solid"></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>
          <FeatureSwitch feature={Feature.schematic} userRole={userRole}>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip
                  id="add-image"
                  className={!isReadOnly ? "d-inline" : "d-none"}
                >
                  Add Image
                </Tooltip>
              }
            >
              <Button
                id="6"
                variant="light"
                value="add-image"
                className={getActiveClass(ConsoleOption.schematic)}
                onClick={() => handleChange(ConsoleOption.schematic)}
                disabled={isReadOnly}
              >
                <i className="icon-image-solid"></i>
              </Button>
            </OverlayTrigger>
          </FeatureSwitch>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Console;
