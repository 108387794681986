import { useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  ElementFormatType,
  TextFormatType,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $isListNode, ListNode } from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isCodeNode, getDefaultCodeLanguage } from "@lexical/code";
import {
  $patchStyleText,
  $getSelectionStyleValueForProperty,
} from "@lexical/selection";
import ColorDropdown from "../TextEditorDropDown";
import { DrawableAnnotation } from "../../../../models/models";

const LowPriority = 1;

interface IToolbarPlugin {
  annotation: DrawableAnnotation;
}

export default function ToolbarPlugin({ annotation }: IToolbarPlugin) {
  const [editor] = useLexicalComposerContext();
  const [activeEditor] = useState(editor);
  const [fontSize, setFontSize] = useState<string>("12px");
  const [fontColor, setFontColor] = useState<string>("black");
  const toolbarRef = useRef(null);
  const [, setBlockType] = useState("paragraph");
  const [, setSelectedElementKey] = useState<string | null>(null);
  const [, setCodeLanguage] = useState("");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  useEffect(() => {
    if (!annotation.lexical) return;
    const editorState = editor.parseEditorState(annotation.lexical);
    editor.setEditorState(editorState);
  }, []);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setFontSize(
        $getSelectionStyleValueForProperty(selection, "font-size", "12px")
      );
      setFontColor(
        $getSelectionStyleValueForProperty(selection, "color", "black")
      );
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [activeEditor]
  );

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  const onFontSizeSelect = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            "font-size": option,
          });
        }
      });
    },
    [editor]
  );

  const FONT_SIZE_OPTIONS: [string, string][] = [
    ["12px", "12"],
    ["14px", "14"],
    ["16px", "16"],
    ["18px", "18"],
    ["20px", "20"],
    ["22px", "22"],
    ["24px", "24"],
    ["26px", "26"],
    ["28px", "28"],
    ["30px", "30"],
    ["32px", "32"],
    ["34px", "34"],
    ["36px", "36"],
  ];

  const FONT_COLOR_OPTIONS: [string, string][] = [
    ["red", "Red"],
    ["green", "Green"],
    ["black", "Black"],
    ["blue", "Blue"],
  ];

  const TEXT_ALIGNMENTS: ElementFormatType[] = [
    "left",
    "center",
    "right",
    "justify",
  ];

  const TEXT_FORMATS: [TextFormatType, boolean][] = [
    ["bold", isBold],
    ["italic", isItalic],
    ["underline", isUnderline],
  ];

  return (
    <div ref={toolbarRef}>
      <ButtonGroup size="sm" className="mr-1">
        {TEXT_FORMATS.map(([format, func]) => (
          <Button
            variant="light"
            className={func ? "active" : ""}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
            }}
          >
            <i className={`icon-${format}`}></i>
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup size="sm" className="mr-1">
        <ColorDropdown
          value={fontSize}
          icon={false}
          array={FONT_SIZE_OPTIONS}
          onClick={(e) => onFontSizeSelect(e)}
        />
        <ColorDropdown
          value={fontColor}
          icon={true}
          array={FONT_COLOR_OPTIONS}
          onClick={(e) => onFontColorSelect(e)}
        />
      </ButtonGroup>
      <ButtonGroup size="sm">
        {TEXT_ALIGNMENTS.map((alignment) => (
          <Button
            variant="light"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, alignment);
            }}
          >
            <i className={`icon-align-${alignment}`}></i>
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
