import { useState, useEffect, useRef } from "react";
import { LatLng, LeafletEvent, LeafletMouseEvent } from "leaflet";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addEditableNetwork,
  addPolyVertex,
  deleteVertices,
  editNetwork,
  replaceEditableNetwork,
  selectNetworkCreator,
  completeSegment,
  completeEditableAsset,
} from "./networkCreatorSlice";
import AddMarker from "./AddMarker";
import EditMarker from "./EditMarker";
import Asset from "../../components/asset/Asset";
import {
  LatLngObj,
  ExistingNetworkAsset,
  NetworkAsset,
  FeatureData,
  SharedPoints,
  EditableJoint,
  TransposedAsset,
} from "../../models/models";
import { useMap, useMapEvents, Marker, Polyline } from "react-leaflet";
import { handleSnapping } from "../../utils/snapFns";
import {
  selectApp,
  updateDialog,
  EditMode,
  setEditMode,
} from "../../app/appSlice";
import { selectExistingNetwork } from "../existingNetwork/existingNetworkSlice";
import {
  getEditableJoints,
  getMidPointOfLinestring,
  isGroundTypeValid,
} from "../../utils/geoSpatialHelpers";
import DeleteMarker from "./DeleteMarker";
import Hints from "./Hints";
import {
  refineSnapping,
  _calcClosestAsset,
  _calcLayerDistances,
} from "../../utils/snapping.js";
import { filterByProperty } from "../../utils/filterByProperty";
import {
  asset,
  getFeatureData,
  matchNetworkAsset,
} from "../../utils/networkUtils";
import { iconMarker } from "../../utils/iconMarker";
import { deviceType } from "detect-it";
import { FeatureCollection, Position, GeoJsonProperties } from "geojson";
import { convertCoords, TransformFormat } from "../../utils/convertCoords";
import { updateConnectedAssets } from "../../utils/networkConnector";
import DialogModal from "../../components/dialogModal/DialogModal";
import { v4 as uuid } from "uuid";
import { useRagConnectivity } from "../../hooks/useRagConnectivity";
import {
  PowerTransformerMounting,
  UserRole,
  FeatureType,
} from "../../models/enums";
import { useFeatureCollection } from "../../hooks/useFeatureCollection";

const polyTypeFilter: string[] = ["cable"];
const markerTypeFilter: string[] = [
  "marker",
  "new-domestic",
  "new-non-domestic",
  "new-mixed",
  "existing-domestic",
  "existing-non-domestic",
  "existing-mixed",
];

let markers: SharedPoints[];
let _editableNetwork: NetworkAsset[];

const Edit = () => {
  const map = useMap();
  const app = useAppSelector(selectApp);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const {
    activeTool,
    editableNetwork,
    vertices,
    network,
    ngedId,
    groundTypes,
    firstConnectedAsset,
    existingConnectedAssets,
  } = networkCreator;
  const markerRef = useRef(null);
  const dispatch = useAppDispatch();
  const { getFeatureCollection } = useFeatureCollection();

  const { userRole } = app.inputs;
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const { conductingSections, overheadPoles, powerTransformers } =
    existingNetwork;
  const poleMountedTransformers = powerTransformers.filter(
    (transformer) =>
      transformer.properties?.Mounting === PowerTransformerMounting.pmt
  );

  const ragConnectivity = useRagConnectivity(userRole);

  const [isSnapped, setIsSnapped] = useState(false);
  const [isCableComplete, setIsCableComplete] = useState(false);
  const [currentLatLng, setCurrentLatLng] = useState<LatLngObj | undefined>(
    undefined
  );
  const [currentSnappedAsset, setCurrentSnappedAsset] = useState<
    ExistingNetworkAsset | undefined
  >(undefined);
  const [, setInvalidIntersections] = useState<Position[][]>([]);

  const [, setInvalidTopographies] = useState<Position[][][]>([]);

  useEffect(() => {
    dispatch(addEditableNetwork());
  }, []);

  useMapEvents({
    mousemove(e) {
      if (app.editMode === EditMode.drawCable) {
        const snap = handleDrawCableSnapping(e);
        setCurrentSnappedAsset(snap.closestAsset.asset);
        setCurrentLatLng(snap.refinedSnapping);
        setIsSnapped(!compareLatLng(snap.refinedSnapping, e.latlng));
      }
    },
    click(e) {
      if (app.editMode === EditMode.drawCable) {
        switch (activeTool) {
          case "cable":
            setIsCableComplete(false);
            return handleCable(e);
          default:
            return true;
        }
      }
    },
  });

  const styleMarkerIcon = (styles: string = "") => {
    return iconMarker(1, styles);
  };

  const eventHandlers = {
    dblclick(e: LeafletMouseEvent) {
      const marker = markerRef.current;
      if (marker != null) {
        setIsSnapped(true);
        setIsCableComplete(false);
        handleCable(e);
      }
    },
  };

  const getIconSize = (network: NetworkAsset[], latlng: LatLngObj) => {
    const marker = matchNetworkAsset(network, latlng);
    return marker && marker.name ? 1 : 1;
  };

  const styleIcon = (index: number, latlng: LatLngObj) => {
    const iconSize = getIconSize(network, latlng);
    const getIndex = activeTool === "cable" ? vertices.length - 1 : 0;
    const iconGlow = getIndex === index ? "iconGlow" : "";
    return iconMarker(iconSize, `iconBorder ${iconGlow}`);
  };

  const compareLatLng = (a: LatLngObj | LatLngObj, b: LatLng | LatLngObj) => {
    return a.lat === b.lat && a.lng === b.lng;
  };

  const compareLatLngs = (arr: LatLngObj[], arr2: LatLngObj[]) => {
    return arr.some((a) => arr2.some((b) => compareLatLng(a, b)));
  };

  const isNetworkConnected = (connectedAssets?: NetworkAsset[]) => {
    if (!connectedAssets) return false;
    const assetIds = connectedAssets.map((p) => p.id);
    const filteredNetwork = [...network].filter(
      (f) => !assetIds.includes(f.id)
    );
    const updatedNetwork = [...filteredNetwork, ...connectedAssets];
    return updatedNetwork
      .filter((a) => a.type !== "site")
      .find((a) => !a.isConnected);
  };

  const handleDrawCableSnapping = (e: LeafletMouseEvent) => {
    const snappableAssets = cableSnapList();
    const closestAsset = _calcClosestAsset(map, e.latlng, snappableAssets);
    const refinedSnapping = refineSnapping(map, e, closestAsset, 6);
    return { closestAsset, refinedSnapping };
  };

  const snappableVertex = (
    activeTool: string | undefined,
    vertices: LatLngObj[]
  ) => {
    return activeTool === "cable" ? vertices[vertices.length - 1] : vertices[0];
  };

  const cableSnapList = () => {
    // Get either first or last vertex depending if the activeTool is a cable or site
    const vertex = snappableVertex(activeTool, vertices);

    // Create network asset from vertex
    const hintMarker: NetworkAsset = asset(undefined, "marker", vertex);
    // Filter network by markers
    let filteredNetwork = network.filter((n) => n.type !== "site");

    filteredNetwork = filteredNetwork.map((asset) => ({
      id: asset.id,
      type: [
        "new-domestic",
        "new-non-domestic",
        "existing-domestic",
        "existing-non-domestic",
        "new-mixed",
        "existing-mixed",
      ].includes(asset.type)
        ? "marker"
        : asset.type,
      markerGeometry: asset.markerGeometry,
      name: asset.name,
      ngedId: ngedId,
      topographyId: asset.topographyId,
      polyGeometry: asset.polyGeometry,
      firstConnectedAsset: asset.firstConnectedAsset,
      secondConnectedAsset: asset.secondConnectedAsset,
      isConnected: asset.isConnected,
      pointOfConnectionType: asset.pointOfConnectionType,
      isConsumer: asset.isConsumer,
      cableRag: asset.cableRag,
    }));

    const undergroundConductingSections = conductingSections.filter(
      (c) => (c?.properties as any).Class === "underground-cable"
    );

    const filteredConductingSections = filterByProperty(
      ragConnectivity || [],
      undergroundConductingSections,
      "color"
    );
    const filteredOverheadPoles = filterByProperty(
      ragConnectivity || [],
      overheadPoles,
      "color"
    );

    // Copy network
    let snappableAssets = [
      ...filteredNetwork,
      ...filteredConductingSections,
      ...filteredOverheadPoles,
    ];

    if (vertices.length) {
      snappableAssets = [hintMarker];
    }

    return snappableAssets;
  };

  const handleCable = (e: LeafletMouseEvent) => {
    let snap = currentLatLng;
    if (deviceType !== "mouseOnly") {
      snap = handleDrawCableSnapping(e).refinedSnapping;
    }

    if (vertices.length) {
      const line = [vertices[vertices.length - 1], snap as LatLngObj];
      runFeatures(line).then((result) => {
        if (result.invalid) {
          dispatch(
            updateDialog({
              props: ["cable", snap, result.features],
              type: "warning",
              className: "warning",
              affirmLabel: "OK",
              dismissLabel: "Cancel",
              messages: [
                {
                  description:
                    "Please note, your cable route has crossed an invalid ground type. Please check your route.",
                },
              ],
            })
          );
        } else {
          if (firstConnectedAsset && isSnapped && currentSnappedAsset) {
            if (
              firstConnectedAsset.id !== currentSnappedAsset.id &&
              firstConnectedAsset.type !== currentSnappedAsset.type
            ) {
              if (internalUserHasSnappedToOHTransformer()) {
                dispatch(
                  updateDialog({
                    props: ["cable", snap, result.features],
                    type: "warning",
                    className: "warning",
                    affirmLabel: "OK",
                    dismissLabel: "Cancel",
                    messages: [
                      {
                        description:
                          "Connection directly to O/H transformer, please ensure that an HV shutdown isn't required.",
                      },
                    ],
                  })
                );
              } else {
                finaliseCable(snap, result.features);
              }
            }

            return;
          }
          finaliseCable(snap, result.features);
        }
      });
    } else {
      finaliseCable(snap);
    }
  };

  const internalUserHasSnappedToOHTransformer = () => {
    return (
      userRole !== UserRole.developer &&
      currentSnappedAsset?.type === "existingpole" &&
      (currentSnappedAsset?.properties as any).p24 === 1
    );
  };

  const runFeatures = async (line: LatLngObj[]) => {
    let invalid = false;
    const _features: FeatureData[] = [];
    const reversedCoords: Position[] = line.map((m) => [m.lng, m.lat]);

    const featureCollection = getFeatureCollection(
      reversedCoords,
      FeatureType.lineString,
      groundTypes
    );
    const reversedFeatureCollection: FeatureCollection = convertCoords(
      featureCollection,
      TransformFormat.reverselatlng
    );

    reversedFeatureCollection.features.forEach((feature) => {
      _features.push(getFeatureData(feature, reversedCoords));
    });

    const firstConnectedAssetFid = firstConnectedAsset?.topographyId;

    const invalidFeatures = _features
      .filter(
        (feature) =>
          feature?.properties?.Fid !== firstConnectedAssetFid &&
          isGroundTypeValid(feature?.properties?.GroundType)
      )
      .map((m) => m.coords);

    if (invalidFeatures.length) {
      setInvalidIntersections(invalidFeatures);
      invalid = true;
    }
    return { features: _features, invalid };
  };

  const finaliseCable = (snap?: LatLngObj, features?: FeatureData[]) => {
    let _currentSnappedAsset: NetworkAsset | undefined;
    if (vertices.length < 1) {
      if (isSnapped && currentSnappedAsset) {
        _currentSnappedAsset = currentSnappedAsset;
      }
    }

    if (vertices.length && isSnapped) {
      completeCable(features);
      return;
    }

    if (!vertices.length && !isSnapped) {
      return;
    }

    // If the same cable joint has been clicked more than once, then ignore it
    // unless we have double clicked to finish the drawing of the cable
    if (vertices.length && snap && !isCableComplete) {
      if (vertices.find((v) => v.lat === snap.lat && v.lng === snap.lng))
        return;
    }

    dispatch(
      completeSegment({
        snap,
        features,
        firstConnectedAsset: _currentSnappedAsset,
      })
    );
    setInvalidIntersections([]);
  };

  const completeCable = (features?: FeatureData[]) => {
    const allVertices = isSnapped
      ? [...vertices]
      : [...vertices, currentLatLng as LatLngObj];

    // Init empty array
    let assets: NetworkAsset[] = [];
    let pointOfConnectionType = undefined;
    let _firstConnectedAsset: NetworkAsset | undefined = firstConnectedAsset;
    let secondConnectedAsset: NetworkAsset | undefined;
    let _existingConnectedAssets: ExistingNetworkAsset[] | undefined;
    if (isSnapped && currentSnappedAsset) {
      secondConnectedAsset = currentSnappedAsset;

      // if connected asset is a cable, check if it should be an overhead pole and replace it
      _firstConnectedAsset =
        findOverheadPole(firstConnectedAsset, allVertices) ||
        firstConnectedAsset;
      secondConnectedAsset =
        findOverheadPole(secondConnectedAsset, allVertices) ||
        secondConnectedAsset;

      _existingConnectedAssets = filterExistingConnectedAssets(
        _firstConnectedAsset,
        secondConnectedAsset
      );

      pointOfConnectionType = getPointOfConnectionType(
        [_firstConnectedAsset?.type, secondConnectedAsset.type],
        ["existingcable", "existingpole", "cable"]
      );
    }

    const markerGeometry = getJoint(secondConnectedAsset);

    const jointId = `joint_${uuid()}`;
    if (markerGeometry) {
      assets.push({
        id: jointId,
        type: "marker",
        name: "joint",
        isConnected: true,
        isConsumer: false,
        markerGeometry: markerGeometry,
      });
    }

    const potEndId = `pot_end_${uuid()}`;
    assets.push({
      id: potEndId,
      type: "marker",
      name: "pot_end",
      isConnected: true,
      isConsumer: false,
      markerGeometry: currentLatLng,
    });

    assets.push({
      id: uuid(),
      type: "cable",
      cableType: "Mains",
      cableType2: app.editCableType,
      ngedId: "",
      isConnected: true,
      pointOfConnectionType: pointOfConnectionType,
      isConsumer: false,
      firstConnectedAsset: _firstConnectedAsset?.id,
      secondConnectedAsset: "",
      editModeFirstConnectedAsset: jointId,
      editModeSecondConnectedAsset: potEndId,
      polyGeometry: allVertices,
    });

    const connectedAssets = updateConnectedAssets(
      assets,
      network,
      conductingSections,
      overheadPoles,
      existingConnectedAssets
    );

    const _activeTool = isNetworkConnected(connectedAssets) ? "cable" : "";

    dispatch(
      completeEditableAsset({
        connectedAssets,
        assets,
        _activeTool,
        features: getFeaturesData(features),
        existingConnectedAssets: _existingConnectedAssets,
      })
    );

    dispatch(setEditMode(undefined));
  };

  const getFeaturesData = (features?: FeatureData[]) => {
    if (!features || !features.length) {
      return [];
    }

    const getValidProperties = (properties: GeoJsonProperties) => {
      if (!properties) {
        return null;
      }

      return {
        Fid: properties["Fid"],
        GroundType: properties["GroundType"],
        Theme: properties["Theme"],
        DescriptiveGroup: properties["DescriptiveGroup"],
        DescriptiveTerm: properties["DescriptiveTerm"],
        Make: properties["Make"],
      };
    };

    return features.map((x) => {
      return {
        ...x,
        properties: getValidProperties(x.properties),
      };
    });
  };

  const filterExistingConnectedAssets = (
    _firstConnectedAsset: ExistingNetworkAsset | undefined,
    secondConnectedAsset: ExistingNetworkAsset | undefined
  ) => {
    let filteredExistingConnectedAssets: ExistingNetworkAsset[] = [];
    [_firstConnectedAsset, secondConnectedAsset].forEach((asset) => {
      if (
        asset &&
        (asset.id.startsWith("conducting_sections") ||
          asset.id.startsWith("overhead_poles"))
      ) {
        filteredExistingConnectedAssets.push(asset);
      }
    });
    return filteredExistingConnectedAssets.length
      ? filteredExistingConnectedAssets
      : undefined;
  };

  const getJoint = (secondConnectedAsset: NetworkAsset | undefined) => {
    return firstConnectedAsset?.type === "existingcable" ||
      firstConnectedAsset?.type === "existingpole" ||
      firstConnectedAsset?.type === "cable"
      ? vertices[0]
      : secondConnectedAsset?.type === "existingcable" ||
        secondConnectedAsset?.type === "cable"
      ? currentLatLng
      : undefined;
  };

  const getPointOfConnectionType = (arr: any, arr2: string[]) => {
    return arr.find((a: string) => arr2.includes(a));
  };

  const findOverheadPole = (
    connectedAsset: NetworkAsset | undefined,
    allVertices: LatLngObj[]
  ) => {
    if (!connectedAsset) return;

    // if connected asset is a cable, find it on the network
    const cable = network.find(
      (c) => c.id === connectedAsset.id && c.type === "cable"
    );

    // or return if not
    if (!cable) return;

    // get new cable start and end point
    const newCablePoints = [
      allVertices[0],
      allVertices[allVertices.length - 1],
    ];

    // get cable start and end point
    const cablePoints = cable.polyGeometry as LatLngObj[];

    // if cables share a start or end point, find and return cable first or second connected overhead pole
    if (compareLatLngs(newCablePoints, cablePoints)) {
      if (cable.firstConnectedAsset?.startsWith("overhead_poles"))
        return overheadPoles.find((op) => op.id === cable.firstConnectedAsset);
      if (cable.secondConnectedAsset?.startsWith("overhead_poles"))
        return overheadPoles.find((op) => op.id === cable.secondConnectedAsset);
    }

    return;
  };

  const createNetworkMidPointMarkers = () => {
    const arr: TransposedAsset[] = [];
    editableNetwork.forEach((asset, index) => {
      polyTypeFilter.includes(asset.type) &&
        (asset.polyGeometry as LatLngObj[])?.forEach(
          (polyGeometry, polyIndex, array) => {
            if (array.length === polyIndex + 1) return;
            const position = getMidPointOfLinestring(
              polyGeometry,
              polyIndex,
              array
            );
            arr.push({
              id: `${position.geometry.coordinates[1]},${position.geometry.coordinates[0]}`,
              coord: new LatLng(
                position.geometry.coordinates[1],
                position.geometry.coordinates[0]
              ),
              asset,
              index,
              polyIndex,
            });
          }
        );
    });
    return arr;
  };

  const createNetworkMarkers = () => {
    const arr: TransposedAsset[] = [];
    editableNetwork.forEach((asset, index) => {
      polyTypeFilter.includes(asset.type) &&
        (asset.polyGeometry as LatLngObj[])?.forEach(
          (polyGeometry, polyIndex) => {
            arr.push({
              id: `${polyGeometry.lat},${polyGeometry.lng}`,
              coord: { lat: polyGeometry.lat, lng: polyGeometry.lng },
              asset,
              index,
              polyIndex,
            });
          }
        );
      markerTypeFilter.includes(asset.type) &&
        asset.markerGeometry &&
        arr.push({
          id: `${asset.markerGeometry.lat},${asset.markerGeometry.lng}`,
          coord: {
            lat: asset.markerGeometry.lat,
            lng: asset.markerGeometry.lng,
          },
          asset,
          index,
        });
    });
    return arr;
  };

  const setColor = (asset: NetworkAsset) => {
    const color: string = asset.markerGeometry ? "#343a40" : "#3388ff";
    return color;
  };

  const findAssetName = (name: string[], assets: any) => {
    const hasName = assets.find((f: any) => name.includes(f.asset.name));
    return hasName ? hasName.asset.name : "";
  };

  const findMarker = (
    assets: TransposedAsset[]
  ): TransposedAsset | undefined => {
    return assets.find((f: any) => f.asset.type !== "cable");
  };

  const findCable = (
    moveableMarker: TransposedAsset | undefined,
    assets: TransposedAsset[]
  ): TransposedAsset | undefined => {
    if (moveableMarker) {
      return assets.find(
        (a) =>
          a.asset.type === "cable" &&
          (a.asset.editModeFirstConnectedAsset === moveableMarker.asset.id ||
            a.asset.editModeSecondConnectedAsset === moveableMarker.asset.id)
      );
    } else {
      return assets.find((a) => a.asset.type === "cable");
    }
  };

  const getLatLngObj = (e: LeafletEvent): LatLngObj => {
    return {
      lat: e.target.getLatLng().lat,
      lng: e.target.getLatLng().lng,
    };
  };

  const filteredConnectedToCable = (
    cable: NetworkAsset,
    assetIds: string[]
  ): TransposedAsset[] => {
    const polyIndex = (asset: NetworkAsset) =>
      asset.editModeFirstConnectedAsset === cable.id
        ? 0
        : asset.editModeSecondConnectedAsset === cable.id
        ? asset.polyGeometry && asset.polyGeometry.length - 1
        : undefined;

    return _editableNetwork
      .map((asset, index) => ({
        id: asset.id,
        index,
        polyIndex: polyIndex(asset),
        asset,
      }))
      .filter(
        (f) =>
          cable?.id &&
          (f.asset.editModeFirstConnectedAsset === cable.id ||
            f.asset.editModeSecondConnectedAsset === cable.id) &&
          !assetIds.includes(f.asset.id)
      );
  };

  const handleMidPointCableJoints = (
    latlng: LatLngObj,
    marker: string,
    assets: TransposedAsset[]
  ) => {
    const cableData = assets.find((a) => a.asset.type === "cable");
    const cable = cableData?.asset;
    const assetIds = assets.map((a) => a.asset.id);

    let editableJoints: EditableJoint[] = [];
    if (cable) {
      const connectedToCable = filteredConnectedToCable(cable, assetIds);
      editableJoints = [
        ...getEditableJoints(latlng, marker, cable, connectedToCable),
      ];
    }
    return editableJoints;
  };

  const handleMoveMarker = (e: LeafletEvent, marker: string) => {
    const _e = { latlng: e.target.getLatLng() };
    let latlng = getLatLngObj(e);

    const assets = markers.find((m) => m.id === marker)!.arr;

    const isMeter = assets.find(
      (f: any) => f.asset.ngedId && f.asset.name !== "cabinet"
    );
    if (isMeter) {
      latlng = _calcLayerDistances(map, latlng, isMeter.asset).latlng;
    }
    const moveableMarker = findMarker(assets);
    const cable = findCable(moveableMarker, assets);
    const _assets = moveableMarker && cable ? [moveableMarker, cable] : assets;

    const filteredEditableNetwork = _editableNetwork.filter(
      (asset) => asset.type === "cable" && asset.id !== cable?.asset.id
    );

    let replaceConnectedCable = cable !== undefined ? cable.asset.id : "";
    let replaceConnectedCableType =
      cable !== undefined ? cable.asset.pointOfConnectionType : "";

    if (moveableMarker && moveableMarker.asset.name === "joint") {
      const snappedAsset = handleSnapping(
        _e,
        map,
        ragConnectivity,
        filteredEditableNetwork,
        conductingSections,
        overheadPoles,
        poleMountedTransformers
      );
      replaceConnectedCable = snappedAsset.closestAsset.asset.id;
      replaceConnectedCableType = snappedAsset.closestAsset.asset.type;
      latlng = snappedAsset.refinedSnapping;
    }

    const editableJoint: EditableJoint = { latlng, assets: _assets };
    let editableJoints: EditableJoint[] = handleMidPointCableJoints(
      latlng,
      marker,
      assets
    );

    dispatch(
      editNetwork({
        joints: [editableJoint, ...editableJoints],
        cable: replaceConnectedCable,
        cableType: replaceConnectedCableType,
      })
    );
  };

  const handleAddMarker = (e: LeafletEvent, asset: TransposedAsset) => {
    let latlng = getLatLngObj(e);

    dispatch(
      addPolyVertex({
        latlng,
        asset,
      })
    );
  };

  const handleDeleteMarker = (marker: string) => {
    const deletableAssets = _editableNetwork.find(
      (f) =>
        f.editModeFirstConnectedAsset === marker ||
        f.editModeSecondConnectedAsset === marker
    );

    const filteredEditableNetwork = _editableNetwork.filter(
      (asset) =>
        ![
          deletableAssets?.id,
          deletableAssets?.editModeFirstConnectedAsset,
          deletableAssets?.editModeSecondConnectedAsset,
        ].includes(asset.id)
    );

    dispatch(replaceEditableNetwork(filteredEditableNetwork));
  };

  const handleDeleteCableMarker = (marker: string) => {
    const assets = markers.find((m) => m.id === marker)!.arr;
    if (assets.some((x) => x.asset.type !== "cable")) {
      return;
    }

    const cableAssets = assets.filter((a) => a.asset.type === "cable");

    dispatch(
      deleteVertices({
        assets: cableAssets,
      })
    );
  };

  const groupedArray = (objectArray: any[], property: string) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      const marker = acc.find((a: any) => a.id === key);
      if (marker) {
        marker.arr.push(obj);
      } else {
        acc.push({ id: key, coord: obj.coord, arr: [obj] });
      }
      return acc;
    }, []);
  };

  const networkMarkers = createNetworkMarkers();
  markers = groupedArray(networkMarkers, "id");
  const splitMarkers = createNetworkMidPointMarkers();
  _editableNetwork = [...editableNetwork];

  const deletableMarker = () => {
    const mains = _editableNetwork.filter((m) => m.cableType === "Mains");
    const editModeFirstConnectedAssets = mains
      .filter((y) => y.editModeFirstConnectedAsset?.startsWith("joint"))
      .map((m) => m.editModeFirstConnectedAsset);
    const editModeSecondConnectedAssets = mains
      .filter((y) => y.editModeSecondConnectedAsset?.startsWith("joint"))
      .map((m) => m.editModeSecondConnectedAsset);
    return _editableNetwork.filter((e) =>
      [
        ...editModeFirstConnectedAssets,
        ...editModeSecondConnectedAssets,
      ].includes(e.id)
    );
  };

  const handleDialogAffirmAction = (props?: any[]) => {
    if (!props) return;
    dispatch(updateDialog({}));
    if (props[0] === "cable") {
      const snap: LatLng = props[1];
      const features: FeatureData[] = props[2];
      finaliseCable(snap, features);
    }
  };

  const handleDialogDismissAction = () => {
    dispatch(updateDialog({}));
    setInvalidIntersections([]);
    setInvalidTopographies([]);
  };

  return (
    <>
      <Hints currentLatLng={currentLatLng as LatLngObj} withinBounds={false} />
      {vertices.map((latlng, index) => (
        <Marker
          key={`${latlng.lat}${latlng.lng}`}
          position={latlng}
          icon={styleIcon(index, latlng)}
        ></Marker>
      ))}
      {vertices.length > 1 && (
        <>
          <Polyline positions={[...vertices]}></Polyline>
          <Marker
            ref={markerRef}
            position={vertices[vertices.length - 1]}
            icon={styleMarkerIcon()}
            eventHandlers={eventHandlers}
            zIndexOffset={10000}
          ></Marker>
        </>
      )}
      {editableNetwork.map((asset) => (
        <Asset key={asset.id} asset={asset} color={setColor(asset)}></Asset>
      ))}
      {splitMarkers.map((marker) => (
        <AddMarker
          key={marker.id}
          coord={marker.coord as LatLngObj}
          onAddMarker={(e) => handleAddMarker(e, marker)}
        />
      ))}
      {markers.map((marker) => (
        <EditMarker
          key={marker.id}
          coord={marker.coord}
          type={findAssetName(["joint", "cabinet"], marker.arr)}
          onMoveMarker={(e) => handleMoveMarker(e, marker.id)}
          onDeleteCableMarker={() => handleDeleteCableMarker(marker.id)}
        />
      ))}
      {deletableMarker().map((marker) => (
        <DeleteMarker
          key={marker.id}
          coord={marker.markerGeometry as LatLngObj}
          onDeleteMarker={() => handleDeleteMarker(marker.id)}
        />
      ))}
      <DialogModal
        affirmDialogAction={handleDialogAffirmAction}
        dismissDialogAction={handleDialogDismissAction}
      />
    </>
  );
};

export default Edit;
