import { LatLngExpression } from "leaflet";
import { Polyline } from "react-leaflet";
import { useAppSelector } from "../../app/hooks";
import { selectExistingNetwork } from "../existingNetwork/existingNetworkSlice";
import { layerOptionValues } from "../layerToggle/constants";

const GeoServerConductingSections = () => {
  const { selectedAsset } = useAppSelector(selectExistingNetwork);

  return (
    <>
      {selectedAsset?.assetType === layerOptionValues.conductingSections && (
        <Polyline
          key={selectedAsset.id}
          positions={selectedAsset.polyGeometry as LatLngExpression[]}
          color="blue"
          weight={4}
        ></Polyline>
      )}
    </>
  );
};

export default GeoServerConductingSections;
