import { Marker, Polygon } from "react-leaflet";
import { iconMarker } from "../../utils/iconMarker";
import "../../utils/iconMarker.css";
import { NetworkAsset } from "../../models/models";
import MeasuredPolyline from "../../measurePath/MeasuredPolyline";
import { UserRole } from "../../models/enums";
import { useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";

const assetTypes = [
  "new-domestic",
  "existing-domestic",
  "new-non-domestic",
  "existing-non-domestic",
  "new-mixed",
  "existing-mixed",
];

interface IAsset {
  key: string;
  asset: NetworkAsset;
  color: string;
}

const Asset = ({ asset, color }: IAsset) => {
  const styleIcon = () => {
    const iconColor = asset.isConnected ? "iconConnected" : "iconDark";
    const iconSize = asset.name === "joint" ? 0.75 : 1;
    return iconMarker(iconSize, iconColor, asset.name);
  };

  const app = useAppSelector(selectApp);
  const userRole = app.inputs.userRole;
  const showMeasurements = userRole !== UserRole.public;

  return (
    <>
      {asset.type === "cable" && (
        <MeasuredPolyline
          positions={asset.polyGeometry as L.LatLng[]}
          pathOptions={{
            weight: asset.cableType === "Mains" ? 2 : 1.5
          }}
          showMeasurements={showMeasurements} ></MeasuredPolyline>
      )}
      {asset.type === "marker" && (
        <Marker
          position={asset.markerGeometry as L.LatLng}
          icon={styleIcon()}
          zIndexOffset={9999}></Marker>
      )}

      {assetTypes.includes(asset.type) && (
        <>
          <Polygon
            positions={asset.polyGeometry as L.LatLng[]}
            pathOptions={{
              color: asset.markerGeometry ? "#343a40" : "#3388ff",
              fillOpacity: 0,
            }}></Polygon>
          {asset.markerGeometry && (
            <Marker
              position={asset.markerGeometry as L.LatLng}
              icon={styleIcon()}
              zIndexOffset={9999}></Marker>
          )}
        </>
      )}
      {asset.type === "site" && (
        <Polygon
          positions={asset.polyGeometry as L.LatLng[]}
          pathOptions={{
            color: "#343a40",
            fillOpacity: 0,
            dashArray: "5 10",
            weight: 2,
          }}
          ></Polygon>
      )}
    </>
  );
};

export default Asset;
