import { useMapEvents } from "react-leaflet";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectQuotationPlan } from "../quotationPlanSlice";
import { addAnnotation } from "../quotationPlanSlice";
import { v4 as uuid } from "uuid";

const DrawAnnotation = () => {
  const dispatch = useAppDispatch();
  const quotationPlan = useAppSelector(selectQuotationPlan);
  const { annotation } = quotationPlan;

  useMapEvents({
    click(e) {
      const position = [e.latlng.lat, e.latlng.lng];
      dispatch(
        addAnnotation({
          id: uuid(),
          text: annotation.text,
          lexical: "",
          fontSize: annotation.fontSize,
          width: annotation.width,
          color: annotation.color,
          textColor: annotation.textColor,
          borderColor: annotation.color,
          position: position,
        })
      );
    },
  });

  return null;
};

export default DrawAnnotation;
