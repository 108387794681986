import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { MapFeature } from "../../models/enums";

export const initialState = {
  selectedFeature: MapFeature.ragAssets,
};

export const selectMapFeaturesSlice = createSlice({
  name: "selectMapFeatures",
  initialState,
  reducers: {
    setSelectedFeature: (state, action: PayloadAction<MapFeature>) => {
      state.selectedFeature = action.payload;
    },
  },
});

export const { setSelectedFeature } = selectMapFeaturesSlice.actions;

export const selectSelectMapFeatures = (state: RootState) =>
  state.selectMapFeatures;

export default selectMapFeaturesSlice.reducer;
