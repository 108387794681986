import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GeoJSONObject } from "@turf/helpers";
import { Feature, Polygon } from "geojson";
import type { RootState } from "../../app/store";
import {
  NetworkAsset,
  LatLngObj,
  FeatureData,
  ExistingNetworkAsset,
  TransposedAsset,
  EditableJoint,
  IntersectingSegment,
} from "../../models/models";

export interface NetworkCreatorState {
  network: NetworkAsset[];
  editableNetwork: NetworkAsset[];
  activeTool?: string;
  activePremise?: string;
  ngedId?: string;
  vertices: LatLngObj[];
  firstConnectedAsset?: NetworkAsset;
  existingConnectedAssets: ExistingNetworkAsset[];
  currentSegments: FeatureData[];
  radii: Feature[];
  topographies: Feature<Polygon>[];
  groundTypes: Feature<Polygon>[];
  ragConductingSectionsAndPoles: ExistingNetworkAsset[];
  intersectingSegments: IntersectingSegment[];
  lvAceGrid?: GeoJSONObject;
  connectionMethod: "manual" | "automatic";
}

const initialState: NetworkCreatorState = {
  network: [],
  editableNetwork: [],
  activeTool: undefined,
  activePremise: undefined,
  ngedId: undefined,
  vertices: [],
  firstConnectedAsset: undefined,
  existingConnectedAssets: [],
  currentSegments: [],
  radii: [],
  topographies: [],
  groundTypes: [],
  ragConductingSectionsAndPoles: [],
  intersectingSegments: [],
  lvAceGrid: undefined,
  connectionMethod: "manual",
};

const filterPremises = (
  activeTool: string | undefined,
  state: NetworkCreatorState
) => {
  if (activeTool !== "meter") {
    state.network = state.network.filter(
      (asset) => asset.type !== "new-domestic" || asset.markerGeometry
    );
  }
};

export const networkCreatorSlice = createSlice({
  name: "networkCreator",
  initialState,
  reducers: {
    setActiveTool: (
      state,
      action: PayloadAction<{
        activeTool?: string;
        ngedId?: string;
      }>
    ) => {
      state.vertices = [];
      state.firstConnectedAsset = undefined;
      filterPremises(action.payload.activeTool, state);
      state.activeTool = action.payload.activeTool;
      state.ngedId = action.payload.ngedId;
    },
    setConnectionMethod: (
      state,
      action: PayloadAction<"manual" | "automatic">
    ) => {
      state.connectionMethod = action.payload;
      state.activeTool = undefined;
    },
    setActivePremise: (state, action: PayloadAction<string | undefined>) => {
      state.activePremise = action.payload;
    },
    addToNetwork: (
      state,
      action: PayloadAction<{
        assets: NetworkAsset[];
        _activeTool?: string;
        _ngedId?: string;
      }>
    ) => {
      const { assets } = action.payload;
      //when a marker is placed check if it already exists
      if (assets && assets.length === 1 && assets[0].type === "marker") {
        let stateAssets = [...state.network];
        let existingMarkerAsset = state.network.filter(
          (n) => n.ngedId === assets[0].ngedId && n.type === "marker"
        );
        if (existingMarkerAsset && existingMarkerAsset.length > 0) {
          return state;
        }
      }
      state.vertices = [];
      state.firstConnectedAsset = undefined;
      state.activeTool = action.payload._activeTool;
      state.ngedId = action.payload._ngedId;
      state.network = [...state.network, ...action.payload.assets];
    },
    removeFromNetwork: (state, action: PayloadAction<any[]>) => {
      action.payload.forEach((itemToRemove) => {
        const { property, value } = itemToRemove;
        if (property === "ngedId") {
          state.network = state.network.filter(
            (n) =>
              n.ngedId !== value && n.type !== "cable" && n.name !== "joint"
          );
          state.firstConnectedAsset = undefined;
        }
        if (property === "type") {
          const filteredNetwork = state.network.filter(
            (n) =>
              (n.type !== value && n.name !== "joint") || n.name === "cabinet"
          );

          const updatedNetwork = filteredNetwork.map((asset) => ({
            ...asset,
            firstConnectedAsset: undefined,
            secondConnectedAsset: undefined,
            isConnected: false,
          }));
          state.network = updatedNetwork;
        }
        state.activeTool = undefined;
        state.currentSegments = [];
        state.radii = [];
        state.topographies = [];
        state.intersectingSegments = [];
        state.lvAceGrid = undefined;
      });
    },
    replaceNetwork: (state, action: PayloadAction<NetworkAsset[]>) => {
      state.network = action.payload;
    },
    replaceNetworkCreatorState: (
      state,
      action: PayloadAction<NetworkCreatorState>
    ) => {
      state.network = action.payload.network;
      state.activeTool = action.payload.activeTool || undefined;
      state.ngedId = action.payload.ngedId || undefined;
      state.vertices = action.payload.vertices;
      state.intersectingSegments = action.payload.intersectingSegments;
      state.radii = action.payload.radii;
      state.ragConductingSectionsAndPoles =
        action.payload.ragConductingSectionsAndPoles;
      state.topographies = action.payload.topographies;
      state.lvAceGrid = action.payload.lvAceGrid || undefined;
    },
    replaceNetworkAssets: (
      state,
      action: PayloadAction<NetworkAsset[] | undefined>
    ) => {
      if (!action.payload) return;
      const assetIds = action.payload.map((p) => p.id);
      const filteredNetwork = state.network.filter(
        (f) => !assetIds.includes(f.id)
      );
      state.network = [...filteredNetwork, ...action.payload];
    },
    updateNetworkAsset: (
      state,
      action: PayloadAction<{
        id: string;
        geometry?: LatLngObj;
      }>
    ) => {
      const obj = state.network.find(
        (f) => f.id === action.payload.id
      ) as NetworkAsset;
      obj.markerGeometry = action.payload.geometry;
      state.activeTool = undefined;
      state.ngedId = undefined;
      state.activePremise = undefined;
    },
    addVertex: (state, action: PayloadAction<LatLngObj | undefined>) => {
      if (!action.payload) return;
      state.vertices.push(action.payload);
    },
    setFirstConnectedAsset: (
      state,
      action: PayloadAction<NetworkAsset | undefined>
    ) => {
      if (!action.payload) return;
      state.firstConnectedAsset = action.payload;
    },
    updateExistingConnectedAssets: (
      state,
      action: PayloadAction<ExistingNetworkAsset[] | undefined>
    ) => {
      if (!action.payload) return;
      state.existingConnectedAssets = [
        ...state.existingConnectedAssets,
        ...action.payload,
      ];
    },
    replaceMarker: (
      state,
      action: PayloadAction<{
        marker: NetworkAsset;
        activeTool?: string;
      }>
    ) => {
      const marker = state.network.find(
        (n) => n.id === action.payload.marker.id
      );
      if (marker) marker.name = action.payload.activeTool;
    },
    clearNetwork: (state) => {
      state.network = [];
      state.activeTool = undefined;
      state.ngedId = undefined;
      state.vertices = [];
      state.firstConnectedAsset = undefined;
      state.existingConnectedAssets = [];
      state.currentSegments = [];
      state.radii = [];
      state.topographies = [];
      state.ragConductingSectionsAndPoles = [];
      state.intersectingSegments = [];
      state.lvAceGrid = undefined;
    },
    addCurrentSegments: (
      state,
      action: PayloadAction<FeatureData[] | undefined>
    ) => {
      if (!action.payload) return;
      state.currentSegments = [...state.currentSegments, ...action.payload];
    },
    addRadii: (state, action: PayloadAction<Feature | undefined>) => {
      if (!action.payload) return;
      state.radii = [...state.radii, action.payload];
    },
    addTopographies: (
      state,
      action: PayloadAction<Feature<Polygon>[] | undefined>
    ) => {
      if (!action.payload) return;
      state.topographies = [...state.topographies, ...action.payload];
    },
    addGroundTypes: (
      state,
      action: PayloadAction<Feature<Polygon>[] | undefined>
    ) => {
      if (!action.payload) return;
      state.groundTypes = [...state.groundTypes, ...action.payload];
    },
    addLVAceGrid: (state, action: PayloadAction<GeoJSONObject>) => {
      state.lvAceGrid = action.payload;
    },
    addRAGConductingSectionsAndPoles: (
      state,
      action: PayloadAction<ExistingNetworkAsset[]>
    ) => {
      state.ragConductingSectionsAndPoles = [
        ...state.ragConductingSectionsAndPoles,
        ...action.payload,
      ];
    },
    addIntersectingSegments: (state, action: PayloadAction<NetworkAsset[]>) => {
      const intersectingSegment = {
        id: action.payload[0].id,
        data: [...state.currentSegments],
      };

      state.intersectingSegments = [
        ...state.intersectingSegments,
        intersectingSegment,
      ];

      state.currentSegments = [];
    },
    addEditableNetwork: (state) => {
      state.editableNetwork = state.network;
    },
    addToEditableNetwork: (
      state,
      action: PayloadAction<{
        assets: NetworkAsset[];
        _activeTool?: string;
        _ngedId?: string;
      }>
    ) => {
      const { assets } = action.payload;
      //when a marker is placed check if it already exists
      if (assets && assets.length === 1 && assets[0].type === "marker") {
        let existingMarkerAsset = state.editableNetwork.filter(
          (n) => n.ngedId === assets[0].ngedId && n.type === "marker"
        );
        if (existingMarkerAsset && existingMarkerAsset.length > 0) {
          return state;
        }
      }
      state.vertices = [];
      state.activeTool = action.payload._activeTool;
      state.ngedId = action.payload._ngedId;
      state.editableNetwork = [
        ...state.editableNetwork,
        ...action.payload.assets,
      ];
    },
    saveEditableNetwork: (state) => {
      state.network = state.editableNetwork;
    },
    replaceEditableNetworkAssets: (
      state,
      action: PayloadAction<NetworkAsset[] | undefined>
    ) => {
      if (!action.payload) return;
      const assetIds = action.payload.map((p) => p.id);
      const filteredNetwork = state.editableNetwork.filter(
        (f) => !assetIds.includes(f.id)
      );
      state.editableNetwork = [...filteredNetwork, ...action.payload];
    },
    editNetwork: (
      state,
      action: PayloadAction<{
        joints: EditableJoint[];
        cable: string;
        cableType: string | undefined;
      }>
    ) => {
      const { joints, cable, cableType } = action.payload;
      joints.forEach((item) =>
        item.assets.forEach((asset: any) => {
          let poly =
            asset.polyIndex !== undefined &&
            state.editableNetwork[asset.index].polyGeometry;
          if (poly) {
            poly[asset.polyIndex] = item.latlng;
            if (cable)
              if (state.editableNetwork[asset.index].id === cable) return;
            state.editableNetwork[asset.index].firstConnectedAsset = cable;
            state.editableNetwork[asset.index].pointOfConnectionType =
              cableType;
          } else {
            state.editableNetwork[asset.index].markerGeometry = item.latlng;
          }
        })
      );
    },
    addPolyVertex: (
      state,
      action: PayloadAction<{
        latlng: LatLngObj;
        asset: TransposedAsset;
      }>
    ) => {
      const { latlng, asset } = action.payload;
      let poly = state.editableNetwork[asset.index].polyGeometry;

      if (asset.polyIndex !== undefined && poly) {
        poly.splice(asset.polyIndex + 1, 0, latlng);
      }
    },
    deleteVertices: (
      state,
      action: PayloadAction<{
        assets: TransposedAsset[];
      }>
    ) => {
      action.payload.assets.forEach((asset: any) => {
        let poly =
          asset.polyIndex !== undefined &&
          state.editableNetwork[asset.index].polyGeometry;
        if (poly) {
          poly.splice(asset.polyIndex, 1);
        } else {
          state.editableNetwork.splice(asset.index, 1);
        }
      });
    },
    clearVertices: (state) => {
      state.vertices = [];
    },
    replaceEditableNetwork: (state, action: PayloadAction<NetworkAsset[]>) => {
      state.editableNetwork = action.payload;
    },
    completeAsset: (
      state,
      action: PayloadAction<{
        connectedAssets?: NetworkAsset[];
        assets: NetworkAsset[];
        _activeTool?: string;
        _ngedId?: string;
      }>
    ) => {
      const { connectedAssets, assets, _activeTool, _ngedId } = action.payload;
      const { replaceNetworkAssets, addToNetwork, addIntersectingSegments } =
        networkCreatorSlice.caseReducers;

      replaceNetworkAssets(state, {
        payload: connectedAssets,
        type: "",
      });
      addToNetwork(state, {
        payload: { assets, _activeTool, _ngedId },
        type: "",
      });
      addIntersectingSegments(state, {
        payload: assets,
        type: "",
      });
    },
    completeAsset2: (
      state,
      action: PayloadAction<{
        connectedAssets?: NetworkAsset[];
        assets: NetworkAsset[];
        _activeTool?: string;
        features?: FeatureData[];
        existingConnectedAssets?: ExistingNetworkAsset[];
      }>
    ) => {
      const {
        connectedAssets,
        assets,
        _activeTool,
        features,
        existingConnectedAssets,
      } = action.payload;
      const {
        replaceNetworkAssets,
        addToNetwork,
        updateExistingConnectedAssets,
        addCurrentSegments,
        addIntersectingSegments,
      } = networkCreatorSlice.caseReducers;

      replaceNetworkAssets(state, {
        payload: connectedAssets,
        type: "",
      });
      addToNetwork(state, {
        payload: { assets, _activeTool },
        type: "",
      });
      updateExistingConnectedAssets(state, {
        payload: existingConnectedAssets,
        type: "",
      });
      addCurrentSegments(state, {
        payload: features,
        type: "",
      });
      addIntersectingSegments(state, {
        payload: assets,
        type: "",
      });
    },
    completeSegment: (
      state,
      action: PayloadAction<{
        snap?: LatLngObj;
        features?: FeatureData[];
        firstConnectedAsset?: NetworkAsset;
      }>
    ) => {
      const { snap, features, firstConnectedAsset } = action.payload;
      const { addVertex, addCurrentSegments, setFirstConnectedAsset } =
        networkCreatorSlice.caseReducers;
      addVertex(state, {
        payload: snap,
        type: "",
      });
      addCurrentSegments(state, {
        payload: features,
        type: "",
      });
      setFirstConnectedAsset(state, {
        payload: firstConnectedAsset,
        type: "",
      });
    },

    updateNetworkAssetAndDebuggingAssets: (
      state,
      action: PayloadAction<{
        id: string;
        geometry?: LatLngObj;
        intersectingTopographies: Feature<Polygon>[];
        _circle?: Feature;
      }>
    ) => {
      const { id, geometry, intersectingTopographies, _circle } =
        action.payload;
      const {
        updateNetworkAsset,
        addRadii,
        addTopographies,
        setActivePremise,
      } = networkCreatorSlice.caseReducers;
      updateNetworkAsset(state, {
        payload: { id, geometry },
        type: "",
      });
      addRadii(state, {
        payload: _circle,
        type: "",
      });
      addTopographies(state, {
        payload: intersectingTopographies,
        type: "",
      });
      setActivePremise(state, {
        payload: undefined,
        type: "",
      });
    },
    completeEditableAsset: (
      state,
      action: PayloadAction<{
        connectedAssets?: NetworkAsset[];
        assets: NetworkAsset[];
        _activeTool?: string;
        features?: FeatureData[];
        existingConnectedAssets?: ExistingNetworkAsset[];
      }>
    ) => {
      const {
        connectedAssets,
        assets,
        _activeTool,
        features,
        existingConnectedAssets,
      } = action.payload;
      const {
        replaceEditableNetworkAssets,
        addToEditableNetwork,
        updateExistingConnectedAssets,
        addCurrentSegments,
        addIntersectingSegments,
      } = networkCreatorSlice.caseReducers;

      replaceEditableNetworkAssets(state, {
        payload: connectedAssets,
        type: "",
      });
      addToEditableNetwork(state, {
        payload: { assets, _activeTool },
        type: "",
      });
      updateExistingConnectedAssets(state, {
        payload: existingConnectedAssets,
        type: "",
      });
      addCurrentSegments(state, {
        payload: features,
        type: "",
      });
      addIntersectingSegments(state, {
        payload: assets,
        type: "",
      });
    },
    addToNetworkAndDebuggingAssets: (
      state,
      action: PayloadAction<{
        assets: NetworkAsset[];
        _activeTool?: string;
        _ngedId?: string;
        intersectingTopographies: Feature<Polygon>[];
        _circle?: Feature;
      }>
    ) => {
      const {
        assets,
        _activeTool,
        _ngedId,
        intersectingTopographies,
        _circle,
      } = action.payload;
      const { addToNetwork, addRadii, addTopographies, setActivePremise } =
        networkCreatorSlice.caseReducers;
      addToNetwork(state, {
        payload: { assets, _activeTool, _ngedId },
        type: "",
      });
      addRadii(state, {
        payload: _circle,
        type: "",
      });
      addTopographies(state, {
        payload: intersectingTopographies,
        type: "",
      });
      setActivePremise(state, {
        payload: undefined,
        type: "",
      });
    },
  },
});

export const {
  setActiveTool,
  setConnectionMethod,
  setActivePremise,
  addToNetwork,
  addToNetworkAndDebuggingAssets,
  removeFromNetwork,
  replaceNetwork,
  replaceNetworkCreatorState,
  updateNetworkAsset,
  updateNetworkAssetAndDebuggingAssets,
  replaceNetworkAssets,
  addVertex,
  setFirstConnectedAsset,
  replaceMarker,
  clearNetwork,
  addCurrentSegments,
  addRadii,
  addTopographies,
  addGroundTypes,
  addLVAceGrid,
  addRAGConductingSectionsAndPoles,
  addIntersectingSegments,
  editNetwork,
  addPolyVertex,
  deleteVertices,
  clearVertices,
  completeAsset,
  completeAsset2,
  completeEditableAsset,
  completeSegment,
  addEditableNetwork,
  saveEditableNetwork,
  replaceEditableNetwork,
  replaceEditableNetworkAssets,
  addToEditableNetwork,
} = networkCreatorSlice.actions;

export const selectNetworkCreator = (state: RootState) =>
  state.networkCreator.present;

export const hasPast = (state: RootState) =>
  state.networkCreator.past.length > 0;

export const getPast = (state: RootState) => state.networkCreator.past;

export const hasFuture = (state: RootState) =>
  state.networkCreator.future.length > 0;

export const selectEntireNetworkCreator = (state: RootState) =>
  state.networkCreator;

export default networkCreatorSlice.reducer;
