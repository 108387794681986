import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import {
  setActiveTool,
  setActivePremise,
  selectNetworkCreator,
  removeFromNetwork,
  clearNetwork,
  clearVertices,
} from "../../features/networkCreator/networkCreatorSlice";
import styles from "./StepToolBtn.module.css";
import { capitaliseString } from "../../utils/capitaliseString";
import { setIsLvAceComplete, setLVAceActive } from "../../app/appSlice";
import { clearQuotationPlan } from "../quotationPlan/quotationPlanSlice";

interface IStepToolBtn {
  item: any;
  cssClasses?: string;
  disabled?: boolean;
}

const StepToolBtn = ({ item, cssClasses = "", disabled }: IStepToolBtn) => {
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { activeTool, network, ngedId, activePremise, connectionMethod } =
    networkCreator;
  const dispatch = useAppDispatch();

  const onClick = (selectedTool: string) => {
    if (hasBeenAdded()) {
      if (item.id) {
        dispatch(
          removeFromNetwork([
            { property: "ngedId", value: item.id },
            { property: "type", value: "marker" },
          ])
        );
        dispatch(clearVertices());
      } else {
        if (item.name === "site") {
          dispatch(clearNetwork());
          dispatch(clearQuotationPlan());
        }
        if (item.name === "cable") {
          dispatch(
            removeFromNetwork([
              { property: "type", value: "cable" },
              { property: "type", value: "marker" },
            ])
          );
        }
      }
      dispatch(setIsLvAceComplete(false));
    } else {
      dispatch(setActivePremise(item.id));
      _setActiveTool(selectedTool);
    }
  };

  const _setActiveTool = (selectedTool: string) => {
    let _activeTool: string | undefined = selectedTool;
    let _ngedId: string | undefined = item.id;
    if (activeTool && selectedTool === activeTool) {
      _activeTool = undefined;
      _ngedId = undefined;
    }
    if (_activeTool === "cable") {
      if (connectionMethod === "automatic") {
        dispatch(setLVAceActive(true));
        return;
      }
    }
    dispatch(setActiveTool({ activeTool: _activeTool, ngedId: _ngedId }));
  };

  const hasBeenAdded = () => {
    return item.id
      ? network.some((i) => i.ngedId === item.id && i.markerGeometry)
      : item.name === "site"
      ? network.some((i) => i.type === "site")
      : item.name === "cable"
      ? network.filter((a) => a.type !== "site").some((a) => a.isConnected)
      : true;
  };

  const premiseIcon = () => {
    return item.id
      ? network.find((i) => i.ngedId === item.id && !i.markerGeometry)
        ? "meter"
        : item.type || item.name
      : item.type || item.name;
  };

  const _hasBeenAdded = hasBeenAdded();
  const isActive = item.id ? ngedId === item.id : activeTool === item.name;
  const ariaLabel = `draw${capitaliseString(item.type || item.name)}${item.id}`;

  const isDisabled = (item: any) => {
    return (
      disabled || (activePremise && item.id ? item.id !== activePremise : false)
    );
  };

  return (
    <div className="position-relative">
      <Button
        active={isActive}
        variant="dark"
        aria-label={ariaLabel}
        className={""}
        onClick={() => onClick(item.type || item.name)}
        disabled={isDisabled(item)}
      >
        <i className={`icon-${premiseIcon()}`}></i>
        <Badge
          pill
          variant={_hasBeenAdded ? "danger" : "success"}
          className={styles.iconBadge}
        >
          <i
            className={`icon-${_hasBeenAdded ? "cross-solid" : "plus-solid"}`}
          ></i>
        </Badge>
      </Button>
    </div>
  );
};

export default StepToolBtn;
