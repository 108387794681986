import { useAppSelector } from "../app/hooks";
import { selectApp } from "../app/appSlice";
import { selectNetworkCreator } from "../features/networkCreator/networkCreatorSlice";

const usePremise = () => {
  const app = useAppSelector(selectApp);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { connectionPoints } = app.inputs;
  const { network } = networkCreator;

  const getPremisesInNetwork = () => {
    return connectionPoints.filter((connectionPoint) =>
      network
        .map((item) => item.markerGeometry && item.ngedId)
        .includes(connectionPoint.id)
    );
  };

  const premisesInNetwork = getPremisesInNetwork();

  const premisesCount = premisesInNetwork.length;
  const premisesTotal = connectionPoints.length;
  const premisesCountOfTotal = `${premisesInNetwork.length}/${connectionPoints.length}`;
  const allPremisesInNetwork = connectionPoints.length
    ? premisesInNetwork.length === connectionPoints.length
    : false;
  const cableCount = network.filter((item) => item.type === "cable").length;

  return {
    premisesInNetwork,
    premisesCount,
    premisesTotal,
    premisesCountOfTotal,
    allPremisesInNetwork,
    cableCount,
  };
};

export default usePremise;
