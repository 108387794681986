import UndoRedoBtns from "../../features/steps/UndoRedoBtns";
import { useAppDispatch } from "../../app/hooks";
import { setMouseOverControl } from "../../app/appSlice";
import "./Toolbar.css";
import classNames from "classnames";

interface ISidebar {
  cssClasses?: string;
  canUndoRedo: boolean;
}

const Toolbar: React.FunctionComponent<ISidebar> = ({
  cssClasses,
  canUndoRedo,
  children,
}) => {
  const dispatch = useAppDispatch();

  const onMouseEnter = () => {
    dispatch(setMouseOverControl(true));
  };

  const onMouseLeave = () => {
    dispatch(setMouseOverControl(false));
  };

  return (
    <div
      className={classNames("edit-toolbar", cssClasses)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label="toolbar"
    >
      <div className="toolbar-btns">
        {canUndoRedo && <UndoRedoBtns color="dark" cssClasses="mr-2" />}
        <div
          className={classNames("ml-2", {
            "mt-2": !canUndoRedo,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
