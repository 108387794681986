import { LatLngBounds } from "leaflet";
import axios from "axios";
import { Position } from "geojson";

import { convertFeatures } from "../utils/networkUtils";
import {
  TransformFormat,
  convertCoords,
  transformCoord,
} from "../utils/convertCoords";
import { ConnectionPoint, ExistingNetworkAsset } from "../models/models";
import { FeatureType } from "../models/enums";
import { appConfig } from "../services/configService";
import { EPSGType } from "../features/layerToggle/constants";

const { geoserverBaseUrl, groundTypesUrl } = appConfig;

export const useGeoServer = function () {
  const getBounds = (bounds: LatLngBounds) => {
    return {
      southLat: bounds.getSouth(),
      westLng: bounds.getWest(),
      northLat: bounds.getNorth(),
      eastLng: bounds.getEast(),
      northEast: bounds.getNorthEast(),
      southWest: bounds.getSouthWest(),
    };
  };

  const convertLatLngBoundsToEastingNorthing = (bounds: LatLngBounds) => {
    const bb = getBounds(bounds);
    return [
      [bb.southWest.lng, bb.southWest.lat],
      [bb.northEast.lng, bb.northEast.lat],
    ]
      .map((bounds: Position) =>
        transformCoord(bounds, "EPSG:4326", "EPSG:27700").join(",")
      )
      .join(",");
  };

  const getUrl = (featureType: FeatureType) => {
    let typeName = "";
    let viewParam = "";
    switch (featureType) {
      case FeatureType.lineString:
        typeName = "line";
        viewParam = "laypath";
        break;
      case FeatureType.polygon:
        typeName = "polygon";
        viewParam = "polygon";
        break;
      case FeatureType.point:
        typeName = "point";
        viewParam = "point";
        break;
      case FeatureType.bbox:
        typeName = "bbox";
        break;
      default:
        typeName = "";
        viewParam = "";
    }

    const suffix =
      featureType === FeatureType.bbox ? "&bbox=" : `&viewparams=${viewParam}:`;

    const geoServerString = `${geoserverBaseUrl}${groundTypesUrl.replace(
      "##TYPENAME##",
      typeName
    )}${suffix}`;
    return geoServerString;
  };

  const getGroundTypes = (bounds: LatLngBounds) => {
    const bbox = convertLatLngBoundsToEastingNorthing(bounds);

    return fetch(getUrl(FeatureType.bbox) + bbox)
      .then((response) => response.json())
      .then((data) => {
        if (!data.features.length) return;
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const getGeoJsonWithBounds = (
    bounds: LatLngBounds,
    layer: string,
    EPSG: string
  ) => {
    let boundingBox: string;

    if (EPSG === EPSGType.eastingNorthing) {
      boundingBox = convertLatLngBoundsToEastingNorthing(bounds);
    } else {
      const bb = getBounds(bounds);
      boundingBox = `${bb.southLat},${bb.westLng},${bb.northLat},${bb.eastLng}`;
    }

    const url = `${layer}&bbox=${boundingBox}`;

    return axios
      .get(url)
      .then((result) => {
        return result.data;
      })
      .catch((error) => console.log(error));
  };

  const getGeoJsonByIds = async (featureIds: string, layer: string) => {
    const url = `${layer}&featureID=${featureIds}`;

    return axios
      .get(url)
      .then((result) => {
        return result.data;
      })
      .catch((error) => console.log(error));
  };

  const getLayer = async (
    geoServerAddresses: string[],
    EPSG: EPSGType,
    totalkVA: number,
    connectionPoints: ConnectionPoint[],
    featureIds?: string,
    bounds?: LatLngBounds
  ) => {
    const features: ExistingNetworkAsset[] = [];

    for (let address of geoServerAddresses) {
      const result = featureIds
        ? await getGeoJsonByIds(featureIds, address)
        : bounds
        ? await getGeoJsonWithBounds(bounds, address, EPSG)
        : null;
      features.push(...result.features);
    }

    const featureCollection = {
      features: features,
    };
    const reversedFeatureCollection = convertCoords(
      featureCollection,
      EPSG === EPSGType.eastingNorthing
        ? TransformFormat.transformlatlngreverse
        : TransformFormat.reverselatlng
    );
    const existingNetworkAsset = convertFeatures(
      reversedFeatureCollection.features,
      totalkVA,
      connectionPoints
    );

    return existingNetworkAsset;
  };

  return { getLayer, getGroundTypes };
};
