/**
 * This component wraps the children passed with the function specified in wapper.
 */

interface ConditionalWrapperInterface {
  condition: boolean;
  wrapper: Function;
  children: React.ReactElement;
}
const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperInterface): React.ReactElement =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
