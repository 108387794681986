import { LatLng, Map } from "leaflet";
import { ExistingNetworkAsset, NetworkAsset } from "../models/models";
import { filterByProperty } from "./filterByProperty";
import { refineSnapping, _calcClosestAsset } from "./snapping.js";

const snapList = (
  ragConnectivity: string[],
  network: NetworkAsset[],
  conductingSections: ExistingNetworkAsset[],
  overheadPoles: ExistingNetworkAsset[],
  poleMountedTransformers: ExistingNetworkAsset[]
) => {
  const filteredNetwork = network.filter((n) => ["cable"].includes(n.type));
  const undergroundConductingSections = conductingSections.filter(
    (c) => (c?.properties as any).Class === "underground-cable"
  );

  const filteredConductingSections = filterByProperty(
    ragConnectivity || [],
    undergroundConductingSections,
    "color"
  );
  const filteredOverheadPoles = filterByProperty(
    ragConnectivity || [],
    overheadPoles,
    "color"
  );
  const filteredPmts = filterByProperty(
    ragConnectivity || [],
    poleMountedTransformers,
    "color"
  );

  // Copy network
  let snappableAssets = [
    ...filteredNetwork,
    ...filteredConductingSections,
    ...filteredOverheadPoles,
    ...filteredPmts,
  ];

  return snappableAssets;
};

export const handleSnapping = (
  e: { latlng: LatLng },
  map: Map,
  ragConnectivity: string[],
  network: NetworkAsset[],
  conductingSections: ExistingNetworkAsset[],
  overheadPoles: ExistingNetworkAsset[],
  poleMountedTransformers: ExistingNetworkAsset[]
) => {
  const snappableAssets = snapList(
    ragConnectivity,
    network,
    conductingSections,
    overheadPoles,
    poleMountedTransformers
  );
  const closestAsset = _calcClosestAsset(map, e.latlng, snappableAssets);
  const refinedSnapping = refineSnapping(map, e, closestAsset, 999);
  return { closestAsset, refinedSnapping };
};
