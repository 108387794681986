import { ChangeEvent } from "react";
import { Dropdown, Form } from "react-bootstrap";

const WidthDropDown = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>, type?: string) => void;
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic">
        Width
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "20rem" }}>
        <Form.Control
          type="range"
          name="width"
          value={value}
          min="100"
          max="800"
          step="10"
          onChange={onChange}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default WidthDropDown;
