import L from "leaflet";

export function gmtIcon(colour: string | undefined, size = 24): L.DivIcon {
  let ragColour: string;
  colour ? (ragColour = colour) : (ragColour = "red");

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="${size}" height="${size}">
  <path fill=${ragColour} d="M15.5,11.6l-2.7-0.9l1.9-3.8c0.1-0.3,0.1-0.5-0.2-0.7C14.3,6,13.9,6,13.7,6.2l-5.6,5.6C8.1,11.9,8,12.2,8,12.4 c0,0.2,0.2,0.4,0.4,0.4l2.7,0.9l-1.8,3.8c-0.1,0.3-0.1,0.5,0.2,0.7c0.2,0.2,0.5,0.1,0.7-0.1l5.6-5.6c0.1-0.1,0.2-0.4,0.2-0.5 C15.8,11.8,15.7,11.7,15.5,11.6L15.5,11.6z M17.5,0h-11C2.9,0,0,2.9,0,6.5v11C0,21.1,2.9,24,6.5,24h11c3.6,0,6.5-2.9,6.5-6.5v-11 C24,2.9,21.1,0,17.5,0z M22,17.5c0,2.5-2,4.5-4.5,4.5h-11C4,22,2,20,2,17.5v-11C2,4,4,2,6.5,2h11C20,2,22,4,22,6.5V17.5z"/>
  </svg>`;
  return L.divIcon({
    html: svg,
    iconSize: [size, size],
  });
}
