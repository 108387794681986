import { ScaleControl } from "react-leaflet";
import Control from "../components/control/Control";
import Console from "../components/console/Console";
import Legend from "../components/legend/Legend";
import ZoomControl from "../components/zoomControl/ZoomControl";

import { useAppSelector } from "../app/hooks";
import { AppMode, selectApp } from "../app/appSlice";
import LayerToggle from "../features/layerToggle/LayerToggle";

const Controls = () => {
  const app = useAppSelector(selectApp);
  const { appMode, showLayerToggle } = app;
  return (
    <>
      <Control positionY="top" positionX="right" size="md">
        {!appMode && <Console />}
        {appMode === AppMode.quotationPlan && showLayerToggle && (
          <LayerToggle />
        )}
      </Control>
      <Control positionY="bottom" positionX="right" size="md" zIndex={990}>
        {appMode !== AppMode.quotationPlan && (
          <>
            <ScaleControl imperial={false} position="bottomright" />
            <div className="d-flex">
              <Legend />
              <ZoomControl color="dark" cssClasses="iosBoxShadow" />
            </div>
          </>
        )}
      </Control>
    </>
  );
};

export default Controls;
