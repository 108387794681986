import { useSelector } from "react-redux";
import { selectApp } from "../app/appSlice";
import { ConnectLite2Response } from "../models/connectLite2Response";
import { StudyResultRequest } from "../models/studyResultRequest";
import axios from "axios";
import { appConfig } from "../services/configService";

export const useStudyResult = () => {
  const { inputs } = useSelector(selectApp);
  const { webEnquiryRef } = inputs;

  const saveStudyResult = async (
    connectLite2Response: ConnectLite2Response
  ) => {
    if (!webEnquiryRef?.length) return;

    const { connectionOffer, connectLv, billOfMaterials, screenshots, design } =
      connectLite2Response;

    const requestBody: StudyResultRequest = {
      ...connectionOffer,
      reference: webEnquiryRef,
      costing: JSON.stringify(billOfMaterials),
      designScreenshot: screenshots.design,
      quotationPlanScreenshot: screenshots.quotationPlan,
      postConnectionNetworkResultSummary: JSON.stringify(
        design.postConnectionNetworkDetails
      ),
      connectLvAssessment: JSON.stringify(connectLv),
    };

    await axios.post(
      `${appConfig.apiBaseUrl}/v1/studyresult/save`,
      requestBody
    );

    return;
  };

  return {
    saveStudyResult,
  };
};
