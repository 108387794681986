import L, { Map } from "leaflet";

export const offsetPosition = (
  map: Map,
  geometry: L.LatLngExpression,
  x: number,
  y: number
) => {
  const point = map.latLngToContainerPoint(geometry);
  const newPoint = L.point([point.x + x, point.y + -y]);
  return map.containerPointToLatLng(newPoint);
};
