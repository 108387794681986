import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import {
  DrawableAnnotation,
  DrawableLine,
  DrawableShape,
} from "../../models/models";
import { Position } from "geojson";
import { ChangeEvent } from "react";
import { Map } from "leaflet";

const removeTransformPathLayers = (map: Map) => {
  map.eachLayer(function (layer) {
    //@ts-ignore
    if (layer.transform && layer.transform._handlersGroup) {
      const transformLayers = Object.values(
        //@ts-ignore
        layer.transform._handlersGroup._layers
      ).map((l) => l);
      transformLayers.forEach((lyr) => {
        //@ts-ignore
        map.removeLayer(lyr);
      });
    }
  });
};

// Define a type for the slice state
export interface QuotationPlanState {
  screenshot: string | undefined;
  gridReference: string | undefined;
  error: any | string | undefined;
  lines: DrawableLine[];
  annotations: DrawableAnnotation[];
  shapes: DrawableShape[];
  annotation: {
    text: string;
    fontSize: number;
    color: string;
    textColor: string;
    borderColor: string;
    width: number;
  };
  activeTool?: string;
  mapScale?: number;
  bannerSvg?: string;
  quotationPlanScreenshot?: string;
  selectedObject?: string;
}

// Define the initial state using that type
export const initialState: QuotationPlanState = {
  screenshot: undefined,
  gridReference: undefined,
  error: undefined,
  lines: [],
  shapes: [],
  annotations: [],
  selectedObject: undefined,
  annotation: {
    text: "Enter text...",
    fontSize: 12,
    color: "white",
    textColor: "black",
    borderColor: "black",
    width: 100,
  },
};

export const quotationPlanSlice = createSlice({
  name: "quotationPlan",
  initialState,
  reducers: {
    addLine: (state, action: PayloadAction<DrawableLine>) => {
      state.lines.push(action.payload);
    },
    updateLine: (
      state,
      action: PayloadAction<{
        id: string;
        psnIdx: number;
        newPosition: Position;
      }>
    ) => {
      const { id, psnIdx, newPosition } = action.payload;
      const lineIdx = state.lines.findIndex((line) => line.id === id);
      let line = state.lines[lineIdx];
      const psn = (line.positions[psnIdx] = newPosition);
      line = { ...line, ...psn };
    },
    updateLineData: (
      state,
      action: PayloadAction<{
        id: string;
        key: string;
        value: string | boolean | ChangeEvent<HTMLInputElement>;
      }>
    ) => {
      const { id, key, value } = action.payload;
      const index = state.lines.findIndex((line) => line.id === id);
      let line = state.lines[index];
      const updatedLine = { ...line, [key]: value };
      state.lines[index] = updatedLine;
    },
    removeLine: (state, action: PayloadAction<string>) => {
      state.lines = state.lines.filter((line) => line.id !== action.payload);
    },
    addAnnotation: (state, action: PayloadAction<DrawableAnnotation>) => {
      state.annotations.push(action.payload);
      state.selectedObject = action.payload.id;
    },
    updateAnnotationData: (
      state,
      action: PayloadAction<{
        id: string;
        key: string;
        value: string | boolean | number[] | ChangeEvent<HTMLInputElement>;
      }>
    ) => {
      const { id, key, value } = action.payload;
      const index = state.annotations.findIndex(
        (annotation) => annotation.id === id
      );
      let annotation = state.annotations[index];
      const updatedAnnotation = { ...annotation, [key]: value };
      state.annotations[index] = updatedAnnotation;
    },
    removeAnnotation: (state, action: PayloadAction<string>) => {
      state.annotations = state.annotations.filter(
        (annotation) => annotation.id !== action.payload
      );
      state.selectedObject = undefined;
    },
    addShape: (state, action: PayloadAction<DrawableShape>) => {
      state.shapes.push(action.payload);
    },
    updateShape: (
      state,
      action: PayloadAction<{
        id: string;
        newPositions: Position[];
      }>
    ) => {
      const { id, newPositions } = action.payload;
      const shapeIdx = state.shapes.findIndex((shape) => shape.id === id);
      let shape = state.shapes[shapeIdx];
      const psns = (shape.positions = newPositions);
      shape = { ...shape, ...psns };
    },
    updateShapeData: (
      state,
      action: PayloadAction<{
        id: string;
        key: string;
        value: string | boolean | ChangeEvent<HTMLInputElement>;
      }>
    ) => {
      const { id, key, value } = action.payload;
      const index = state.shapes.findIndex((shape) => shape.id === id);
      let shape = state.shapes[index];
      const updatedShape = { ...shape, [key]: value };
      state.shapes[index] = updatedShape;
    },
    removeShape: (state, action: PayloadAction<string>) => {
      state.shapes = state.shapes.filter(
        (shape) => shape.id !== action.payload
      );
      state.selectedObject = undefined;
    },
    selectObject: (
      state,
      action: PayloadAction<{
        obj: string | undefined;
        map: Map | undefined;
      }>
    ) => {
      const { obj, map } = action.payload;
      if (map) {
        removeTransformPathLayers(map);
      }
      state.selectedObject = obj;
    },
    setActiveTool: (state, action: PayloadAction<string | undefined>) => {
      state.selectedObject = undefined;
      state.activeTool = action.payload;
    },
    setMapScale: (state, action: PayloadAction<number>) => {
      state.mapScale = action.payload;
    },
    setGridReference: (state, action: PayloadAction<string>) => {
      state.gridReference = action.payload;
    },
    setBannerSvg: (state, action: PayloadAction<string>) => {
      state.bannerSvg = action.payload;
    },
    setQuotationPlanScreenshot: (state, action: PayloadAction<string>) => {
      state.quotationPlanScreenshot = action.payload;
    },
    clearQuotationPlan: (state) => {
      state.lines = [];
      state.annotations = [];
    },
  },
});

export const {
  addLine,
  updateLine,
  updateLineData,
  removeLine,
  addAnnotation,
  updateAnnotationData,
  addShape,
  updateShapeData,
  selectObject,
  removeAnnotation,
  updateShape,
  removeShape,
  setActiveTool,
  setMapScale,
  setGridReference,
  setBannerSvg,
  setQuotationPlanScreenshot,
  clearQuotationPlan,
} = quotationPlanSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectQuotationPlan = (state: RootState) => state.quotationPlan;

export default quotationPlanSlice.reducer;
