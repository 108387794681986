import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  replaceNetworkCreatorState,
  selectEntireNetworkCreator,
} from "../features/networkCreator/networkCreatorSlice";
import {
  AppMode,
  Inputs,
  selectApp,
  setAppMode,
  setInputs,
  setIsReadOnly,
} from "../app/appSlice";
import {
  LoadStudyAdapter,
  SaveStudyAdapter,
  StudySaveFile,
} from "../services/persistService";
import { UserRole } from "../models/enums";

export const usePersistor = () => {
  const dispatch = useAppDispatch();
  const { inputs } = useAppSelector(selectApp);
  const networkCreatorState = useAppSelector(selectEntireNetworkCreator);

  const saveNetworkState = async (
    saver: SaveStudyAdapter,
    isCompletedStudy: boolean
  ) => {
    const inputsToSave: Inputs = {
      ...inputs,
      isCompletedStudy,
    };
    saver(inputsToSave, networkCreatorState);
  };

  const restoreStudy = async (
    loader: LoadStudyAdapter,
    inputs: Inputs
  ): Promise<StudySaveFile | null> => {
    const restored = await loader(inputs);

    if (restored) {
      dispatch(setInputs(restored.inputs));

      const { isCompletedStudy, userRole } = restored.inputs;
      const isReadOnly =
        isCompletedStudy &&
        (userRole === UserRole.public || userRole === UserRole.icp);
      dispatch(setIsReadOnly(isReadOnly));

      restored.networkCreatorState.past.forEach((p) => {
        dispatch(replaceNetworkCreatorState(p));
      });
      dispatch(setAppMode(undefined));
      dispatch(
        replaceNetworkCreatorState(restored.networkCreatorState.present)
      );
    }

    return restored;
  };

  return {
    saveNetworkState,
    restoreStudy,
  };
};
