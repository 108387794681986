import { CSSTransition } from "react-transition-group";
import Toggle from "./Toggle";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";
import { selectSteps } from "./stepsSlice";
import {
  removeFromNetwork,
  selectNetworkCreator,
  setConnectionMethod,
} from "../networkCreator/networkCreatorSlice";

import styles from "./StepItem.module.css";
import { UserRole } from "../../models/enums";

export interface IStepItem {
  children: JSX.Element;
  item: any;
  toggle: boolean;
}

const StepItem = ({ children, item, toggle }: IStepItem) => {
  const app = useAppSelector(selectApp);
  const { userRole, isInPublic } = app.inputs;

  const steps = useAppSelector(selectSteps);
  const { mode } = steps;

  const networkCreator = useAppSelector(selectNetworkCreator);
  const { connectionMethod } = networkCreator;

  const dispatch = useAppDispatch();

  const canUseInternalTools = userRole !== UserRole.public;

  const isConnectionStep = () => {
    if (isInPublic && item.id === 2) {
      return true;
    }

    if (!isInPublic && item.id === 3) {
      return true;
    }

    return false;
  };

  const handleConnectionMethod = () => {
    const _connectionMethod =
      connectionMethod === "manual" ? "automatic" : "manual";

    dispatch(
      removeFromNetwork([
        { property: "type", value: "cable" },
        { property: "type", value: "marker" },
      ])
    );
    dispatch(setConnectionMethod(_connectionMethod));
  };

  const transition = (
    <CSSTransition
      in={mode === "expanded"}
      timeout={150}
      classNames="step-text"
      unmountOnExit
    >
      <span
        className={isConnectionStep() && canUseInternalTools ? "step-link" : ""}
        aria-label={`${item.type || item.label}${item.id}`}
        onClick={() =>
          isConnectionStep() && canUseInternalTools
            ? handleConnectionMethod()
            : null
        }
      >
        {isConnectionStep() && (
          <>
            {canUseInternalTools && connectionMethod === "manual"
              ? "draw"
              : item.label === "Submit"
              ? ""
              : "route"}{" "}
          </>
        )}
        {(item.type || item.label).replace("-", " ")}
      </span>
    </CSSTransition>
  );

  return (
    <div className={`d-flex align-items-center justify-content-between p-2`}>
      {toggle ? (
        <Toggle eventKey={item.id} mode={mode}>
          {transition}
        </Toggle>
      ) : (
        <div className={styles.stepItem}>
          <span className={styles.circle}>{item.id}</span>
          {transition}
        </div>
      )}
      {children}
    </div>
  );
};

export default StepItem;
