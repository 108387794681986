import { LatLngObj } from "../../models/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

interface Coords {
  type: string;
  coordinates: LatLngObj | LatLngObj[];
}
export interface OSFeature {
  id: string;
  type: string;
  geometry: Coords;
  properties: {};
}

interface OSFeaturesState {
  osFeature: OSFeature | undefined;
  osFeatures: OSFeature[];
}

const initialState: OSFeaturesState = {
  osFeature: undefined,
  osFeatures: [],
};

export const osFeaturesSlice = createSlice({
  name: "osFeatures",
  initialState,
  reducers: {
    resetFeatures: (state) => {
      state.osFeature = undefined;
      state.osFeatures = [];
    },
    setOSFeature: (state, action: PayloadAction<OSFeature | undefined>) => {
      state.osFeature = action.payload;
    },
    setOSFeatures: (state, action: PayloadAction<OSFeature[]>) => {
      state.osFeatures = action.payload;
    },
    clearOSFeatures: (state) => {
      state.osFeature = undefined;
      state.osFeatures = [];
    },
  },
});

export const { resetFeatures, setOSFeature, setOSFeatures, clearOSFeatures } =
  osFeaturesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectOSFeatures = (state: RootState) => state.osFeatures;

export default osFeaturesSlice.reducer;
