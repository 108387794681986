import { AppMode, selectApp } from "../../app/appSlice";
import { useAppSelector } from "../../app/hooks";

interface IMapWrapper {
  children: JSX.Element;
}

const MapWrapper = ({ children }: IMapWrapper) => {
  const app = useAppSelector(selectApp);
  const { appMode, takingQuotationScreenshot } = app;

  const isQuotationPlan =
    appMode === AppMode.quotationPlan || takingQuotationScreenshot;

  return (
    <div className={isQuotationPlan ? "wrapper" : "w-100 h-100"}>
      <div className={isQuotationPlan ? "paper-a3" : "w-100 h-100"}>
        {children}
      </div>
    </div>
  );
};

export default MapWrapper;
