import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { Schematic } from "../../models/models";

// Define a type for the slice state
export interface SchematicsState {
  originalSchematics: Schematic[];
  schematics: Schematic[];
  selectedSchematic?: Schematic;
  incrementSchematicUpdate: number;
  status: "idle" | "loading" | "failed";
}

// Define the initial state using that type
export const initialState: SchematicsState = {
  schematics: [],
  originalSchematics: [],
  incrementSchematicUpdate: 0,
  status: "idle",
};

export const schematicsSlice = createSlice({
  name: "schematic",
  initialState,
  reducers: {
    addSchematic: (state, action: PayloadAction<Schematic>) => {
      state.schematics = [...state.schematics, action.payload];
      state.originalSchematics = [...state.originalSchematics, action.payload];
    },
    selectSchematic: (state, action: PayloadAction<Schematic | undefined>) => {
      state.selectedSchematic = action.payload;
    },
    updateSchematicOpacity: (
      state,
      action: PayloadAction<{ id: string; newOpacity: number }>
    ) => {
      const { id, newOpacity } = action.payload;
      const index = state.schematics.findIndex((s) => s.id === id);
      state.schematics[index].opacity = newOpacity;
    },
    updateSchematicPositions: (
      state,
      action: PayloadAction<{ id: string; newPositions: number[][] }>
    ) => {
      const { id, newPositions } = action.payload;
      const index = state.schematics.findIndex((s) => s.id === id);
      state.schematics[index].positions = newPositions;
    },
    updateSchematicUniformScaling: (
      state,
      action: PayloadAction<{ id: string; newUniformScaling: boolean }>
    ) => {
      const { id, newUniformScaling } = action.payload;
      const index = state.schematics.findIndex((s) => s.id === id);
      state.schematics[index].uniformScaling = newUniformScaling;
      state.selectedSchematic = state.schematics[index];
    },
    updateSchematicVisibility: (
      state,
      action: PayloadAction<{ id: string; visible: boolean }>
    ) => {
      const { id, visible } = action.payload;
      const index = state.schematics.findIndex((s) => s.id === id);
      state.schematics[index].visible = visible;
    },
    resetSchematic: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const schematicIndex = state.schematics.findIndex((s) => s.id === id);
      const originalIndex = state.originalSchematics.findIndex(
        (s) => s.id === id
      );
      state.schematics[schematicIndex] =
        state.originalSchematics[originalIndex];
      state.selectedSchematic = undefined;
    },
    deleteSchematic: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.schematics = state.schematics.filter((s) => s.id !== id);
      state.selectedSchematic = undefined;
    },
  },
});

export const {
  addSchematic,
  selectSchematic,
  updateSchematicOpacity,
  updateSchematicPositions,
  updateSchematicUniformScaling,
  updateSchematicVisibility,
  resetSchematic,
  deleteSchematic,
} = schematicsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSchematics = (state: RootState) => state.schematics;

export default schematicsSlice.reducer;
