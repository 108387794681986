import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

// Define a type for the slice state
export interface DebuggingState {
  debug: string[];
}

// Define the initial state using that type
export const initialState: DebuggingState = {
  debug: [],
};

export const debuggingSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateDebug: (state, action: PayloadAction<string[]>) => {
      state.debug = action.payload;
    },
  },
});

export const { updateDebug } = debuggingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectDebugging = (state: RootState) => state.debugging;

export default debuggingSlice.reducer;
