import styles from "./StepItem.module.css";

interface IStepCounter {
  tracker: string;
}

const StepCounter = ({ tracker }: IStepCounter) => {
  return <span className={styles.frame}>{tracker}</span>;
};

export default StepCounter;
