import { useState, useCallback } from "react";
import L, { Map } from "leaflet";
import { timeout } from "../utils/timeout";

export const useScreenshot = () => {
  const [screenshot, setScreenshot] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(false);

  const takeScreenshot = useCallback(async (map: Map, type?: "jpg" | "png") => {
    const simpleMapScreenshoter = L.simpleMapScreenshoter({
      hidden: true, // hide screen btn on map
      hideElementsWithSelectors: [".screen-hide"],
    }).addTo(map);

    setLoading(true);
    let tempImage: Blob | Error | L.ErrorEvent | undefined = undefined;

    try {
      const format: "image" | "blob" | "canvas" = "image";
      const overridedPluginOptions = {
        mimeType: `${type === "jpg"} ? "image/jpeg" : "image/png"`,
      };

      await timeout(2000);
      await simpleMapScreenshoter
        .takeScreen(format, overridedPluginOptions)
        .then((image) => {
          tempImage = image;
        })
        .catch((e) => {
          console.log(e.toString());
        });

      if (tempImage) setScreenshot(tempImage);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    return tempImage;
  }, []);

  const clear = useCallback(() => setScreenshot(undefined), []);

  return { screenshot, takeScreenshot, isLoading, clear };
};

export default useScreenshot;
