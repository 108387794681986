import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { ExistingNetworkAsset } from "../../models/models";
import {
  AcLineSegmentProperties,
  PoleProperties,
  TransformerProperties,
} from "../../models/featureProperties";
import { layerOptionValues } from "../layerToggle/constants";

export interface ExistingNetworkState {
  selectedAsset?: ExistingNetworkAsset & { assetType: layerOptionValues };
  conductingSections: ExistingNetworkAsset<AcLineSegmentProperties>[];
  overheadPoles: ExistingNetworkAsset<PoleProperties>[];
  powerTransformers: ExistingNetworkAsset<TransformerProperties>[];
  ehvs: ExistingNetworkAsset[];
  hvs: ExistingNetworkAsset[];
  thirdparties: ExistingNetworkAsset[];
  lvmains: ExistingNetworkAsset[];
  wayleaves: ExistingNetworkAsset[];
  serviceCables: ExistingNetworkAsset[];
  acceptedToConnects: ExistingNetworkAsset[];
}

const initialState: ExistingNetworkState = {
  selectedAsset: undefined,
  conductingSections: [],
  overheadPoles: [],
  powerTransformers: [],
  ehvs: [],
  hvs: [],
  thirdparties: [],
  lvmains: [],
  wayleaves: [],
  serviceCables: [],
  acceptedToConnects: [],
};

export const existingNetworkSlice = createSlice({
  name: "existingNetwork",
  initialState,
  reducers: {
    setSelectedAsset: (state, action: PayloadAction<any>) => {
      state.selectedAsset = action.payload;
    },
    updateConductingSections: (state, action: PayloadAction<any[]>) => {
      state.conductingSections = [...action.payload];
    },
    updateOverheadPoles: (state, action: PayloadAction<any[]>) => {
      state.overheadPoles = [...action.payload];
    },
    updatePowerTransformers: (
      state,
      action: PayloadAction<ExistingNetworkAsset<TransformerProperties>[]>
    ) => {
      state.powerTransformers = action.payload;
    },
    updateEhvs: (state, action: PayloadAction<any[]>) => {
      state.ehvs = [...action.payload];
    },
    updateHvs: (state, action: PayloadAction<any[]>) => {
      state.hvs = [...action.payload];
    },
    updateThirdParties: (state, action: PayloadAction<any[]>) => {
      state.thirdparties = [...action.payload];
    },
    updateLVMains: (state, action: PayloadAction<any[]>) => {
      state.lvmains = [...action.payload];
    },
    updateWayleaves: (state, action: PayloadAction<any[]>) => {
      state.wayleaves = [...action.payload];
    },
    updateServiceCables: (state, action: PayloadAction<any[]>) => {
      state.serviceCables = [...action.payload];
    },
    updateAcceptedToConnects: (state, action: PayloadAction<any[]>) => {
      state.acceptedToConnects = [...action.payload];
    },
  },
});

export const {
  setSelectedAsset,
  updateConductingSections,
  updateOverheadPoles,
  updatePowerTransformers,
  updateEhvs,
  updateHvs,
  updateThirdParties,
  updateLVMains,
  updateWayleaves,
  updateServiceCables,
  updateAcceptedToConnects,
} = existingNetworkSlice.actions;

export const selectExistingNetwork = (state: RootState) =>
  state.existingNetwork;

export default existingNetworkSlice.reducer;
