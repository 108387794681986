import { validateInputs } from "../components/inputValidator/validators";
import { Inputs } from "../app/appSlice";

/**
 * Checks if the message event is a supported input type
 * @param event Message event
 * @returns True if the data type is not supported by C2C
 */
export const isNotInputFormat = (event: MessageEvent<any>) => {
  return !(
    event.data.isNewStudy ||
    event.data.existingStudy ||
    event.data.webEnquiryRef
  );
};

/**
 * Checks if new study inputs are valid
 * @param event Message event with Inputs data type
 * @returns True if a new study input does not have valid types
 */
export const isNotValidInputs = (event: MessageEvent<Inputs>) => {
  return event.data.isNewStudy && !validateInputs(event.data);
};
