import { useState } from "react";
import L, { LatLng, LeafletMouseEvent } from "leaflet";
import {
  Marker,
  Polyline,
  useMapEvents,
  useMap,
  Polygon as RLPolygon,
} from "react-leaflet";
import { deviceType } from "detect-it";
import pointInPolygon from "point-in-polygon";

import { useAppSelector, useAppDispatch } from "../../app/hooks";

import {
  addVertex,
  updateNetworkAssetAndDebuggingAssets,
  selectNetworkCreator,
  completeAsset,
  completeSegment,
  completeAsset2,
  addToNetworkAndDebuggingAssets,
  addRAGConductingSectionsAndPoles,
  addGroundTypes,
} from "./networkCreatorSlice";
import { selectExistingNetwork } from "../existingNetwork/existingNetworkSlice";
import { filterByProperty } from "../../utils/filterByProperty";
import { convertCoords, TransformFormat } from "../../utils/convertCoords";
import {
  NetworkAsset,
  LatLngObj,
  FeatureData,
  ExistingNetworkAsset,
} from "../../models/models";
import { FeatureType } from "../../models/enums";
import {
  refineSnapping,
  _calcClosestAsset,
  _calcLayerDistances,
} from "../../utils/snapping.js";
import {
  asset,
  matchNetworkAsset,
  latLngArray,
  getFeatureData,
  isMarkerClicked,
} from "../../utils/networkUtils";
import { warningMessage } from "../../utils/warningMessage";
import { iconMarker } from "../../utils/iconMarker";
import "../../utils/iconMarker.css";
import Hints from "./Hints";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Polygon,
  Position,
} from "geojson";
import { checkArrayList } from "../../utils/checkArrayList";
import { selectApp, updateDialog } from "../../app/appSlice";
import DialogModal from "../../components/dialogModal/DialogModal";
import circle from "@turf/circle";
import buffer from "@turf/buffer";
import { multiLineString, polygon, Units } from "@turf/helpers";
import { updateConnectedAssets } from "../../utils/networkConnector";
import {
  getGroundType,
  isGroundTypeValid,
} from "../../utils/geoSpatialHelpers";
import { url } from "../../utils/geoServerUrl";
import kinks from "@turf/kinks";
import { latLngToGridRef } from "../../utils/convertToSetView";
import { useRagConnectivity } from "../../hooks/useRagConnectivity";
import { useGeoServer } from "../../hooks/useGeoServer";
import { useFeatureCollection } from "../../hooks/useFeatureCollection";
import { EPSGType, layerOptions } from "../layerToggle/constants";
import { PowerTransformerMounting } from "../../models/enums";

const snappableVertex = (
  activeTool: string | undefined,
  vertices: LatLngObj[]
) => {
  return activeTool === "cable" ? vertices[vertices.length - 1] : vertices[0];
};

const cableSelfIntersects = (vertices: LatLngObj[], snap: LatLngObj) => {
  const snapPoint: Position = [snap.lng, snap.lat];
  const cablePoints: Position[] = vertices.map((m) => [m.lng, m.lat]);
  const sitePointsArray: Position[][] = [[...cablePoints, snapPoint]];
  const cable = multiLineString(sitePointsArray);
  const intersections = kinks(cable);
  return intersections.features.length > 0;
};

const DrawingTool = () => {
  const map = useMap();
  const app = useAppSelector(selectApp);
  const { totalkVA, userRole, isInPublic, connectionPoints } = app.inputs;
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const { conductingSections, overheadPoles, powerTransformers } =
    existingNetwork;
  const poleMountedTransformers = powerTransformers.filter(
    (transformer) =>
      transformer.properties?.Mounting === PowerTransformerMounting.pmt
  );
  const networkCreator = useAppSelector(selectNetworkCreator);
  const {
    network,
    vertices,
    firstConnectedAsset,
    activeTool,
    ngedId,
    groundTypes,
    existingConnectedAssets,
  } = networkCreator;
  const dispatch = useAppDispatch();

  const { getLayer, getGroundTypes } = useGeoServer();
  const { getFeatureCollection } = useFeatureCollection();

  const [currentLatLng, setCurrentLatLng] = useState<LatLngObj | undefined>(
    undefined
  );
  const [isSnapped, setIsSnapped] = useState(false);
  const [withinBounds, setWithinBounds] = useState<boolean>(false);

  const [currentSnappedAsset, setCurrentSnappedAsset] = useState<
    ExistingNetworkAsset | undefined
  >(undefined);

  const [invalidIntersections, setInvalidIntersections] = useState<
    Position[][]
  >([]);

  const [invalidTopographies, setInvalidTopographies] = useState<
    Position[][][]
  >([]);

  const ragConnectivity = useRagConnectivity(userRole);

  const siteSnapList = () => {
    // Get either first or last vertex depending if the activeTool is a cable or site
    const vertex = snappableVertex(activeTool, vertices);

    // Create network asset from vertex
    const hintMarker: NetworkAsset = asset(undefined, "marker", vertex);

    // Copy network
    // Temporarily limit snapping
    // let snappableAssets = [...network];
    let snappableAssets: NetworkAsset[] = [];

    if (vertices.length) {
      // Temporarily limit snapping
      // snappableAssets = [hintMarker, ...network];
      snappableAssets = [hintMarker];
    }

    return snappableAssets;
  };

  const cableSnapList = () => {
    // Get either first or last vertex depending if the activeTool is a cable or site
    const vertex = snappableVertex(activeTool, vertices);

    // Create network asset from vertex
    const hintMarker: NetworkAsset = asset(undefined, "marker", vertex);

    const firstConnectedAssetIsCableOrPole =
      firstConnectedAsset &&
      ["cable", "existingcable", "existingpole"].includes(
        firstConnectedAsset.type
      );

    const firstConnectedAssetIsMeter =
      typeof firstConnectedAsset !== "undefined" &&
      firstConnectedAsset.type === "marker";

    // Filter network by markers
    let filteredNetwork = network.filter((n) => n.type !== "site");

    if (firstConnectedAssetIsCableOrPole) {
      filteredNetwork = filteredNetwork.filter((n) => n.type !== "cable");
    } else if (firstConnectedAssetIsMeter) {
      filteredNetwork = filteredNetwork.filter(
        (n) =>
          ![
            "new-domestic",
            "new-non-domestic",
            "existing-domestic",
            "existing-non-domestic",
            "new-mixed",
            "existing-mixed",
            "marker",
          ].includes(n.type)
      );
    }

    filteredNetwork = filteredNetwork.map((asset) => ({
      id: asset.id,
      type: [
        "new-domestic",
        "new-non-domestic",
        "existing-domestic",
        "existing-non-domestic",
        "new-mixed",
        "existing-mixed",
      ].includes(asset.type)
        ? "marker"
        : asset.type,
      markerGeometry: asset.markerGeometry,
      name: asset.name,
      ngedId: ngedId,
      topographyId: asset.topographyId,
      polyGeometry: asset.polyGeometry,
      firstConnectedAsset: asset.firstConnectedAsset,
      secondConnectedAsset: asset.secondConnectedAsset,
      isConnected: asset.isConnected,
      pointOfConnectionType: asset.pointOfConnectionType,
      isConsumer: asset.isConsumer,
      cableRag: asset.cableRag,
    }));

    const undergroundConductingSections = conductingSections.filter(
      (c) => (c?.properties as any).Class === "underground-cable"
    );

    let filteredConductingSections = [];
    let filteredOverheadPoles = [];
    let filteredPmts = [];

    if (!firstConnectedAssetIsCableOrPole) {
      filteredConductingSections = filterByProperty(
        ragConnectivity || [],
        undergroundConductingSections,
        "color"
      );
      filteredOverheadPoles = filterByProperty(
        ragConnectivity || [],
        overheadPoles,
        "color"
      );
      filteredPmts = filterByProperty(
        ragConnectivity || [],
        poleMountedTransformers,
        "color"
      );
    }

    // Copy network
    let snappableAssets = [
      ...filteredNetwork,
      ...filteredConductingSections,
      ...filteredOverheadPoles,
      ...filteredPmts,
    ];

    if (vertices.length) {
      snappableAssets = [
        ...filteredNetwork,
        ...filteredConductingSections,
        ...filteredOverheadPoles,
        ...filteredPmts,
      ];
    }

    return snappableAssets;
  };

  const premiseSnapList = () => {
    // Get either first or last vertex depending if the activeTool is a cable or site
    const vertex = snappableVertex(activeTool, vertices);

    // Create network asset from vertex
    const hintMarker: NetworkAsset = asset(undefined, "marker", vertex);

    // Filter network by markers
    const filteredNetwork = network.filter((n) =>
      ["new-domestic", "new-non-domestic", "new-mixed"].includes(n.type)
    );

    // Copy network
    let snappableAssets = [...filteredNetwork];

    if (vertices.length) {
      snappableAssets = [hintMarker, ...filteredNetwork];
    }

    return snappableAssets;
  };

  const validCableConnections = [
    ["cable", "cable"],
    ["cable", "new-domestic"],
    ["cable", "new-non-domestic"],
    ["cable", "new-mixed"],
    ["cable", "existing-domestic"],
    ["cable", "existing-non-domestic"],
    ["cable", "existing-mixed"],
    ["cable", "existingcable"],
    ["cable", "marker"],
    ["cable", "cabinet"],
    ["new-domestic", "existingcable"],
    ["new-non-domestic", "existingcable"],
    ["new-mixed", "existingcable"],
    ["existing-domestic", "existingcable"],
    ["existing-non-domestic", "existingcable"],
    ["existing-mixed", "existingcable"],
    ["existingcable", "marker"],
    ["existingcable", "cabinet"],
  ];

  const validateCable = (
    firstConnectedAsset: NetworkAsset | undefined,
    secondConnectedAsset: NetworkAsset | undefined
  ) => {
    if (!firstConnectedAsset || !secondConnectedAsset) return false;
    const assetTypes = [
      firstConnectedAsset.type,
      secondConnectedAsset.type,
    ].sort();
    return checkArrayList(assetTypes, validCableConnections);
  };

  const isNetworkConnected = (connectedAssets?: NetworkAsset[]) => {
    if (!connectedAssets) return false;
    const assetIds = connectedAssets.map((p) => p.id);
    const filteredNetwork = [...network].filter(
      (f) => !assetIds.includes(f.id)
    );
    const updatedNetwork = [...filteredNetwork, ...connectedAssets];
    return updatedNetwork
      .filter((a) => a.type !== "site")
      .find((a) => !a.isConnected);
  };

  const completePremisePerimeter = (
    _activeTool: string | undefined = undefined,
    _ngedId: string | undefined = undefined
  ) => {
    const allVertices = [...vertices];

    // Init empty array
    let assets: NetworkAsset[] = [];
    const connectedAssets = undefined;

    assets.push(
      asset(
        undefined,
        activeTool as string,
        undefined,
        allVertices,
        undefined,
        ngedId,
        undefined,
        firstConnectedAsset?.id,
        undefined,
        false,
        undefined,
        true
      )
    );

    dispatch(
      completeAsset({
        connectedAssets,
        assets,
        _activeTool,
        _ngedId,
      })
    );
  };

  const completeSite = (
    _activeTool: string | undefined = undefined,
    _ngedId: string | undefined = undefined
  ) => {
    const allVertices = [...vertices];

    // Init empty array
    let assets: NetworkAsset[] = [];
    const connectedAssets = undefined;

    assets.push(
      asset(
        undefined,
        activeTool as string,
        undefined,
        allVertices,
        undefined,
        ngedId,
        undefined,
        firstConnectedAsset?.id
      )
    );

    dispatch(
      completeAsset({
        connectedAssets,
        assets,
        _activeTool,
        _ngedId,
      })
    );

    const latLngs: LatLng[] = [];
    allVertices.forEach((n) => {
      latLngs.push(L.latLng(n.lat, n.lng));
    });

    map && map.fitBounds(L.latLngBounds(latLngs), { animate: false });
  };

  const getJoint = (secondConnectedAsset: NetworkAsset | undefined) => {
    return firstConnectedAsset?.type === "existingcable"
      ? vertices[0]
      : secondConnectedAsset?.type === "existingcable"
      ? currentLatLng
      : undefined;
  };

  const getPointOfConnectionType = (arr: any, arr2: string[]) => {
    return arr.find((a: string) => arr2.includes(a));
  };

  const findOverheadPole = (
    connectedAsset: NetworkAsset | undefined,
    allVertices: LatLngObj[]
  ) => {
    if (!connectedAsset) return;

    // if connected asset is a cable, find it on the network
    const cable = network.find(
      (c) => c.id === connectedAsset.id && c.type === "cable"
    );

    // or return if not
    if (!cable) return;

    // get new cable start and end point
    const newCablePoints = [
      allVertices[0],
      allVertices[allVertices.length - 1],
    ];

    // get cable start and end point
    const cablePoints = cable.polyGeometry as LatLngObj[];

    // if cables share a start or end point, find and return cable first or second connected overhead pole
    if (compareLatLngs(newCablePoints, cablePoints)) {
      if (cable.firstConnectedAsset?.startsWith("overhead_poles"))
        return overheadPoles.find((op) => op.id === cable.firstConnectedAsset);
      if (cable.secondConnectedAsset?.startsWith("overhead_poles"))
        return overheadPoles.find((op) => op.id === cable.secondConnectedAsset);
    }

    return;
  };

  const completeCable = (features?: FeatureData[]) => {
    const allVertices = [...vertices, currentLatLng as LatLngObj];

    // Init empty array
    let assets: NetworkAsset[] = [];
    // Add either site or cable to array with vertices including current latlng

    let isConnected = undefined;
    let pointOfConnectionType = undefined;
    let _firstConnectedAsset: NetworkAsset | undefined = firstConnectedAsset;
    let secondConnectedAsset: NetworkAsset | undefined;
    let _existingConnectedAssets: ExistingNetworkAsset[] | undefined;
    let connectedColor;

    if (isSnapped && currentSnappedAsset) {
      secondConnectedAsset = currentSnappedAsset;

      // if connected asset is a cable, check if it should be an overhead pole and replace it
      _firstConnectedAsset =
        findOverheadPole(firstConnectedAsset, allVertices) ||
        firstConnectedAsset;
      secondConnectedAsset =
        findOverheadPole(secondConnectedAsset, allVertices) ||
        secondConnectedAsset;

      _existingConnectedAssets = filterExistingConnectedAssets(
        _firstConnectedAsset,
        secondConnectedAsset
      );

      isConnected = validateCable(_firstConnectedAsset, secondConnectedAsset);

      if (isConnected) {
        const connectedAsset: any = [
          _firstConnectedAsset!,
          secondConnectedAsset,
        ].find((a) => ["existingcable", "existingpole"].includes(a.type));
        connectedColor = connectedAsset?.properties?.color
          ? connectedAsset?.properties?.color
          : undefined;
      }

      pointOfConnectionType = getPointOfConnectionType(
        [_firstConnectedAsset?.type, secondConnectedAsset.type],
        ["existingcable", "existingpole"]
      );
    }

    assets.push(
      asset(
        undefined,
        activeTool as string,
        undefined,
        allVertices,
        undefined,
        ngedId,
        undefined,
        _firstConnectedAsset?.id,
        secondConnectedAsset?.id,
        isConnected,
        pointOfConnectionType,
        undefined,
        connectedColor
      )
    );

    const markerGeometry = getJoint(secondConnectedAsset);

    if (markerGeometry) {
      assets.push(
        asset(
          undefined,
          "marker",
          markerGeometry,
          undefined,
          "joint",
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      );
    }

    const connectedAssets = updateConnectedAssets(
      assets,
      network,
      conductingSections,
      overheadPoles,
      existingConnectedAssets
    );
    // if (activeTool === "cable") assets = addNodeToCable(assets);
    const _activeTool = isNetworkConnected(connectedAssets) ? "cable" : "";

    dispatch(
      completeAsset2({
        connectedAssets,
        assets,
        _activeTool,
        features: getFeaturesData(features),
        existingConnectedAssets: _existingConnectedAssets,
      })
    );
  };

  const filterExistingConnectedAssets = (
    _firstConnectedAsset: ExistingNetworkAsset | undefined,
    secondConnectedAsset: ExistingNetworkAsset | undefined
  ) => {
    let filteredExistingConnectedAssets: ExistingNetworkAsset[] = [];
    [_firstConnectedAsset, secondConnectedAsset].forEach((asset) => {
      if (
        asset &&
        (asset.id.startsWith("conducting_sections") ||
          asset.id.startsWith("overhead_poles"))
      ) {
        filteredExistingConnectedAssets.push(asset);
      }
    });
    return filteredExistingConnectedAssets.length
      ? filteredExistingConnectedAssets
      : undefined;
  };

  const getFeaturesData = (features?: FeatureData[]) => {
    if (!features || !features.length) {
      return [];
    }

    const getValidProperties = (properties: GeoJsonProperties) => {
      if (!properties) {
        return null;
      }

      return {
        Fid: properties["Fid"],
        GroundType: properties["GroundType"],
        Theme: properties["Theme"],
        DescriptiveGroup: properties["DescriptiveGroup"],
        DescriptiveTerm: properties["DescriptiveTerm"],
        Make: properties["Make"],
      };
    };

    return features.map((x) => {
      return {
        ...x,
        properties: getValidProperties(x.properties),
      };
    });
  };

  const completeExisting = (
    _activeTool: string | undefined = undefined,
    _ngedId: string | undefined = undefined,
    topography: FeatureCollection<Polygon>
  ) => {
    // Init empty array
    let assets: NetworkAsset[] = [];
    const coords: LatLngObj[] = topography.features[0].geometry
      .coordinates[0] as any[];
    const fid: string = topography.features[0].properties?.Fid;

    assets.push(
      asset(
        undefined,
        activeTool as string,
        undefined,
        coords,
        undefined,
        ngedId,
        fid,
        undefined,
        undefined,
        false,
        undefined,
        true
      )
    );

    let connectedAssets = undefined;
    dispatch(
      completeAsset({
        connectedAssets,
        assets,
        _activeTool,
        _ngedId,
      })
    );
  };

  const runFeature = async (coord: LatLngObj) => {
    let invalid = false;
    let _features: Feature[] = [];

    const featureCollection = getFeatureCollection(
      coord,
      FeatureType.point,
      groundTypes
    );

    if (!featureCollection) return { features: [], invalid: false };

    const reversedFeatureCollection: FeatureCollection = convertCoords(
      featureCollection,
      TransformFormat.reverselatlng
    );

    _features = reversedFeatureCollection.features.filter(
      (feature) => getGroundType(feature) === "Invalid Route"
    );

    const invalidFeatures = reversedFeatureCollection.features
      .filter((feature) => getGroundType(feature) === "Invalid Route")
      .map((m) => (m.geometry as Polygon).coordinates);

    if (invalidFeatures.length) {
      setInvalidTopographies(invalidFeatures);
      invalid = true;
    }
    return { features: _features, invalid };
  };

  const runFeatures = async (line: LatLngObj[]) => {
    let invalid = false;
    const _features: FeatureData[] = [];
    const reversedCoords: Position[] = line.map((m) => [m.lng, m.lat]);

    const featureCollection = getFeatureCollection(
      reversedCoords,
      FeatureType.lineString,
      groundTypes
    );
    const reversedFeatureCollection: FeatureCollection = convertCoords(
      featureCollection,
      TransformFormat.reverselatlng
    );

    reversedFeatureCollection.features.forEach((feature) => {
      _features.push(getFeatureData(feature, reversedCoords));
    });

    const firstConnectedAssetFid = firstConnectedAsset?.topographyId;

    const invalidFeatures = _features
      .filter(
        (feature) =>
          feature?.properties?.Fid !== firstConnectedAssetFid &&
          !isGroundTypeValid(feature?.properties?.GroundType)
      )
      .map((m) => m.coords);

    if (invalidFeatures.length) {
      setInvalidIntersections(invalidFeatures);
      invalid = true;
    }
    return { features: _features, invalid };
  };

  const fetchExistingBuilding = async (coord: LatLngObj) => {
    let reversedFeatureCollection: FeatureCollection<Polygon> | undefined;
    const featureCollection = getFeatureCollection(
      coord,
      FeatureType.point,
      groundTypes
    );

    if (
      featureCollection &&
      featureCollection.features[0].properties &&
      featureCollection.features[0].properties.Theme === "Buildings"
    ) {
      reversedFeatureCollection = convertCoords(
        featureCollection,
        TransformFormat.reverselatlng
      );
    }
    return reversedFeatureCollection;
  };

  const handleSnapping = (e: LeafletMouseEvent) => {
    const snappableAssets =
      activeTool === "cable"
        ? cableSnapList()
        : activeTool === "new-domestic" ||
          activeTool === "new-non-domestic" ||
          activeTool === "new-mixed"
        ? premiseSnapList()
        : siteSnapList();
    const closestAsset = _calcClosestAsset(map, e.latlng, snappableAssets);
    const refinedSnapping = refineSnapping(map, e, closestAsset, 6);
    return { closestAsset, refinedSnapping };
  };

  const markerRadiiIntersections = async (_currentLatLng?: LatLngObj) => {
    if (!_currentLatLng) return;
    let intersectingTopographies: Feature<Polygon>[] = [];
    const coords = [_currentLatLng.lng, _currentLatLng.lat];
    const radiusInKm = 0.03;
    const options = {
      steps: 36,
      units: "kilometers" as Units,
    };
    const _circleProps: GeoJsonProperties = circle(coords, radiusInKm, options);
    await runCircleFeatures(_circleProps.geometry.coordinates).then(
      (features) => {
        if (features) {
          intersectingTopographies = [...intersectingTopographies, ...features];
        }
      }
    );
    let _circle: Feature = {
      type: "Feature",
      geometry: _circleProps.geometry,
      properties: _circleProps.properties,
    };
    return {
      intersectingTopographies,
      _circle,
    };
  };

  const fetchAssets = async (bounds: L.LatLngBounds, layerName: string[]) => {
    const EPSG = EPSGType.latLng;
    const features = await getLayer(
      layerName.map((address) => url(address, EPSG)),
      EPSG,
      totalkVA,
      connectionPoints,
      undefined,
      bounds
    );
    return features;
  };

  const ragCablesWithinRadius = async (vertices: LatLngObj[]) => {
    let sitePoints: Position[] = vertices.map((m) => [m.lng, m.lat]);
    const sitePointsArray: Position[][] = [[...sitePoints, sitePoints[0]]];

    const features = polygon(sitePointsArray);
    const buffered = buffer(features, 0.03, { units: "kilometers" });
    const bounds = L.geoJSON(buffered).getBounds();

    let convertedConductingSections: ExistingNetworkAsset[] = [];
    let convertedOverheadPoles: ExistingNetworkAsset[] = [];

    await fetchAssets(
      bounds,
      layerOptions.conductingSections.geoServerAddress
    ).then((t) => (convertedConductingSections = t));
    await fetchAssets(bounds, layerOptions.overheadPoles.geoServerAddress).then(
      (t) => (convertedOverheadPoles = t)
    );

    dispatch(
      addRAGConductingSectionsAndPoles([
        ...convertedConductingSections,
        ...convertedOverheadPoles,
      ])
    );
  };

  const runCircleFeatures = async (circle: Position[][]) => {
    let reversedFeatureCollection: Feature<Polygon>[] = [];
    const featureCollection = getFeatureCollection(
      circle,
      FeatureType.polygon,
      groundTypes
    );
    reversedFeatureCollection = convertCoords(
      featureCollection,
      TransformFormat.reverselatlng
    ).features;
    return reversedFeatureCollection;
  };

  const finaliseMeter = async (
    _currentLatLng: LatLngObj,
    meter: NetworkAsset
  ) => {
    let debugAssets:
      | {
          intersectingTopographies: Feature<Polygon>[];
          _circle: Feature;
        }
      | undefined;
    await markerRadiiIntersections(_currentLatLng).then(
      (t) => (debugAssets = t)
    );

    const intersectingTopographies = debugAssets
      ? debugAssets.intersectingTopographies
      : [];
    const _circle = debugAssets ? debugAssets._circle : undefined;

    if (meter) {
      dispatch(
        updateNetworkAssetAndDebuggingAssets({
          id: meter.id,
          geometry: _currentLatLng,
          intersectingTopographies,
          _circle,
        })
      );
    } else {
      const assets = [];
      assets.push(
        asset(
          undefined,
          "marker",
          _currentLatLng as LatLngObj,
          undefined,
          activeTool,
          ngedId,
          undefined,
          undefined,
          undefined,
          false,
          undefined,
          true
        )
      );
      dispatch(
        addToNetworkAndDebuggingAssets({
          assets,
          _activeTool: undefined,
          _ngedId: undefined,
          intersectingTopographies,
          _circle,
        })
      );
    }
  };

  const handleMeter = async (e: LeafletMouseEvent) => {
    let _currentLatLng = currentLatLng as LatLngObj;
    let _withinBounds = withinBounds;
    if (!_withinBounds) return;
    const meter = network.find((f) => f.ngedId === ngedId) as NetworkAsset;
    if (deviceType !== "mouseOnly") {
      if (activeTool !== "cabinet") {
        const snap = _calcLayerDistances(map, e.latlng, meter).latlng;
        setCurrentLatLng(snap);
        setWithinBounds(isWithinBounds(e.latlng));
      }
    }

    if (activeTool === "cabinet") {
      runFeature(_currentLatLng).then((result) => {
        if (result.invalid) {
          dispatch(
            updateDialog({
              props: ["meter", _currentLatLng, meter],
              type: "warning",
              className: "warning",
              affirmLabel: "OK",
              dismissLabel: "Cancel",
              messages: [
                {
                  description: warningMessage(result.features, "Buildings"),
                },
              ],
            })
          );
        } else {
          finaliseMeter(_currentLatLng, meter);
        }
      });
    } else {
      finaliseMeter(_currentLatLng, meter);
    }
  };

  const handleExisting = (e: LeafletMouseEvent) => {
    let snap = currentLatLng;
    let _withinBounds = withinBounds;
    if (deviceType !== "mouseOnly") {
      snap = handleSnapping(e).refinedSnapping;
    }
    if (!_withinBounds) return;

    fetchExistingBuilding(e.latlng).then(
      (y: FeatureCollection<Polygon> | undefined) => {
        if (y) {
          completeExisting("meter", ngedId, y);
        }
      }
    );
  };

  const handlePremise = (e: LeafletMouseEvent) => {
    let snap = currentLatLng;
    let _withinBounds = withinBounds;
    if (deviceType !== "mouseOnly") {
      snap = handleSnapping(e).refinedSnapping;
    }
    if (!_withinBounds) return;

    if (vertices.length) {
      const line = [vertices[vertices.length - 1], snap as LatLngObj];
      runFeatures(line).then((result) => {
        if (result.invalid) {
          dispatch(
            updateDialog({
              props: ["premise", snap],
              type: "warning",
              className: "warning",
              affirmLabel: "OK",
              dismissLabel: "Cancel",
              messages: [
                {
                  description:
                    "A connection may not be possible to this location. Are you sure you want to continue?",
                },
              ],
            })
          );
        } else {
          finalisePremise(snap);
        }
      });
    } else {
      finalisePremise(snap);
    }
  };

  const finalisePremise = (snap?: LatLngObj) => {
    // Get first vertex
    const marker = vertices[0];

    // If there is a first or last vertex and it is clicked, we want to complete the shape
    if (marker && isMarkerClicked(snap as LatLngObj, marker)) {
      if (vertices.length === 1) return;
      completePremisePerimeter("meter", ngedId);

      // If vertex is not clicked
    } else {
      // add vertex
      dispatch(addVertex(snap as LatLngObj));
      // if there are vertices and you've clicked a network marker (i.e. terminate cable ending on network marker)
      if (
        vertices.length &&
        matchNetworkAsset(network, snap as LatLngObj, ["marker"])
      ) {
        completePremisePerimeter();
      }
    }
  };

  const handleCable = (e: LeafletMouseEvent) => {
    let snap = currentLatLng;
    if (deviceType !== "mouseOnly") {
      snap = handleSnapping(e).refinedSnapping;
    }
    if (snap) {
      const intersectsSelf = cableSelfIntersects(vertices, snap);
      if (intersectsSelf) {
        dispatch(
          updateDialog({
            props: [],
            type: "warning",
            className: "warning",
            dismissLabel: "Close",
            messages: [
              {
                description: "The cable that you have drawn intersects itself.",
              },
            ],
          })
        );
        return;
      }
    }

    if (vertices.length) {
      const line = [vertices[vertices.length - 1], snap as LatLngObj];
      runFeatures(line).then((result) => {
        if (result.invalid) {
          dispatch(
            updateDialog({
              props: ["cable", snap, result.features],
              type: "warning",
              className: "warning",
              affirmLabel: "OK",
              dismissLabel: "Cancel",
              messages: [
                {
                  description:
                    "Please note, your cable route has crossed an invalid ground type. Please check your route.",
                },
              ],
            })
          );
        } else {
          finaliseCable(snap, result.features);
        }
      });
    } else {
      finaliseCable(snap);
    }
  };

  const finaliseCable = (snap?: LatLngObj, features?: FeatureData[]) => {
    let _currentSnappedAsset: NetworkAsset | undefined;
    if (vertices.length < 1) {
      if (isSnapped && currentSnappedAsset) {
        _currentSnappedAsset = currentSnappedAsset;
      }
    }

    if (vertices.length && isSnapped) {
      completeCable(features);
      return;
    }

    if (!vertices.length && !isSnapped) {
      return;
    }

    // If the same cable joint has been clicked more than once, then ignore it
    if (vertices.length && snap) {
      if (vertices.find((v) => v.lat === snap.lat && v.lng === snap.lng))
        return;
    }

    dispatch(
      completeSegment({
        snap,
        features,
        firstConnectedAsset: _currentSnappedAsset,
      })
    );
    setInvalidIntersections([]);
  };

  const handleSite = (e: LeafletMouseEvent) => {
    let snap = currentLatLng;
    if (deviceType !== "mouseOnly") {
      snap = handleSnapping(e).refinedSnapping;
    }

    // Get first vertex
    const marker = vertices[0];

    // If there is a first or last vertex and it is clicked, and there are enough points to make a polygon, we want to complete the shape
    if (marker && latLngArray(snap as LatLngObj, marker)) {
      if (vertices.length < 3) return;
      completeSite();
      ragCablesWithinRadius(vertices);

      // If vertex is not clicked
    } else {
      // Does the site self intersect, if so then show a message
      if (siteSelfIntersects(snap as LatLngObj)) {
        dispatch(
          updateDialog({
            props: [],
            type: "warning",
            className: "warning",
            dismissLabel: "Close",
            messages: [
              {
                description:
                  "The site boundary that you have drawn intersects itself.",
              },
            ],
          })
        );
      } else {
        // add vertex
        dispatch(addVertex(snap as LatLngObj));
      }
      // if there are vertices and you've clicked a network marker (i.e. terminate cable ending on network marker)
      if (
        vertices.length &&
        matchNetworkAsset(network, snap as LatLngObj, ["marker"])
      ) {
        completeSite();
      }
    }
  };

  const siteSelfIntersects = (latLng: LatLngObj) => {
    const snapPoint: Position = [latLng.lng, latLng.lat];
    const sitePoints: Position[] = vertices.map((m) => [m.lng, m.lat]);
    const sitePointsArray: Position[][] = [[...sitePoints, snapPoint]];

    const points = multiLineString(sitePointsArray);
    const intersections = kinks(points);

    return intersections.features.length > 0;
  };

  const isWithinBounds = (e: LatLng) => {
    if (isInPublic) {
      return true;
    }
    const site = network.find((n) => n.type === "site");
    let _site: any = [];
    if (site && site.polyGeometry instanceof Array) {
      _site = (site.polyGeometry as LatLngObj[]).map((geo) => [
        geo.lat,
        geo.lng,
      ]);
    }
    return pointInPolygon([e.lat, e.lng], _site);
  };

  const compareLatLng = (a: LatLngObj | LatLngObj, b: LatLng | LatLngObj) => {
    return a.lat === b.lat && a.lng === b.lng;
  };

  const compareLatLngs = (arr: LatLngObj[], arr2: LatLngObj[]) => {
    return arr.some((a) => arr2.some((b) => compareLatLng(a, b)));
  };

  useMapEvents({
    moveend() {
      getGroundTypes(map.getBounds()).then((featureCollection) => {
        if (!featureCollection) return;
        const reversedFeatureCollection: FeatureCollection<Polygon> =
          convertCoords(featureCollection);

        const groundTypeIds = groundTypes.map((gt) => gt.id);
        const _groundTypes = reversedFeatureCollection.features.filter(
          (feature) => !groundTypeIds.includes(feature.id)
        );
        dispatch(addGroundTypes(_groundTypes));
      });
      latLngToGridRef(map.getCenter());
    },
    mousemove(e) {
      if (activeTool === "meter" || activeTool === "cabinet") {
        const hasNgedId = network.find((f) => f.ngedId === ngedId);
        if (hasNgedId) {
          const snap = _calcLayerDistances(map, e.latlng, hasNgedId).latlng;
          setCurrentLatLng(snap);
          setWithinBounds(isWithinBounds(snap));
        } else {
          setCurrentLatLng({ lat: e.latlng.lat, lng: e.latlng.lng });
          setWithinBounds(isWithinBounds(e.latlng));
        }
      } else {
        const snap = handleSnapping(e);
        setCurrentSnappedAsset(snap.closestAsset.asset);
        setCurrentLatLng(snap.refinedSnapping);
        setIsSnapped(!compareLatLng(snap.refinedSnapping, e.latlng));
        [
          "new-domestic",
          "new-non-domestic",
          "new-mixed",
          "meter",
          "cabinet",
          "existing-domestic",
          "existing-non-domestic",
          "existing-mixed",
        ].includes(activeTool || "") &&
          setWithinBounds(isWithinBounds(e.latlng));
      }
    },

    click(e) {
      switch (activeTool) {
        case "site":
          return handleSite(e);
        case "new-domestic":
        case "new-non-domestic":
        case "new-mixed":
          return handlePremise(e);
        case "existing-domestic":
        case "existing-non-domestic":
        case "existing-mixed":
          return handleExisting(e);
        case "meter":
        case "cabinet":
          return handleMeter(e);
        case "cable":
          return handleCable(e);
        default:
          return true;
      }
    },
  });

  const getIconSize = (network: NetworkAsset[], latlng: LatLngObj) => {
    const marker = matchNetworkAsset(network, latlng);
    return marker && marker.name ? 1 : 1;
  };

  const styleIcon = (index: number, latlng: LatLngObj) => {
    const iconSize = getIconSize(network, latlng);
    const getIndex = activeTool === "cable" ? vertices.length - 1 : 0;
    const iconGlow = getIndex === index ? "iconGlow" : "";
    return iconMarker(iconSize, `iconBorder ${iconGlow}`);
  };

  const handleDialogAffirmAction = (props?: any[]) => {
    if (!props) return;
    dispatch(updateDialog({}));
    if (props[0] === "premise") {
      const snap: LatLng = props[1];
      setInvalidIntersections([]);
      finalisePremise(snap);
    }
    if (props[0] === "meter") {
      const snap: LatLng = props[1];
      const meter: NetworkAsset = props[2];
      finaliseMeter(snap, meter);
    }
    if (props[0] === "cable") {
      const snap: LatLng = props[1];
      const features: FeatureData[] = props[2];
      finaliseCable(snap, features);
    }
  };

  const handleDialogDismissAction = () => {
    dispatch(updateDialog({}));
    setInvalidIntersections([]);
    setInvalidTopographies([]);
  };

  return (
    <>
      <Hints
        currentLatLng={currentLatLng as LatLngObj}
        withinBounds={withinBounds}
      />
      {vertices.map((latlng, index) => (
        <Marker
          key={`${latlng.lat}${latlng.lng}`}
          position={latlng}
          icon={styleIcon(index, latlng)}
        ></Marker>
      ))}
      {vertices.length > 1 && <Polyline positions={[...vertices]}></Polyline>}
      {invalidIntersections.map((intersection) => (
        <Polyline
          key={`${intersection[0]}${intersection[1]}`}
          positions={[...(intersection as [number, number][])]}
          color="red"
        ></Polyline>
      ))}
      {invalidTopographies.map((topography) => (
        <RLPolygon
          key={`${topography[0]}${topography[1]}`}
          positions={[...(topography as [number, number][][])]}
          color="red"
        ></RLPolygon>
      ))}
      <DialogModal
        affirmDialogAction={handleDialogAffirmAction}
        dismissDialogAction={handleDialogDismissAction}
      />
    </>
  );
};

export default DrawingTool;
