import { CircleMarker } from "react-leaflet";
import { ExistingNetworkAsset } from "../../models/models";
import { UserRole } from "../../models/enums";
import { layerOptionValues } from "../layerToggle/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectExistingNetwork,
  setSelectedAsset,
} from "./existingNetworkSlice";
import { ConsoleOption, selectApp } from "../../app/appSlice";

interface OverheadPoleProps {
  overheadPole: ExistingNetworkAsset;
  userRole: UserRole;
  isTransformer?: boolean;
  color: string;
}

export const OverheadPole = ({
  overheadPole,
  userRole,
  isTransformer,
  color,
}: OverheadPoleProps) => {
  const dispatch = useAppDispatch();
  const { consoleMode } = useAppSelector(selectApp);
  const { selectedAsset } = useAppSelector(selectExistingNetwork);
  const { id, markerGeometry, properties } = overheadPole;

  if (!markerGeometry || !properties) {
    return null;
  }

  const isInspectingPole =
    consoleMode === ConsoleOption.selectMapFeatures && id === selectedAsset?.id;
  const displayColor = isInspectingPole ? "blue" : color;

  return (
    <CircleMarker
      center={markerGeometry}
      radius={color ? 6 : 4}
      pathOptions={{ color: displayColor }}
      opacity={userRole === UserRole.public ? 0 : 1}
      fillOpacity={isTransformer ? 0 : 1}
      eventHandlers={{
        click: () => {
          dispatch(
            setSelectedAsset({
              ...overheadPole,
              ...{ assetType: layerOptionValues.overheadPoles },
            })
          );
        },
      }}
    ></CircleMarker>
  );
};
