import {
  AcLineSegmentProperties,
  PoleProperties,
  TransformerProperties,
  AcceptedToConnectProperties,
} from "../models/featureProperties";
import { ExistingNetworkAsset } from "../models/models";
import { formatBoolean, formatNumber, formatString } from "./formatService";

export type DataListProperties = { [index: string]: string | number };

export const getConductingSectionFeatureProperties = (
  conductingSection: ExistingNetworkAsset
): DataListProperties => {
  const props = conductingSection.properties as
    | AcLineSegmentProperties
    | undefined;

  if (props === undefined) {
    return {};
  }

  const cable: DataListProperties = {
    "Id": formatNumber(props.Id, 0, null),
    "Ac Line Segment Id": formatString(props.AcLineSegmentId),
    "Class": formatString(props.Class),
    "Diagram Id": formatNumber(props.DiagramId, 0, null),
    "Substation Number": formatNumber(props.SubstationNumber, 0, null),
    "Feeder Number": formatNumber(props.FeederNumber, 0, null),
    "Rating Type": formatString(props.RatingType),
    "Annotation": formatString(props.Annotation),
    "Number Of Phases": formatString(props.NumberOfPhases),
    "Number Of Phases Is Default": formatBoolean(props.NumberOfPhasesIsDefault),
    "Rating": formatNumber(props.Rating, 0, "A"),
    "Rating Is Default": formatBoolean(props.RatingIsDefault),
    "Cable Type": formatString(props.CableType),
    "Cable Type Is Default": formatBoolean(props.CableTypeIsDefault),
    "Auto Select": formatBoolean(props.AutoSelect),
    "Length": formatNumber(props.Length, 2, "m"),
    "Override Length": formatNumber(props.OverrideLength, 0, null),
    "Start Asset Id": formatString(props.StartAssetId),
    "End Asset Id": formatString(props.EndAssetId),
    "Cable Group": formatString(props.CableGroup),
    "Cable Group Is Default": formatBoolean(props.CableGroupIsDefault),
    "Ducting": formatBoolean(props.Ducting),
    "Grouped Connection Points": formatString(props.GroupedConnectionPoints),
    "Max Current": formatNumber(props.MaxCurrentAmps, 2, "A"),
    "Max Utilisation": formatNumber(props.MaxUtilisationPercent, 2, "%"),
    "Max Branch Utilisation Upstream": formatNumber(
      props.MaximumBranchUtilisationUpstreamPercent,
      2,
      "%"
    ),
    "Max Branch Utilisation Upstream Rating": formatNumber(
      props.MaximumBranchUtilisationUpstreamRatingAmps,
      0,
      "A"
    ),
    "Min Branch Capacity Upstream": formatNumber(
      props.MinimumBranchCapacityUpstreamKVA,
      2,
      "kVA"
    ),
    "Min Branch Capacity Upstream Rating": formatNumber(
      props.MinimumBranchCapacityUpstreamRatingAmps,
      0,
      "A"
    ),
    "Season": formatString(props.Season),
    "Source Loop Operating Impedance": formatNumber(
      props.SourceLoopOperatingImpedance,
      2,
      "mΩ"
    ),
    "Max Volt Drop": formatNumber(props.MaxVoltDropPercent, 2, "%"),
    "Transformer Max Utilisation": formatNumber(
      props.TransformerMaxUtilisationPercent,
      2,
      "%"
    ),
    "Cable Rating": formatNumber(props.CableRatingAmps, 0, "A"),
    "Transformer Rating": formatNumber(props.TransformerRatingkVA, 0, "kVA"),
    "System Voltage": formatNumber(props.SystemVoltage, 0, "V"),
  };

  return cable;
};

export const getOverheadPoleFeatureProperties = (
  overheadPole: ExistingNetworkAsset
): DataListProperties => {
  const props = overheadPole.properties as PoleProperties | undefined;

  if (props === undefined) {
    return {};
  }

  const display: DataListProperties = {
    "Id": formatNumber(props.Id, 0, null),
    "Junction Id": formatString(props.JunctionId),
    "Class": formatString(props.Class),
    "Diagram Id": formatNumber(props.DiagramId, 0, null),
    "Substation Number": formatNumber(props.SubstationNumber, 0, null),
    "Feeder Number": formatNumber(props.FeederNumber, 0, null),
    "Grouped Connection Points": formatString(props.GroupedConnectionPoints),
    "Max Branch Utilisation Upstream": formatNumber(
      props.MaximumBranchUtilisationUpstreamPercent,
      2,
      "%"
    ),
    "Season": formatString(props.Season),
    "Source Loop Operating Impedance": formatNumber(
      props.SourceLoopOperatingImpedance,
      2,
      "mΩ"
    ),
    "Max Volt Drop": formatNumber(props.MaxVoltDropPercent, 2, "%"),
    "Transformer Max Utilisation": formatNumber(
      props.TransformerMaxUtilisationPercent,
      2,
      "%"
    ),
    "Cable Rating": formatNumber(props.CableRatingAmps, 0, "A"),
    "Transformer Rating": formatNumber(props.TransformerRatingkVA, 0, "kVA"),
    "System Voltage": formatNumber(props.SystemVoltage, 0, "V"),
  };

  return display;
};

export const getHVFeatureProperties = (hv: ExistingNetworkAsset) => {
  const props = hv?.properties as any;
  let cable = {};

  if (hv?.id?.startsWith("hv.")) {
    cable = {
      cable_length: props.cablelength,
      cable_type: props.cabletype,
      phases_in_use: props.phasesinuse,
      is_overhead:
        props.isoverhead !== 0 || props.isoverhead === true ? "true" : "false",
      stage_attained_date:
        (typeof props.stageattaineddate === "string" ||
          props.stageattaineddate instanceof String) &&
        props.stageattaineddate
          ?.split("T")[0]
          .replace(/(\d{4})(\d{2})(\d{2})/, "$3/$2/$1"),
      asset_lifestyle_stage: props.assetlifestylestage,
    };
  } else if (hv?.id?.startsWith("transformer.")) {
    cable = {
      spid: props.uniqueid,
      tx_name: props.txname,
      tx_number: props.txnumber,
      tx_size: props.txsize,
    };
  } else if (hv?.id?.startsWith("supportstay.")) {
    cable = {
      support_stay: true,
    };
  } else if (hv?.id?.startsWith("ufs_hv.")) {
    cable = {
      cable_length: props.cablelength,
      cable_type: props.cabletype,
      phases_in_use: props.phasesinuse,
      is_overhead:
        props.isoverhead !== 0 || props.isoverhead === true ? "true" : "false",
      stage_attained_date:
        (typeof props.stageattaineddate === "string" ||
          props.stageattaineddate instanceof String) &&
        props.stageattaineddate
          ?.split("T")[0]
          .replace(/(\d{4})(\d{2})(\d{2})/, "$3/$2/$1"),
      asset_lifestyle_stage: props.assetlifestylestage,
    };
  } else {
  }
  return cable;
};

export const getLVMainFeatureProperties = (lvmain: ExistingNetworkAsset) => {
  const props = lvmain?.properties as any;
  let cable = {};

  if (
    lvmain?.id?.startsWith("lvjoint") ||
    lvmain?.id?.startsWith("lvtermination") ||
    lvmain?.id?.startsWith("meteredservicetermination")
  ) {
    cable = {
      desc: props.tooltip,
    };
  } else if (lvmain?.id?.startsWith("lvmain")) {
    cable = {
      spid: props.uniqueid,
      activity_reference: props.activityreference,
    };
  } else if (lvmain?.id?.startsWith("site")) {
    cable = {
      spid: props.uniqueid,
      substation_name: props.substationname,
      substation_number: props.substationnumber,
    };
  } else if (lvmain?.id?.startsWith("ufs_lvmain")) {
    cable = {
      spid: props.uniqueid,
      activity_reference: props.activityreference,
    };
  }
  return cable;
};

export const getLVServiceCableFeatureProperties = (
  serviceCable: ExistingNetworkAsset
) => {
  const props = serviceCable?.properties as any;
  let cable = {};

  if (serviceCable?.id?.startsWith("customergeneration")) {
    cable = {
      icon: "Generation icon",
    };
  } else if (
    serviceCable?.id?.startsWith("feederpillar") ||
    serviceCable?.id?.startsWith("lvjoint") ||
    serviceCable?.id?.startsWith("lvtermination") ||
    serviceCable?.id?.startsWith("meteredservicetermination") ||
    serviceCable?.id?.startsWith("publiclightingpillar") ||
    serviceCable?.id?.startsWith("servicedistributionunit") ||
    serviceCable?.id?.startsWith("streetfurniture") ||
    serviceCable?.id?.startsWith("unmeteredconnectionpoint") ||
    serviceCable?.id?.startsWith("unmeteredservicetermination") ||
    serviceCable?.id?.startsWith("wallbox")
  ) {
    cable = {
      desc: props.tooltip,
    };
  } else if (
    serviceCable?.id?.startsWith("lvservice") ||
    serviceCable?.id?.startsWith("ufs_lvservice")
  ) {
    cable = {
      service_length: props.servicelength,
      service_type: props.servicetype,
      phases_in_use: props.phasesinuse,
      is_overhead:
        props.isoverhead !== 0 || props.isoverhead === true ? "true" : "false",
      stage_attained_date:
        (typeof props.stageattaineddate === "string" ||
          props.stageattaineddate instanceof String) &&
        props.stageattaineddate
          ?.split("T")[0]
          .replace(/(\d{4})(\d{2})(\d{2})/, "$3/$2/$1"),
      asset_lifestyle_stage: props.assetlifestylestage,
    };
  }

  return cable;
};

export const getThirdpartyCableFeatureProperties = (
  thirdparty: ExistingNetworkAsset
) => {
  const props = thirdparty?.properties as any;
  let cable = {};

  if (thirdparty?.id?.startsWith("idnoboundary")) {
    cable = {
      company_name: props.companyname,
      idno_site: props.idnosite,
    };
  }
  return cable;
};

export const getPowerTransformerFeatureProperties = (
  powerTransformer: ExistingNetworkAsset
): DataListProperties => {
  const props = powerTransformer.properties as
    | TransformerProperties
    | undefined;

  if (props === undefined) {
    return {};
  }
  const {
    id,
    MaxSubstationLoadingKW,
    TransformerMaxUtilisationPercent,
    PowerTransformerId,
    Season,
    SubstationNumber,
    Mounting,
    SystemVoltage,
    RatingType,
    NumberOfPhases,
    NameplateRating,
    CyclicRating,
    FuseSelection,
    FuseRating,
    HvFaultLevel,
    XrRatio,
    NumberOfAtcRequests,
    TotalAtcEnergyConsumption,
    TotalAtcGeneration,
  } = props;
  const display: DataListProperties = {
    "Substation Number": formatNumber(SubstationNumber, 0, null),
    "ID": formatNumber(id, 0, null),
    "Unique ID": formatString(PowerTransformerId),
    "Max Loading": formatNumber(MaxSubstationLoadingKW, 1, "kW"),
    "Max Utilisation": formatNumber(TransformerMaxUtilisationPercent, 2, "%"),
    "Season": formatString(Season),
    "Mounting": formatString(Mounting),
    "System Voltage": formatNumber(SystemVoltage, 0, "V"),
    "Rating Type": formatString(RatingType),
    "Phasing": formatString(NumberOfPhases),
    "Nameplate Rating": formatNumber(NameplateRating, 0, "kVA"),
    "Cyclic Rating": formatNumber(CyclicRating, 0, "kVA"),
    "Fuse Selection": formatString(FuseSelection),
    "Default Fuse Size": formatNumber(FuseRating, 0, null),
    "HV Fault Level": formatNumber(HvFaultLevel, 0, "MVA"),
    "XR Ratio": formatNumber(XrRatio, 0, null),
    "Number of ATC Requests": formatNumber(NumberOfAtcRequests, 0, null),
    "Total ATC Consumption": formatNumber(TotalAtcEnergyConsumption, 1, "kW"),
    "Total ATC Generation": formatNumber(TotalAtcGeneration, 1, "kW"),
  };
  return display;
};

export const getAtCAssetFeatureProperties = (
  acceptedToConnect: ExistingNetworkAsset
): DataListProperties => {
  const props = acceptedToConnect.properties as
    | AcceptedToConnectProperties
    | undefined;

  if (props === undefined) {
    return {};
  }
  const {
    EnquiryRef,
    ExportCapacityKw,
    ImportCapacityKw,
    Phase,
    SubstationNumber,
  } = props;

  const display: DataListProperties = {
    "Enquiry Reference": formatNumber(EnquiryRef, 0, null),
    "Import Capacity": formatNumber(ImportCapacityKw, 1, "kW"),
    "Export Capacity": formatNumber(ExportCapacityKw, 1, "kW"),
    "Phase": formatString(Phase),
    "Substation Number": formatString(SubstationNumber),
  };
  return display;
};
export const getAtCAggregateFeatureProperties = (
  powerTransformer: ExistingNetworkAsset
): DataListProperties => {
  const props = powerTransformer.properties as
    | TransformerProperties
    | undefined;

  if (props === undefined) {
    return {};
  }
  const {
    SubstationNumber,
    NumberOfAtcRequests,
    TotalAtcEnergyConsumption,
    TotalAtcGeneration,
  } = props;

  const display: DataListProperties = {
    "Substation Number": formatNumber(SubstationNumber, 0, null),
    "Number of AtC Requests": formatNumber(NumberOfAtcRequests, 0, null),
    "Total AtC Consumption": formatNumber(TotalAtcEnergyConsumption, 0, "kW"),
    "Total AtC Generation": formatNumber(TotalAtcGeneration, 0, "kW"),
  };
  return display;
};
