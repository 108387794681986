import { ExistingNetworkAsset, NetworkAsset } from "../models/models";
import { isEmpty } from "./isEmpty";
import { checkArrayList } from "./checkArrayList";

const validMarkerConnections = ["cable"];
const validCableConnections = [
  ["cable", "cable"],
  ["cable", "new-domestic"],
  ["cable", "new-non-domestic"],
  ["cable", "new-mixed"],
  ["cable", "existing-domestic"],
  ["cable", "existing-non-domestic"],
  ["cable", "existing-mixed"],
  ["cable", "existingcable"],
  ["cable", "marker"],
  ["cable", "cabinet"],
  ["new-domestic", "existingcable"],
  ["new-non-domestic", "existingcable"],
  ["new-mixed", "existingcable"],
  ["existing-domestic", "existingcable"],
  ["existing-non-domestic", "existingcable"],
  ["existing-mixed", "existingcable"],
  ["existingcable", "marker"],
  ["existingcable", "cabinet"],
];

export const updateConnectedAssets = (
  assets: NetworkAsset[],
  network: NetworkAsset[],
  conductingSections: ExistingNetworkAsset[],
  overheadPoles: ExistingNetworkAsset[],
  existingConnectedAssets: ExistingNetworkAsset[]
) => {
  const connectedAssets: NetworkAsset[] = [];
  assets.forEach((asset) => {
    const firstConnectedAsset = findAndUpdateAsset(
      asset,
      network,
      conductingSections,
      overheadPoles,
      existingConnectedAssets,
      asset.firstConnectedAsset
    );
    const secondConnectedAsset = findAndUpdateAsset(
      asset,
      network,
      conductingSections,
      overheadPoles,
      existingConnectedAssets,
      asset.secondConnectedAsset
    );
    !isEmpty(firstConnectedAsset) && connectedAssets.push(firstConnectedAsset);
    !isEmpty(secondConnectedAsset) &&
      connectedAssets.push(secondConnectedAsset);
  });
  return connectedAssets;
};

const findAndUpdateAsset = (
  asset: NetworkAsset,
  network: NetworkAsset[],
  conductingSections: ExistingNetworkAsset[],
  overheadPoles: ExistingNetworkAsset[],
  existingConnectedAssets: ExistingNetworkAsset[],
  property?: string
) => {
  let a = { ...(network.find((a) => a.id === property) as NetworkAsset) };
  if (!isEmpty(a)) {
    let firstConnectedAsset = [
      ...network,
      ...conductingSections,
      ...overheadPoles,
      ...existingConnectedAssets,
    ].find((ab) => ab.id === a.firstConnectedAsset);
    let secondConnectedAsset = [
      ...network,
      ...conductingSections,
      ...overheadPoles,
      ...existingConnectedAssets,
    ].find((ab) => ab.id === a.secondConnectedAsset);

    if (!a.firstConnectedAsset) {
      a.firstConnectedAsset = asset.id;
      firstConnectedAsset = asset;
    } else if (!a.secondConnectedAsset) {
      a.secondConnectedAsset = asset.id;
      secondConnectedAsset = asset;
    }

    a.isConnected = a.markerGeometry
      ? validateMarker(firstConnectedAsset)
      : validateCable(firstConnectedAsset, secondConnectedAsset);
  }
  return a;
};

const validateMarker = (firstConnectedAsset: NetworkAsset | undefined) => {
  if (!firstConnectedAsset) return false;
  return validMarkerConnections.includes(firstConnectedAsset.type);
};

const validateCable = (
  firstConnectedAsset: NetworkAsset | undefined,
  secondConnectedAsset: NetworkAsset | undefined
) => {
  if (!firstConnectedAsset || !secondConnectedAsset) return false;
  const assetTypes = [
    firstConnectedAsset.type,
    secondConnectedAsset.type,
  ].sort();
  return checkArrayList(assetTypes, validCableConnections);
};
