import { OverlayTrigger, Tooltip, Button, ButtonGroup } from "react-bootstrap";

import UndoRedoBtns from "../../features/steps/UndoRedoBtns";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectSteps, setMode } from "../../features/steps/stepsSlice";
import { AppMode, selectApp, setMouseOverControl } from "../../app/appSlice";

import "./Sidebar.css";
import { usePersistor } from "../../hooks/usePersistor";
import { saveStudyToWebEnquiryReference } from "../../services/persistService";

interface ISidebar {
  children: JSX.Element;
  cssClasses?: string;
}

const Sidebar = ({ children, cssClasses = "" }: ISidebar) => {
  const dispatch = useAppDispatch();
  const { saveNetworkState } = usePersistor();
  const steps = useAppSelector(selectSteps);
  const { mode } = steps;

  const app = useAppSelector(selectApp);
  const { appMode, isReadOnly } = app;

  const handleMode = () => {
    const _mode = mode === "expanded" ? "contracted" : "expanded";
    dispatch(setMode(_mode));
  };

  const onMouseEnter = () => {
    dispatch(setMouseOverControl(true));
  };

  const onMouseLeave = () => {
    dispatch(setMouseOverControl(false));
  };

  return (
    <div
      className={`sidebar ${cssClasses} ${
        mode === "expanded" ? "expanded" : ""
      } ${appMode === AppMode.screenshot ? "screenshot-mode" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label="sidebar"
    >
      <div className="sidebar-btns">
        <ButtonGroup>
          <Button
            variant="link"
            className={`steps-btn ${mode !== "closed" ? "bg-light" : ""}`}
            onClick={handleMode}
            aria-label="home"
          >
            <i
              className={`icon-${
                mode === "expanded" ? "contract-menu" : "expand-menu"
              }`}
            ></i>
          </Button>
          <UndoRedoBtns color="dark" cssClasses="m-2" />
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="save">Save</Tooltip>}
          >
            <Button
              variant="dark"
              onClick={() =>
                saveNetworkState(saveStudyToWebEnquiryReference, false)
              }
              className={"my-2 mr-2"}
              aria-label="Save Study"
              disabled={isReadOnly}
            >
              <i className="icon-save-solid"></i>
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </div>
      {children}
    </div>
  );
};

export default Sidebar;
