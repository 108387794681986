import { ActionCreators } from "redux-undo";
import { Map, LatLng } from "leaflet";

import {
  Inputs,
  AppMode,
  ConsoleOption,
  setAppMode,
  setInputs,
  setConsoleMode,
  setIsReadOnly,
} from "../app/appSlice";
import { clearNetwork } from "../features/networkCreator/networkCreatorSlice";
import { UserRole } from "../models/enums";

import { usePersistor } from "./usePersistor";
import { useAppDispatch } from "../app/hooks";

import { getStudyLoader } from "../services/persistService";
import {
  postcodeToLatLng,
  gridRefToLatLng,
  latLngToLatLng,
  polygonCentroidToLatLng,
} from "../utils/convertToSetView";

export const useInputLoader = function () {
  const dispatch = useAppDispatch();
  const { restoreStudy } = usePersistor();

  const setViewToSearch = async (
    map: Map,
    searchType: string,
    searchValue: string
  ) => {
    let latlng: LatLng = map.getCenter();
    switch (searchType) {
      case "Postcode":
        latlng = await postcodeToLatLng(searchValue);
        break;
      case "Grid Ref":
        latlng = gridRefToLatLng(searchValue);
        break;
      case "LatLng":
        latlng = latLngToLatLng(searchValue);
        break;
    }
    map.setView(latlng);
  };

  const loadInputs = async (event: MessageEvent<Inputs>, map: Map) => {
    dispatch(ActionCreators.clearHistory());
    dispatch(clearNetwork());
    dispatch(setAppMode(AppMode.loading));

    const inputs = event.data;
    const loader = getStudyLoader(inputs);

    if (loader) {
      const restoredStudy = await restoreStudy(loader, inputs);

      if (restoredStudy) {
        map.setView(
          polygonCentroidToLatLng(
            restoredStudy.networkCreatorState.present.network
          )
        );
      }
    } else {
      const _consoleMode =
        inputs.userRole === UserRole.public
          ? ConsoleOption.userGuidance
          : undefined;
      await setViewToSearch(map, event.data.searchType, event.data.searchValue);
      dispatch(setInputs(event.data));
      dispatch(setConsoleMode(_consoleMode));
      dispatch(setIsReadOnly(false));
    }

    if (inputs.searchValue || inputs.existingStudy) {
      window.parent.postMessage(
        {
          message: JSON.stringify({ inputsLoaded: true }, null, "\t"),
        },
        "*"
      );
      console.log("inputsLoaded");
    }
    dispatch(setAppMode(undefined));
  };

  return { loadInputs, setViewToSearch };
};
