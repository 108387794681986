import { paperPrintDimensions } from "../features/quotationPlan/constants";
import { appConfig } from "./configService";

const { quotationText1, quotationText2, quotationText3, quotationLogo } =
  appConfig;

const getBase64 = async (blob: Blob) => {
  var fr = new FileReader();
  return await new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    fr.readAsDataURL(blob);
    fr.onload = async function () {
      try {
        resolve(fr.result);
      } catch (err) {
        reject(err);
      }
    };
    fr.onerror = (err) => {
      reject(err);
    };
  });
};

const mmToPx = (pixels: number, dpi: number = 96) => {
  return (pixels / 25.4) * dpi;
};

export const fileToUrl = async (svg: string) => {
  return await fetch(svg)
    .then((res) => res.blob())
    .then((blob) => {
      return window.URL.createObjectURL(blob);
    });
};

export const fileToBase64 = async (bannerSvg: string) => {
  const blob = await fileFromUrl(bannerSvg);
  return (await getBase64(blob)) as string;
};

export const fileFromUrl = async (file: string) => {
  return await fetch(file)
    .then((res) => res.blob())
    .then((blob) => {
      return blob;
    });
};

const drawImageToCanvas = async (
  img: string,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number
) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = img;
    image.onload = () => {
      ctx.drawImage(image, x, y, w, h);
      resolve(image);
    };
    image.onerror = reject;
  });
};

export const addQuotationPlanLegend = async (
  imageUrl: string,
  bannerUrl: string
): Promise<string | undefined> => {
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx || !imageUrl) return;
  const printDimension = paperPrintDimensions["a3"];
  const margin = printDimension.margin * 2;
  const bannerHeightRatio = 0.21;
  const bannerWidthRatio = 0.54;
  const availablePageSpace = {
    width: mmToPx(printDimension.width - margin),
    height: mmToPx(printDimension.height - margin),
  };

  ctx.canvas.width = availablePageSpace.width;
  ctx.canvas.height = availablePageSpace.height;

  const screenshotWidth = availablePageSpace.width;
  const screenshotHeight = availablePageSpace.height;

  const bannerWidth = availablePageSpace.width * bannerWidthRatio;
  const bannerHeight = availablePageSpace.height * bannerHeightRatio;

  await drawImageToCanvas(
    imageUrl,
    ctx,
    0,
    0,
    screenshotWidth,
    screenshotHeight
  );
  await drawImageToCanvas(
    bannerUrl,
    ctx,
    availablePageSpace.width * (1 - bannerWidthRatio),
    availablePageSpace.height * (1 - bannerHeightRatio),
    bannerWidth,
    bannerHeight
  );

  const base64canvas = canvas.toDataURL("image/png", 1.0);
  const url = await fileToUrl(base64canvas);

  return url;
};

export const getBannerSvg = (
  author?: string,
  searchValue?: string,
  mapScale?: number,
  gridReference?: string,
  title?: string
) => {
  const svgString = `<?xml version="1.0" encoding="UTF-8"?>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 800 190" xml:space="preserve" height="190px" width="800px">
    <g>
      <rect x="0" y="0" fill="#ffffff" stroke="#000000" stroke-width="1.2" width="799" height="190"></rect>
      <rect x="0" y="0" fill="#ffffff" stroke="#000000" stroke-width="1.2" width="190" height="70"></rect>
      <rect x="0" y="70" fill="#ffffff" stroke="#000000" stroke-width="1.2" width="190" height="30"></rect>
      <rect x="0" y="100" fill="#ffffff" stroke="#000000" stroke-width="1.2" width="190" height="30"></rect>
      <rect x="0" y="130" fill="#ffffff" stroke="#000000" stroke-width="1.2" width="799" height="59"></rect>
    </g>
    <text x="10" y="17.5" font-size="12px" font-family="Arial, Helvetica, sans-serif" font-weight="bold">
      Legend
    </text>
    <text x="10" y="38" font-size="10px" font-family="Arial, Helvetica, sans-serif">
      LV Services (new)
    </text>
    <text x="10" y="55" font-size="10px" font-family="Arial, Helvetica, sans-serif">
      LV Mains (new)
    </text>
    
    <path transform="translate(100,34)" d="M 0,0 h 62.6667" fill="none" stroke="#3388ff" stroke-width="1.5"></path>
    <path transform="translate(100,53)" d="M 0,0 h 62.6667" fill="none" stroke="#3388ff" stroke-width="2"></path>
        
    
    <text font-size="14" font-family="Arial, Helvetica, sans-serif">
      <tspan x="10" y="90">
        Date Printed:
      </tspan>
      <tspan x="100" y="90">
        ${new Date().toLocaleDateString("en-GB") || "N/A"}
      </tspan>
    </text>
    <text font-size="14" font-family="Arial, Helvetica, sans-serif">
      <tspan x="10" y="120">
        Scale:
      </tspan>
      <tspan x="50" y="120">
        1:
      </tspan>
      <tspan x="62" y="120">
        ${mapScale || "N/A"}
      </tspan>
    </text>
    <text font-size="14" font-family="Arial, Helvetica, sans-serif">
      <tspan x="197" y="25">
        OS Grid Reference:
      </tspan>
      <tspan x="318" y="25">
        ${gridReference || "N/A"}
      </tspan>
    </text>
    <text font-size="14" font-family="Arial, Helvetica, sans-serif">
      <tspan x="197" y="55">
        Printed By:
      </tspan>
      <tspan x="266" y="55">
        ${author || "N/A"}
      </tspan>
    </text>
    <text x="197" y="85" font-size="14" font-family="Arial, Helvetica, sans-serif">
      Title:
    </text>
    <text x="230" y="85" font-size="14" font-family="Arial, Helvetica, sans-serif">
      ${title || searchValue || "N/A"}
    </text>
    ${quotationText1}
    ${quotationText2}
    ${quotationText3}
    ${quotationLogo}
    </svg>
  `;

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};
