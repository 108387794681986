import ListGroup from "react-bootstrap/ListGroup";
import { useAppSelector } from "../../app/hooks";
import { selectExistingNetwork } from "../../features/existingNetwork/existingNetworkSlice";
import { selectOSFeatures } from "../../features/osFeatures/osFeaturesSlice";
import { MapFeature } from "../../models/enums";
import { selectSelectMapFeatures } from "../../features/selectMapFeatures/selectMapFeaturesSlice";
import { selectSteps } from "../../features/steps/stepsSlice";
import {
  DataListProperties,
  getAtCAggregateFeatureProperties,
  getAtCAssetFeatureProperties,
  getConductingSectionFeatureProperties,
  getHVFeatureProperties,
  getLVMainFeatureProperties,
  getLVServiceCableFeatureProperties,
  getOverheadPoleFeatureProperties,
  getPowerTransformerFeatureProperties,
  getThirdpartyCableFeatureProperties,
} from "../../services/propertyService";
import "./DataList.css";
import { layerOptionValues } from "../../features/layerToggle/constants";
import { ConsoleOption, selectApp } from "../../app/appSlice";
import { selectLayerToggle } from "../../features/layerToggle/layerToggleSlice";

const DataList = () => {
  const { consoleMode } = useAppSelector(selectApp);
  const { osFeature } = useAppSelector(selectOSFeatures);
  const { selectedAsset } = useAppSelector(selectExistingNetwork);
  const { mode } = useAppSelector(selectSteps);
  const { selectedFeature } = useAppSelector(selectSelectMapFeatures);
  const { layers } = useAppSelector(selectLayerToggle);

  const shouldShow = () => {
    if (!mode) {
      return false;
    }

    if (
      (consoleMode === ConsoleOption.selectMapFeatures &&
        selectedFeature === MapFeature.osFeatures &&
        osFeature) ||
      (consoleMode === ConsoleOption.selectMapFeatures &&
        selectedFeature === MapFeature.ragAssets &&
        selectedAsset) ||
      (consoleMode === ConsoleOption.layerToggle &&
        layers.includes(layerOptionValues.acceptedToConnect) &&
        (selectedAsset?.assetType === layerOptionValues.acceptedToConnect ||
          selectedAsset?.assetType === layerOptionValues.powerTransformers))
    ) {
      return true;
    } else return false;
  };

  const getSelectedFeatureProperties = () => {
    let display: DataListProperties;
    if (
      consoleMode === ConsoleOption.layerToggle &&
      layers.includes(layerOptionValues.acceptedToConnect) &&
      (selectedAsset?.assetType === layerOptionValues.acceptedToConnect ||
        selectedAsset?.assetType === layerOptionValues.powerTransformers)
    ) {
      if (selectedAsset?.assetType === layerOptionValues.acceptedToConnect) {
        display = getAtCAssetFeatureProperties(selectedAsset);
      } else {
        display = getAtCAggregateFeatureProperties(selectedAsset);
      }
      return Object.entries(display);
    }
    if (selectedFeature === MapFeature.osFeatures && osFeature) {
      return Object.entries(osFeature.properties);
    }
    if (selectedFeature !== MapFeature.ragAssets) return [];
    switch (selectedAsset?.assetType) {
      case layerOptionValues.conductingSections:
        display = getConductingSectionFeatureProperties(selectedAsset);
        break;
      case layerOptionValues.overheadPoles:
        display = getOverheadPoleFeatureProperties(selectedAsset);
        break;
      case layerOptionValues.powerTransformers:
        display = getPowerTransformerFeatureProperties(selectedAsset);
        break;
      case layerOptionValues.hvs:
        display = getHVFeatureProperties(selectedAsset);
        break;
      case layerOptionValues.lvMains:
        display = getLVMainFeatureProperties(selectedAsset);
        break;
      case layerOptionValues.lvServices:
        display = getLVServiceCableFeatureProperties(selectedAsset);
        break;
      case layerOptionValues.thirdParties:
        display = getThirdpartyCableFeatureProperties(selectedAsset);
        break;
      default:
        return [];
    }
    return Object.entries(display);
  };

  return (
    <>
      {shouldShow() && (
        <div className="data-list pt-1">
          <ListGroup>
            {getSelectedFeatureProperties().map(([key, value]) => {
              if (key === "desc") {
                return (
                  <ListGroup.Item
                    key={key}
                    className="d-flex justify-content-between px-2 py-1"
                  >
                    <span>{value as string}</span>
                  </ListGroup.Item>
                );
              } else if (key === "spid") {
                try {
                  if (`${value}`.indexOf("-") === -1) {
                    return (
                      <ListGroup.Item
                        key={key}
                        className="d-flex justify-content-between px-2 py-1"
                      >
                        <span className="data-list__key">
                          {key.replaceAll("_", " ")}
                        </span>
                        <b className="text-right">{value as string}</b>
                      </ListGroup.Item>
                    );
                  } else {
                    return (
                      <ListGroup.Item
                        key={key}
                        className="d-flex justify-content-between px-2 py-1"
                      >
                        <span className="data-list__key">
                          {key.replaceAll("_", " ")}
                        </span>
                        <a
                          href={`${value}`.substring(
                            `${value}`.indexOf("-") + 1
                          )}
                          target="_blank"
                        >
                          {`${value}`.split("-")[0]}
                        </a>
                      </ListGroup.Item>
                    );
                  }
                } catch (error) {
                  throw error;
                }
              } else {
                return (
                  <ListGroup.Item
                    key={key}
                    className="d-flex justify-content-between px-2 py-1"
                  >
                    <span className="data-list__key">
                      {key.replaceAll("_", " ")}
                    </span>
                    <b className="text-right">{value as string}</b>
                  </ListGroup.Item>
                );
              }
            })}
          </ListGroup>
        </div>
      )}
    </>
  );
};

export default DataList;
