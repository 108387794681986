import { Position } from "geojson";
import { LatLngTuple, LeafletEvent } from "leaflet";
import { useMemo, useRef } from "react";
import { Marker } from "react-leaflet";
import { LatLngObj } from "../../models/models";
import { iconMarker } from "../../utils/iconMarker";

interface IEditMarker {
  coord: LatLngObj | Position;
  type: string;
  onMoveMarker: (e: LeafletEvent) => void;
  onDeleteCableMarker: (e: LeafletEvent) => void;
}
const EditMarker = ({
  coord,
  type,
  onMoveMarker,
  onDeleteCableMarker,
}: IEditMarker) => {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dblclick(e: LeafletEvent) {
        const marker = markerRef.current;
        if (marker != null) {
          onDeleteCableMarker(e);
        }
      },
      dragend(e: LeafletEvent) {
        const marker = markerRef.current;
        if (marker != null) {
          onMoveMarker(e);
        }
      },
    }),
    []
  );

  const styleIcon = () => {
    return iconMarker(1, "iconEdit", type);
  };

  return (
    <Marker
      draggable={true}
      icon={styleIcon()}
      eventHandlers={eventHandlers}
      position={coord as LatLngTuple}
      ref={markerRef}
      zIndexOffset={99999}
    />
  );
};

export default EditMarker;
