import { Feature, UserRole } from "../models/enums";

type RoleMapping = {
  [key in UserRole]: { [key in Feature]: boolean };
};

const roleMapping: RoleMapping = {
  [UserRole.public]: {
    [Feature.fullScreen]: true,
    [Feature.userGuidance]: true,
    [Feature.debugger]: false,
    [Feature.selectFeature]: false,
    [Feature.editPlan]: false,
    [Feature.editMainsCable]: false,
    [Feature.quotationPlan]: false,
    [Feature.quotationBasicTools]: false,
    [Feature.quotationAdvancedTools]: false,
    [Feature.layers]: false,
    [Feature.atcLocation]: false,
    [Feature.schematic]: true,
    [Feature.autoQuotationPlan]: true,
  },
  [UserRole.icp]: {
    [Feature.fullScreen]: true,
    [Feature.userGuidance]: true,
    [Feature.debugger]: false,
    [Feature.selectFeature]: false,
    [Feature.editPlan]: true,
    [Feature.editMainsCable]: false,
    [Feature.quotationPlan]: true,
    [Feature.quotationBasicTools]: true,
    [Feature.quotationAdvancedTools]: false,
    [Feature.layers]: true,
    [Feature.atcLocation]: true,
    [Feature.schematic]: true,
    [Feature.autoQuotationPlan]: true,
  },
  [UserRole.nged]: {
    [Feature.fullScreen]: true,
    [Feature.userGuidance]: true,
    [Feature.debugger]: false,
    [Feature.selectFeature]: false,
    [Feature.editPlan]: true,
    [Feature.editMainsCable]: false,
    [Feature.quotationPlan]: true,
    [Feature.quotationBasicTools]: true,
    [Feature.quotationAdvancedTools]: false,
    [Feature.layers]: true,
    [Feature.atcLocation]: true,
    [Feature.schematic]: true,
    [Feature.autoQuotationPlan]: true,
  },
  [UserRole.developer]: {
    [Feature.fullScreen]: true,
    [Feature.userGuidance]: true,
    [Feature.debugger]: true,
    [Feature.selectFeature]: true,
    [Feature.editPlan]: true,
    [Feature.editMainsCable]: true,
    [Feature.quotationPlan]: true,
    [Feature.quotationBasicTools]: true,
    [Feature.quotationAdvancedTools]: true,
    [Feature.layers]: true,
    [Feature.atcLocation]: true,
    [Feature.schematic]: true,
    [Feature.autoQuotationPlan]: true,
  },
};

export const isFeatureEnabled = (feature: Feature, userRole: UserRole) => {
  const featureSwitchKey = `REACT_APP_FEATURE_${feature}`;
  const isFeatureEnabled = process.env[featureSwitchKey] === "true";
  const isUserPermitted = roleMapping[userRole][feature];

  return isFeatureEnabled && isUserPermitted;
};
