import { Marker, Tooltip } from "react-leaflet";
import { ExistingNetworkAsset } from "../../../models/models";
import { gmtIcon } from "./gmtIcon";
import { pmtIcon } from "./pmtIcon";
import {
  selectExistingNetwork,
  setSelectedAsset,
} from "../existingNetworkSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DivIcon } from "leaflet";
import { PowerTransformerMounting } from "../../../models/enums";
import { TransformerProperties } from "../../../models/featureProperties";
import { ConsoleOption, selectApp } from "../../../app/appSlice";
import { selectLayerToggle } from "../../layerToggle/layerToggleSlice";
import styles from "./PowerTransformer.module.css";
import { layerOptionValues } from "../../layerToggle/constants";

interface PowerTransformerProps {
  powerTransformer: ExistingNetworkAsset<TransformerProperties>;
  color: string;
}

export const PowerTransformer = ({
  powerTransformer,
  color,
}: PowerTransformerProps) => {
  const dispatch = useAppDispatch();
  const { markerGeometry, properties } = powerTransformer;
  const { selectedAsset } = useAppSelector(selectExistingNetwork);
  const { consoleMode } = useAppSelector(selectApp);
  const { layers } = useAppSelector(selectLayerToggle);

  const isInspectingTransformer =
    consoleMode === ConsoleOption.selectMapFeatures &&
    powerTransformer.id === selectedAsset?.id;

  const showAtCTooltip =
    layers.includes(layerOptionValues.acceptedToConnect) &&
    properties?.NumberOfAtcRequests !== 0 &&
    properties?.NumberOfAtcRequests !== null;

  if (!markerGeometry || !properties) {
    return null;
  }

  let icon: DivIcon =
    properties.Mounting === PowerTransformerMounting.pmt
      ? pmtIcon(isInspectingTransformer ? "blue" : color)
      : gmtIcon(isInspectingTransformer ? "blue" : color);

  return (
    <Marker
      alt="Power Transformer"
      position={markerGeometry}
      icon={icon}
      eventHandlers={{
        click: () => {
          dispatch(
            setSelectedAsset({
              ...powerTransformer,
              ...{ assetType: layerOptionValues.powerTransformers },
            })
          );
        },
      }}
    >
      {showAtCTooltip && (
        <Tooltip
          permanent
          interactive={true}
          offset={[6, 0]}
          className={styles.atcText}
        >
          + AtC
        </Tooltip>
      )}
    </Marker>
  );
};
