import { appConfig } from "../../services/configService";

const { visibleLayers } = appConfig;

interface layerOptionsType {
  conductingSections: {
    id: string;
    text: string;
    value: string;
    visible: boolean;
    ragLayer: boolean;
    geoServerAddress: string[];
    geoServerType: EPSGType;
  };
  overheadPoles: {
    id: string;
    text: string;
    value: string;
    visible: boolean;
    ragLayer: boolean;
    geoServerAddress: string[];
    geoServerType: EPSGType;
  };
  [layerOption: string]: {
    id: string;
    text: string;
    value: string;
    visible: boolean;
    ragLayer: boolean;
    geoServerAddress: string[] | undefined;
    geoServerType: EPSGType;
  };
}

enum layerOptionValues {
  conductingSections = "conductingSections",
  powerTransformers = "powerTransformers",
  overheadPoles = "overheadPoles",
  lvServices = "serviceCables",
  lvMains = "lvmains",
  hvs = "hvs",
  ehvs = "ehvs",
  thirdParties = "thirdparties",
  wayleaves = "wayleaves",
  mainsLabels = "mains-cable-type",
  acceptedToConnect = "acceptedToConnect",
}

enum EPSGType {
  latLng = "EPSG:4326",
  eastingNorthing = "EPSG:27700",
}

const layerOptions: layerOptionsType = {
  conductingSections: {
    id: "1",
    text: "Conducting Sections",
    value: layerOptionValues.conductingSections,
    visible: visibleLayers.includes(layerOptionValues.conductingSections),
    ragLayer: true,
    geoServerAddress: ["AcLineSegment"],
    geoServerType: EPSGType.latLng,
  },
  powerTransformers: {
    id: "1",
    text: "Power Transformers",
    value: layerOptionValues.powerTransformers,
    visible: visibleLayers.includes(layerOptionValues.powerTransformers),
    ragLayer: true,
    geoServerAddress: ["PowerTransformer"],
    geoServerType: EPSGType.latLng,
  },
  overheadPoles: {
    id: "2",
    text: "Overhead Poles",
    value: layerOptionValues.overheadPoles,
    visible: visibleLayers.includes(layerOptionValues.overheadPoles),
    ragLayer: true,
    geoServerAddress: ["JunctionIsPole"],
    geoServerType: EPSGType.latLng,
  },
  lv: {
    id: "3",
    text: "LV Services",
    value: layerOptionValues.lvServices,
    visible: visibleLayers.includes(layerOptionValues.lvServices),
    ragLayer: false,
    geoServerAddress: [
      "lvservice",
      "connectionpoint",
      "customergeneration",
      "duct",
      "feederpillar",
      "lvjoint",
      "lvtermination",
      "meteredservicetermination",
      "publiclightingpillar",
      "servicedistributionunit",
      "streetfurniture",
      "ufs_duct",
      "ufs_lvservice",
      "unmeteredconnectionpoint",
      "unmeteredservicetermination",
      "wallbox",
    ],
    geoServerType: EPSGType.latLng,
  },
  hv: {
    id: "4",
    text: "HV",
    value: layerOptionValues.hvs,
    visible: visibleLayers.includes(layerOptionValues.hvs),
    ragLayer: false,
    geoServerAddress: [
      "hv",
      "supportstay",
      "transformer",
      "duct",
      "ufs_duct",
      "ufs_hv",
    ],
    geoServerType: EPSGType.latLng,
  },
  ehv: {
    id: "5",
    text: "EHV",
    value: layerOptionValues.ehvs,
    visible: visibleLayers.includes(layerOptionValues.ehvs),
    ragLayer: false,
    geoServerAddress: ["ehv", "ufs_ehv"],
    geoServerType: EPSGType.latLng,
  },
  thirdparties: {
    id: "6",
    text: "Third Party",
    value: layerOptionValues.thirdParties,
    visible: visibleLayers.includes(layerOptionValues.thirdParties),
    ragLayer: false,
    geoServerAddress: ["idnoboundary"],
    geoServerType: EPSGType.latLng,
  },
  lvmains: {
    id: "7",
    text: "LV Mains",
    value: layerOptionValues.lvMains,
    visible: visibleLayers.includes(layerOptionValues.lvMains),
    ragLayer: false,
    geoServerAddress: [
      "duct",
      "lvmain",
      "lvjoint",
      "linkbox",
      "lvtermination",
      "meteredservicetermination",
      "site",
      "supportlocation",
      "ufs_duct",
      "ufs_lvmain",
    ],
    geoServerType: EPSGType.latLng,
  },
  wayleaves: {
    id: "8",
    text: "Wayleaves",
    value: layerOptionValues.wayleaves,
    visible: visibleLayers.includes(layerOptionValues.wayleaves),
    ragLayer: false,
    geoServerAddress: ["wayleave"],
    geoServerType: EPSGType.latLng,
  },
  mainsLabels: {
    id: "9",
    text: "Mains Cable Type",
    value: layerOptionValues.mainsLabels,
    visible: visibleLayers.includes(layerOptionValues.mainsLabels),
    ragLayer: false,
    geoServerAddress: undefined,
    geoServerType: EPSGType.latLng,
  },
  acceptedToConnect: {
    id: "10",
    text: "Accepted To Connect",
    value: layerOptionValues.acceptedToConnect,
    visible: visibleLayers.includes(layerOptionValues.acceptedToConnect),
    ragLayer: false,
    geoServerAddress: ["AcceptedToConnectLV"],
    geoServerType: EPSGType.eastingNorthing,
  },
};

export { layerOptions, layerOptionValues, EPSGType };
