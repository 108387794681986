import { ButtonGroup, Dropdown } from "react-bootstrap";

const TextEditorDropDown = ({
  label,
  value,
  icon,
  array,
  onClick,
}: {
  label?: string;
  value: string;
  icon: boolean;
  array: [string, string][];
  onClick: (e: string) => void;
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic">
        {icon ? (
          <>
            <span className={`item-${value}`}></span> {label && label}
          </>
        ) : (
          value
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {array.map(([option, text]) => (
          <Dropdown.Item as={ButtonGroup} onClick={() => onClick(option)}>
            {icon && <span className={`item-${option}`}></span>}
            {text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TextEditorDropDown;
