import ExistingNetworkAssets from "../features/existingNetwork/ExistingNetworkAssets";
import {
  LayerToggleState,
  selectLayerToggle,
} from "../features/layerToggle/layerToggleSlice";

import { useAppSelector } from "../app/hooks";

import { url } from "../utils/geoServerUrl";
import { layerOptions } from "../features/layerToggle/constants";
import { Pane } from "react-leaflet";

function getGeoServerUrl(layer: string): string[] {
  // get an array of the geoServer urls from the constants in layerOptions
  const geoServerAddresses = layerOptions[layer].geoServerAddress;
  const EPSGType = layerOptions[layer].geoServerType;
  const geoServerUrls = geoServerAddresses
    ? geoServerAddresses.map((address: string) => url(address, EPSGType))
    : ["no_address"];
  return geoServerUrls;
}

function showLayer(toggles: LayerToggleState, layer: string): boolean {
  //return true if the layer is toggled on AND the layer is visible
  return (
    toggles.layers.includes(layerOptions[layer].value) &&
    layerOptions[layer].visible
  );
}

const Controls = () => {
  const toggles = useAppSelector(selectLayerToggle);
  return (
    <>
      <Pane name="quotation-plan-rag-layers" style={{ zIndex: 9990 }}>
        {
          //map across all layer options
          Object.entries(layerOptions).map(function ([layer, layerValues]) {
            //if it is a rag layer and should be shown
            let geoServerUrls = getGeoServerUrl(layer);
            return (
              layerValues.ragLayer &&
              showLayer(toggles, layer) && (
                //display the network assets from GeoServer
                <ExistingNetworkAssets
                  name={layerValues.value}
                  urls={geoServerUrls}
                  layerType={layerValues.geoServerType}
                />
              )
            );
          })
        }
      </Pane>
      <Pane name="quotation-plan-extra-layers" style={{ zIndex: 9991 }}>
        {
          //map across all layer options
          Object.entries(layerOptions).map(function ([layer, layerValues]) {
            //if it is a rag layer and should be shown
            let geoServerUrls = getGeoServerUrl(layer);
            return (
              !layerValues.ragLayer &&
              showLayer(toggles, layer) && (
                //display the network assets from GeoServer
                <ExistingNetworkAssets
                  name={layerValues.value}
                  urls={geoServerUrls}
                  layerType={layerValues.geoServerType}
                />
              )
            );
          })
        }
      </Pane>
    </>
  );
};

export default Controls;
