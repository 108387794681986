//dependencies
import TextPath from "react-leaflet-textpath";
import { Map } from "leaflet";
import { useState, useEffect } from "react";
import { MapContainer, Pane, TileLayer } from "react-leaflet";
import Proj from "proj4leaflet";

//components
import Controls from "../../containers/Controls";
import Sidebar from "../sidebar/Sidebar";
import NetworkCreator from "../../features/networkCreator/NetworkCreator";
import Steps from "../../features/steps/Steps";
import Overlay from "../overlay/Overlay";
import LVAce from "../lvAce/LVAce";
import RequestBuilder from "../requestBuilder/RequestBuilder";
import EditToolbar from "../../features/networkCreator/EditToolbar";
import Spinner from "../spinner/Spinner";
import QuotationPlanToolbar from "../quotationPlanTools/QuotationPlanToolbar";
import QuotationPlan from "../../features/quotationPlan/quotationPlan";
import Banner from "../quotationPlanTools/Banner";
import MapWrapper from "./MapWrapper";
import SchematicTool from "../../features/schematic/SchematicsTool";

//hooks
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import usePremise from "../../hooks/usePremises";
import { useInputLoader } from "../../hooks/useInputLoader";

//state
import { AppMode, Inputs, selectApp, setLVAceActive } from "../../app/appSlice";
import { selectNetworkCreator } from "../../features/networkCreator/networkCreatorSlice";
import { selectQuotationPlan } from "../../features/quotationPlan/quotationPlanSlice";
import {
  selectSchematic,
  selectSchematics,
} from "../../features/schematic/schematicsSlice";

//utils, models, services, helpers
import { polygonCentroidToLatLng } from "../../utils/convertToSetView";
import { UserRole, Zoom } from "../../models/enums";
import { appConfig } from "../../services/configService";
import { isDevtoolsMessage } from "../../utils/devUtils";
import { isNotInputFormat, isNotValidInputs } from "../../utils/inputHelpers";
import { Schematic } from "../../models/models";
import { removeTransformPathLayers } from "../../features/schematic/helpers";

const { osApiKey, startLocation } = appConfig;

//@ts-ignore
const crs = new Proj.CRS(
  "EPSG:27700",
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs",
  {
    resolutions: [
      896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875,
      0.4375, 0.21875, 0.109375, 0.0546875, 0.02734375,
    ],
    origin: [-238375.0, 1376256.0],
  }
);

function LeafletMap() {
  const { allPremisesInNetwork } = usePremise();
  const [map, setMap] = useState<Map | null>(null);
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { network } = networkCreator;
  const {
    appMode,
    inputs,
    lvAceActive,
    submit,
    screenshot,
    takingQuotationScreenshot,
  } = app;
  const { author, searchValue, isCompletedStudy, lvAceComplete, studyTitle } =
    app.inputs;
  const quotationPlan = useAppSelector(selectQuotationPlan);
  const { mapScale, gridReference } = quotationPlan;
  const { loadInputs } = useInputLoader();
  const schematic_ = useAppSelector(selectSchematics);
  const { schematics, selectedSchematic } = schematic_;

  const isQuotationPlan =
    appMode === AppMode.quotationPlan || takingQuotationScreenshot;

  useEffect(() => {
    window.parent.postMessage(
      {
        message: JSON.stringify({ everythingReady: true }, null, "\t"),
      },
      "*"
    );
  }, []); // send a everythingReady message to the window when the map component mounts to let Enigma know we are ready to recieve inputs

  useEffect(() => {
    if (!map) {
      return;
    }

    map.options.zoomSnap = 0;
    map.options.zoomDelta = 0.1;

    window.addEventListener("message", handleObj, false);

    if (network.length) {
      map.setView(polygonCentroidToLatLng(network));
    }
  }, [map]);

  useEffect(() => {
    if (
      allPremisesInNetwork &&
      app.inputs.userRole === UserRole.public &&
      !isCompletedStudy &&
      !lvAceComplete
    ) {
      dispatch(setLVAceActive(true));
    }
  }, [allPremisesInNetwork]);

  const handleObj = async (e: MessageEvent<Inputs>) => {
    if (isDevtoolsMessage(e) || isNotInputFormat(e) || isNotValidInputs(e)) {
      return;
    }
    console.log("message", e);
    if (map) await loadInputs(e, map);
  };

  const handleSchematicClick = (schematic: Schematic) => {
    if (map) removeTransformPathLayers(map);
    dispatch(selectSchematic(schematic));
    //update overlay pane to front
    const overlayPane = document.querySelector(".leaflet-overlay-pane");
    overlayPane?.classList.add("overlay-pane-to-front");
  };

  useEffect(() => {
    map?.invalidateSize();
  }, [isQuotationPlan, map]);

  useEffect(() => {
    isQuotationPlan
      ? map?.scrollWheelZoom.disable()
      : map?.scrollWheelZoom.enable();
  }, [isQuotationPlan, map]);

  useEffect(() => {
    isQuotationPlan
      ? document.body.classList.add("quotationBody")
      : document.body.classList.remove("quotationBody");
  }, [isQuotationPlan]);

  return (
    <>
      <MapWrapper>
        <>
          <MapContainer
            id="map"
            style={{
              width: "100%",
              height: "100%",
            }}
            center={startLocation}
            zoom={Zoom.default}
            minZoom={Zoom.min}
            maxZoom={Zoom.max}
            zoomControl={false}
            doubleClickZoom={false}
            attributionControl={false}
            tap={false}
            whenCreated={setMap}
            crs={crs}
          >
            <TileLayer
              id="tilelayer"
              url={`https://api.os.uk/maps/raster/v1/zxy/Outdoor_27700/{z}/{x}/{y}.png?key=${osApiKey}`}
              maxNativeZoom={13}
            />
            <Pane name="schematic" style={{ zIndex: 9999 }}>
              {schematics
                .filter((f) => f.id !== selectedSchematic?.id && f.visible)
                .map((schematic) => (
                  <SchematicTool
                    key={schematic.id}
                    selectedObject={false}
                    schematic={schematic}
                    onClick={() => handleSchematicClick(schematic)}
                  />
                ))}
              {schematics
                .filter((f) => f.id === selectedSchematic?.id && f.visible)
                .map((schematic) => (
                  <SchematicTool
                    key={schematic.id}
                    selectedObject={true}
                    schematic={schematic}
                    onClick={() => {}}
                  />
                ))}
            </Pane>
            {/* We use Panes, there is an issue with Panes and text paths: 
        https://github.com/clementallen/react-leaflet-textpath/issues/52. 
        This is a workaround *cough* hack *cough* */}
            <TextPath
              positions={[
                [0, 0],
                [0, 0],
              ]}
              text={null}
            />
            <Controls />
            {inputs.searchValue && <NetworkCreator />}
            {appMode === AppMode.quotationPlan && <QuotationPlan />}
            <RequestBuilder />
          </MapContainer>
          {appMode === AppMode.quotationPlan && (
            <Banner
              author={author}
              searchValue={searchValue}
              mapScale={mapScale}
              gridReference={gridReference}
              studyTitle={studyTitle}
            />
          )}
        </>
      </MapWrapper>
      {!appMode && (
        <Sidebar>
          <Steps />
        </Sidebar>
      )}
      {appMode === AppMode.edit && <EditToolbar />}
      {map && appMode === AppMode.quotationPlan && (
        <QuotationPlanToolbar map={map} color="primary" />
      )}
      {(submit ||
        screenshot ||
        appMode === AppMode.screenshot ||
        appMode === AppMode.loading) && (
        <Spinner
          text={
            appMode === AppMode.loading
              ? "Loading..."
              : takingQuotationScreenshot
              ? "Generating Quotation Plan..."
              : appMode === AppMode.screenshot || screenshot
              ? "Generating Screenshot..."
              : appMode === AppMode.saveStudyResults
              ? "Saving Study Results..."
              : "Submitting..."
          }
          variant={takingQuotationScreenshot ? "opaque" : "transparent"}
        />
      )}
      <Overlay show={!inputs.searchValue}></Overlay>
      {lvAceActive && <LVAce />}
    </>
  );
}

export default LeafletMap;
