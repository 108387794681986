import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setBannerSvg } from "../../features/quotationPlan/quotationPlanSlice";
import { fileToUrl, getBannerSvg } from "../../services/screenshotService";

interface IBanner {
  color?: string;
  author?: string;
  searchValue?: string;
  mapScale?: number;
  gridReference?: string;
  studyTitle?: string;
}

const Banner = ({
  author,
  searchValue,
  mapScale,
  gridReference,
  studyTitle,
}: IBanner) => {
  const dispatch = useAppDispatch();

  const svg = useMemo(
    () =>
      getBannerSvg(author, searchValue, mapScale, gridReference, studyTitle),
    [author, searchValue, mapScale, gridReference, studyTitle]
  );

  useEffect(() => {
    const getUrl = async () => await fileToUrl(svg);
    getUrl().then((url) => dispatch(setBannerSvg(url)));
  }, [svg]);

  return <>{svg && <img className="banner" src={svg} alt="svg" />}</>;
};
export default Banner;
