import { RagStatus } from "../models/enums";
import { TransformerProperties } from "../models/featureProperties";
import { GeoServerCable } from "../models/models";
import { appConfig } from "./configService";

const {
  redColor,
  amberColor,
  greenColor,
  unknownColor,
  rvdt,
  avdt,
  rut,
  aut,
  hmct,
} = appConfig;

export const getVoltDropRAG = (featureProps: any) => {
  const { p7 } = featureProps.properties;
  const _rvdt = parseInt(rvdt as string, 10);
  const _avdt = parseInt(avdt as string, 10);
  parseInt(rvdt as string, 10);
  return p7 === null
    ? unknownColor
    : p7 >= _rvdt
    ? redColor
    : p7 >= _avdt
    ? amberColor
    : greenColor;
};

export const getLoadUtilisationRAG = (featureProps: any) => {
  const { p10, p11 } = featureProps;
  const _rut = parseInt(rut as string, 10);
  const _aut = parseInt(aut as string, 10);
  return p10 === null || p11 === null
    ? unknownColor
    : p10 >= _rut || p11 >= _rut
    ? redColor
    : p10 >= _aut || p11 >= _aut
    ? amberColor
    : greenColor;
};

export const getLoadCapacityRAG = (featureProps: any) => {
  const { p6, p12, p11 } = featureProps;
  const _hmct = parseInt(hmct as string, 10);
  return p6 === null || p12 === null
    ? unknownColor
    : p6 < _hmct || (p12 < _hmct && p11 > 0)
    ? redColor
    : greenColor;
};

export const ragCalc = (
  property: number | null,
  lowValue: number,
  highValue: number
): RagStatus => {
  if (property === null) return RagStatus.unknown;
  return property <= lowValue
    ? RagStatus.green
    : property <= highValue
    ? RagStatus.amber
    : RagStatus.red;
};

export const getCableRag = (
  geoServerCable: GeoServerCable,
  totalKva: number,
  maxConsumerPhase: number
) => {
  const {
    TransformerMaxUtilisationPercent: transformerUtilisation,
    MaximumBranchUtilisationUpstreamPercent: cableUtilisation,
    MaxVoltDropPercent: voltDrop,
    SourceLoopOperatingImpedance: sourceLoopImpedance,
    TransformerRatingkVA: transformerRatingKva,
    SystemVoltage: systemVoltage,
    CableRatingAmps: cableRatingAmps,
    NumberOfPhases: cablePhasingString,
  } = geoServerCable;

  const isSinglePhaseConnection = maxConsumerPhase === 1;

  const newTransformerUtilisation =
    transformerUtilisation === null
      ? null
      : transformerUtilisation + (totalKva / transformerRatingKva) * 100;

  const newCableUtilisation =
    cableUtilisation === null
      ? null
      : isSinglePhaseConnection
      ? cableUtilisation +
        ((totalKva * (1000 / systemVoltage)) / cableRatingAmps) * 100
      : cableUtilisation +
        (((totalKva / 3) * 1000) / systemVoltage / cableRatingAmps) * 100;

  let cablePhasing =
    cablePhasingString?.toLowerCase() === "single"
      ? 1
      : cablePhasingString?.toLowerCase() === "split"
      ? 2
      : 3;

  const tuRAG = ragCalc(newTransformerUtilisation, 66, 90);
  const cuRAG = ragCalc(newCableUtilisation, 66, 90);
  const vdRAG = ragCalc(voltDrop, 3.83, 4.79);
  const sliRAG = ragCalc(sourceLoopImpedance, 144, 336);
  const phaseRAG = ragCalc(maxConsumerPhase, cablePhasing, cablePhasing);

  return [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes(RagStatus.unknown)
    ? unknownColor
    : [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes(RagStatus.red)
    ? redColor
    : [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes(RagStatus.amber)
    ? amberColor
    : [tuRAG, cuRAG, vdRAG, sliRAG, phaseRAG].includes(RagStatus.green)
    ? greenColor
    : "";
};

export const getTransformerRag = (
  transformerProperties: TransformerProperties
): RagStatus => {
  const { TransformerMaxUtilisationPercent: utilisation } =
    transformerProperties;

  if (utilisation === null || utilisation === undefined) {
    return RagStatus.amber;
  }

  switch (true) {
    case utilisation <= 66:
      return RagStatus.green;
    case utilisation > 66 && utilisation <= 90:
      return RagStatus.amber;
    case utilisation > 90:
      return RagStatus.red;
    default:
      return RagStatus.amber;
  }
};
