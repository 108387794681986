import styles from "./Legend.module.css";

const Legend = () => {
  return (
    <div
      className="mr-2"
      style={{ backgroundColor: "white", padding: "0.5rem" }}
    >
      <ul className={styles.customList}>
        <li className={styles.listItemGreen}>Connection possible</li>
        <li className={styles.listItemOrange}>Connection may be possible</li>
        <li className={styles.listItemRed}>Connection not possible</li>
      </ul>
    </div>
  );
};

export default Legend;
