import { Fragment, useEffect, useRef } from "react";
import { Pane, useMap, useMapEvents } from "react-leaflet";

import { LeafletEvent } from "leaflet";

import "leaflet-path-transform";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  removeLine,
  selectObject,
  selectQuotationPlan,
  setActiveTool,
  setGridReference,
  updateLine,
} from "./quotationPlanSlice";
import { calculateScaleRatio, ratioToZoomLevel } from "../../utils/mapScale";
import {
  latLngToGridRef,
  polygonCentroidToLatLng,
} from "../../utils/convertToSetView";
import { selectNetworkCreator } from "../networkCreator/networkCreatorSlice";
import EditMarker from "../networkCreator/EditMarker";
import DrawLine from "./line/DrawLine";
import Line from "./line/Line";
import DrawShape from "./shape/DrawShape";
import DrawAnnotation from "./annotation/DrawAnnotation";
import Annotation from "./annotation/Annotation";
import DeleteMarker from "../networkCreator/DeleteMarker";
import { Position } from "geojson";
import Shape from "./shape/Shape";

const lineTypes: string[] = ["duct-line", "arrow-line"];
const shapeTypes: string[] = [
  "insulated-end",
  "short-disconnected-cable",
  "long-disconnected-cable",
  "joint",
  "circle",
];

const QuotationPlan = () => {
  const dispatch = useAppDispatch();
  const map = useMap();
  const scaleSizes = useRef<number[]>([]).current;
  const quotationPlan = useAppSelector(selectQuotationPlan);
  const { network } = useAppSelector(selectNetworkCreator);
  const { activeTool, mapScale, lines, shapes, annotations, selectedObject } =
    quotationPlan;

  useEffect(() => {
    handleScaleRatioSelect(mapScale);
  }, [mapScale]);

  const handleScaleRatioSelect = (scale: number | undefined) => {
    if (scale) {
      const zoomLevel = map.getZoom();
      const ratio = calculateScaleRatio(map);
      scaleSizes[zoomLevel] = ratio;
      const zoomSize = ratioToZoomLevel(scale);

      map.setView(polygonCentroidToLatLng(network), zoomSize);
    }
  };

  const handleClick = (id: string, e: any = null) => {
    dispatch(setActiveTool(undefined));
    dispatch(selectObject({ obj: undefined, map }));
    dispatch(selectObject({ obj: id, map: undefined }));
  };

  const handleMoveMarker = (e: LeafletEvent, id: string, psnIdx: number) => {
    const position = e.target.getLatLng();
    const newPosition = [position.lat, position.lng];
    dispatch(updateLine({ id, psnIdx, newPosition }));
  };

  const handleDeleteLine = (id: string) => {
    dispatch(removeLine(id));
  };

  useMapEvents({
    moveend() {
      const gridRef = latLngToGridRef(map.getCenter());
      dispatch(setGridReference(gridRef));
    },
    click() {
      if (activeTool) return;
      dispatch(selectObject({ obj: undefined, map }));
    },
  });

  return (
    <>
      <Pane name="quotation-plan" style={{ zIndex: 10001 }}>
        {activeTool === "annotation" && <DrawAnnotation />}
        {activeTool && lineTypes.includes(activeTool) && (
          <DrawLine type={activeTool} />
        )}
        {activeTool && shapeTypes.includes(activeTool) && (
          <DrawShape type={activeTool} />
        )}
        {annotations.map((annotation) => (
          <Annotation
            key={annotation.id}
            annotation={annotation}
            onClick={() => handleClick(annotation.id)}
          />
        ))}
        {lines.map((line) => (
          <Line
            key={line.id}
            line={line}
            onClick={() => handleClick(line.id)}
          />
        ))}
        {selectedObject &&
          lines
            .find((line) => line.id === selectedObject)
            ?.positions.map((marker: Position, selectedPsnIdx) => (
              <Fragment key={`${marker[0]}${marker[1]}`}>
                <EditMarker
                  coord={marker}
                  type={""}
                  onMoveMarker={(e) =>
                    handleMoveMarker(e, selectedObject, selectedPsnIdx)
                  }
                  onDeleteCableMarker={() => {}}
                />
                {selectedPsnIdx === 0 && (
                  <DeleteMarker
                    coord={marker}
                    onDeleteMarker={() => handleDeleteLine(selectedObject)}
                  />
                )}
              </Fragment>
            ))}
        {shapes
          .filter((f) => f.id !== selectedObject)
          .map((shape) => (
            <Shape
              key={shape.id}
              selectedObject={false}
              shape={shape}
              onClick={(e) => handleClick(shape.id, e)}
            />
          ))}
        {shapes
          .filter((f) => f.id === selectedObject)
          .map((shape) => (
            <Shape
              key={shape.id}
              selectedObject={true}
              shape={shape}
              onClick={() => {}}
            />
          ))}
      </Pane>
    </>
  );
};

export default QuotationPlan;
