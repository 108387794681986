import { Inputs } from "../../app/appSlice";
import { ConnectionPoint } from "../../models/models";
type ValidatorFunction = (value: unknown) => boolean;

export const validateInputBoolean: ValidatorFunction = (value) => {
  return typeof value === "boolean";
};

export const validateInputNumber: ValidatorFunction = (value) => {
  if (typeof value === "number") return true;

  if (typeof value === "string") {
    return /^\d+(\.\d+)?$/.test(value); //regex to check for [0-9] 1 to unlimited times, with optional group(decimal followed by [0-9] 1 to unlimited times)
  }

  return false;
};

export const validateInputString: ValidatorFunction = (value) => {
  return typeof value === "string";
};

export const validateRequiredInputString: ValidatorFunction = (value) => {
  return typeof value === "string" && !!value.length;
};

export const validateSubConnectionPoint: ValidatorFunction = (value) => {
  if (!value) return false;

  const toValidate = value as ConnectionPoint;

  return (
    validateInputString(toValidate.id) &&
    validateInputString(toValidate.type) &&
    validateInputNumber(toValidate.quantity) &&
    validateInputNumber(toValidate.diversifiedQuantity) &&
    validateInputNumber(toValidate.undiversifiedQuantity) &&
    validateInputString(toValidate.unit)
  );
};

export const validateConnectionPoint: ValidatorFunction = (value) => {
  const toValidate = value as ConnectionPoint;

  const subConnectionPoints = toValidate.subConnectionPoints.map((p) =>
    validateSubConnectionPoint(p)
  );
  const subConnectionPointsValid = !subConnectionPoints.includes(false);

  return (
    validateInputString(toValidate.id) &&
    validateInputString(toValidate.type) &&
    validateInputString(toValidate.phases) &&
    validateInputNumber(toValidate.quantity) &&
    validateInputNumber(toValidate.diversifiedQuantity) &&
    validateInputNumber(toValidate.undiversifiedQuantity) &&
    validateInputString(toValidate.unit) &&
    subConnectionPointsValid
  );
};

export const validateConnectionPoints: ValidatorFunction = (value) => {
  const toValidate = value as ConnectionPoint[];
  const connectionPoints = toValidate.map(validateConnectionPoint);
  return !!connectionPoints.length && !connectionPoints.includes(false);
};

export const validateInputs = (inputs: Inputs): boolean => {
  const inputValidators: { [key in keyof Inputs]: ValidatorFunction | null } = {
    webEnquiryRef: validateInputString,
    connectionPoints: validateConnectionPoints,
    searchType: validateRequiredInputString,
    searchValue: validateRequiredInputString,
    isSiteExcavationCostIncluded: validateInputBoolean,
    isInPublic: validateInputBoolean,
    totalkVA: validateInputNumber,
    userRole: validateRequiredInputString,
    studyTitle: validateInputString,
    author: validateInputString,
    company: validateInputString,
    isNewStudy: validateInputBoolean,
    isCompletedStudy: validateInputBoolean,
    lvAceComplete: validateInputBoolean,
  };

  const inputKeys = Object.keys(inputs) as Array<keyof typeof inputs>;

  const errors = inputKeys.reduce((acc, key) => {
    const validator = inputValidators[key];
    const isValid = validator ? validator(inputs[key]) : true;

    if (!isValid) {
      acc.push(key);
    }

    return acc;
  }, [] as string[]);

  if (errors.length) {
    const errorMessage = `Invalid inputs: ${errors.join(", ")}`;
    window.parent.postMessage(
      {
        message: JSON.stringify({ validationError: errorMessage }, null, "\t"),
      },
      "*"
    );
  }

  return !errors.length;
};
