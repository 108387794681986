import { useState, useEffect } from "react";
import { useMap } from "react-leaflet";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

export interface IZoomControl {
  size?: "sm" | "lg" | undefined;
  color: string;
  cssClasses: string;
}

const ZoomControl = ({ size, color, cssClasses }: IZoomControl) => {
  const map = useMap();
  const [zoomLevel, setzoomLevel] = useState(map.getZoom());

  map.on("zoomend", function () {
    setzoomLevel(map.getZoom());
  });
  useEffect(() => {
    map.setZoom(zoomLevel, { animate: false });
  }, [zoomLevel, map]);

  const setZoom = (value: number) => {
    setzoomLevel(map.getZoom() + value);
  };

  return (
    <ButtonGroup
      vertical
      size={size}
      className={cssClasses}
      aria-label="zoom buttons"
    >
      <Button
        aria-label="zoom-up"
        variant={color}
        disabled={zoomLevel === map.getMaxZoom()}
        onClick={() => setZoom(1)}
      >
        <i className="icon-plus-solid"></i>
      </Button>
      <Button
        aria-label="zoom-down"
        variant={color}
        disabled={zoomLevel === map.getMinZoom()}
        onClick={() => setZoom(-1)}
      >
        <i className="icon-minus-solid"></i>
      </Button>
    </ButtonGroup>
  );
};

export default ZoomControl;
