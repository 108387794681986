import { useEffect, useState } from "react";
import "./Overlay.css";

const Overlay = ({ show }: { show: boolean }) => {
  const [display, setDisplay] = useState<boolean>(true);
  useEffect(() => {
    if (show) return;
    setTimeout(() => {
      setDisplay(false);
    }, 150);
  }, [show]);
  return (
    <>
      {display && (
        <div className={`overlay-backdrop fade ${show ? "show" : ""}`}></div>
      )}
    </>
  );
};

export default Overlay;
