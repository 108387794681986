import Alert from "react-bootstrap/Alert";
import { useAppSelector } from "../../app/hooks";
import { selectApp } from "../../app/appSlice";
import { selectNetworkCreator } from "../../features/networkCreator/networkCreatorSlice";
import usePremises from "../../hooks/usePremises";
import useNetwork from "../../hooks/useNetwork";
import "./UserGuidance.css";

import {
  siteMessages,
  premiseMessages,
  premiseMessagesWithoutSite,
  cableMessages,
  submitMessages,
} from "./userGuidanceFns";
import userGuidanceMessages from "./userGuidanceMessages.json";

const UserGuidance = () => {
  const app = useAppSelector(selectApp);
  const { inputs } = app;

  const { premisesCount, premisesTotal, allPremisesInNetwork } = usePremises();
  const { isNetworkConnected } = useNetwork();
  const networkCreator = useAppSelector(selectNetworkCreator);
  const {
    network,
    activeTool,
    vertices,
    firstConnectedAsset,
    connectionMethod,
  } = networkCreator;

  const networkHasSite = network.some((n) => n.type === "site");
  const networkHasCables = network.some((n) => n.type === "cable");

  const isFirstConnectedAssetCable =
    firstConnectedAsset &&
    ["cable", "existingcable"].includes(firstConnectedAsset.type)
      ? true
      : false;

  const _activeTool = activeTool || "";
  const premiseCounter = `${premisesCount + 1}/${premisesTotal}`;

  let header = "";
  let instruction = "";
  let image = "";
  let counter = "";

  const getUserGuidanceMessages = () => {
    switch (true) {
      case !inputs.isInPublic && !networkHasSite:
        header = userGuidanceMessages["1"];
        const site = siteMessages(_activeTool, vertices);
        instruction = site.instruction;
        image = site.image;
        break;
      case !inputs.isInPublic && !allPremisesInNetwork:
        const premise = premiseMessages(
          _activeTool,
          vertices,
          premisesCount,
          premiseCounter
        );
        header = premise.header;
        instruction = premise.instruction;
        counter = premiseCounter;
        image = premise.image;
        break;
      case inputs.isInPublic && !allPremisesInNetwork:
        const premiseWithoutSite = premiseMessagesWithoutSite(
          _activeTool,
          vertices,
          premisesCount,
          premiseCounter
        );
        header = premiseWithoutSite.header;
        instruction = premiseWithoutSite.instruction;
        counter = premiseCounter;
        image = premiseWithoutSite.image;
        break;
      case !isNetworkConnected && connectionMethod === "manual":
        header = userGuidanceMessages["3"];
        const cable = cableMessages(
          _activeTool,
          vertices,
          networkHasCables,
          isFirstConnectedAssetCable
        );
        instruction = cable.instruction;
        image = cable.image;
        break;
      default:
        header = userGuidanceMessages["4"];
        const submit = submitMessages();
        instruction = submit.instruction;
        image = submit.image;
    }
  };

  getUserGuidanceMessages();

  return (
    <>
      <Alert
        variant="light"
        className="text-right m-0"
        style={{ maxWidth: "15rem", border: "2px solid black" }}
      >
        <h6>
          <span className="header-red guidance">{header.slice(0, header.length - counter.length)}</span>
          {counter.length > 0 && <span>&nbsp;</span>}
          <span className="header-black guidance">{counter}</span>
        </h6>
        <p className="content guidance">{instruction}</p>
        <div className="image-container">{image && <img src={image} className="img-fluid" alt={image} />}</div>
      </Alert>
    </>
  );
};

export default UserGuidance;
