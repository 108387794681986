import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectDebugging, updateDebug } from "./debuggingSlice";
import { ToggleButtonGroup, ToggleButton, Button } from "react-bootstrap";

const debugOptions = [
  {
    id: "1",
    value: "Marker Radius Intersections",
    text: "Marker Radius Intersections",
  },
  {
    id: "2",
    value: "Intersecting Segments",
    text: "Intersecting Segments",
  },
  {
    id: "3",
    value: "RAG Assets",
    text: "RAG assets within radius",
  },
  {
    id: "4",
    value: "LVAce Grid",
    text: "Show LVAce Grid",
  },
];

const Debugging = () => {
  const dispatch = useAppDispatch();
  const debugging = useAppSelector(selectDebugging);
  const { debug } = debugging;

  const handleChange = (val: string[]) => dispatch(updateDebug(val));
  const clear = () => dispatch(updateDebug([]));

  return (
    <div className="bg-light d-flex flex-column p-2" style={{border: '2px solid black'}}>
      <h6 className="text-center">Debugging Tools</h6>
      <ToggleButtonGroup
        type="checkbox"
        size="sm"
        value={debug}
        onChange={handleChange}
        className="mb-2"
        vertical
      >
        {debugOptions.map((y) => (
          <ToggleButton
            key={y.id}
            id={y.id}
            variant="outline-secondary"
            value={y.value}
          >
            <i
              className={`icon-${
                debug.includes(y.value) ? "check-solid" : "cross-solid"
              } mr-2`}
            ></i>
            {y.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Button variant="link" size="sm" onClick={() => clear()}>
        Clear
      </Button>
    </div>
  );
};

export default Debugging;
