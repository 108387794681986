import { useEffect, useMemo, useRef } from "react";
import { Polygon, useMap, useMapEvents } from "react-leaflet";
import ImageOverlayRotated from "./ImageOverlayRotated";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectSchematic,
  updateSchematicPositions,
  selectSchematics,
} from "./schematicsSlice";
import { Schematic } from "../../models/models";
import L, { LeafletEvent } from "leaflet";
import { removeTransformPathLayers } from "./helpers";
import { selectNetworkCreator } from "../networkCreator/networkCreatorSlice";
import { selectQuotationPlan } from "../quotationPlan/quotationPlanSlice";

interface ISchematicTool {
  schematic: Schematic;
  selectedObject: boolean;
  onClick: (schematic: Schematic) => void;
}

const SchematicTool = ({
  schematic,
  selectedObject,
  onClick,
}: ISchematicTool) => {
  const shapeRef = useRef(null);
  const imageOverlayRef = useRef(null);
  const map = useMap();

  const dispatch = useAppDispatch();

  const schematics = useAppSelector(selectSchematics);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const quotationPlan = useAppSelector(selectQuotationPlan);
  const networkCreatorActiveTool = networkCreator.activeTool;
  const quotationPlanActiveTool = quotationPlan.activeTool;
  const quotationPlanSelectedObject = quotationPlan.selectedObject;

  const enableTransform = (shape: any) => {
    if (shape != null) {
      if (shape.transform) {
        shape.transform.enable();
        shape.dragging.enable();
        shape.transform.setOptions({
          uniformScaling: schematic.uniformScaling,
          // edge handlers
          handlerOptions: {
            radius: 6,
            fillColor: "#ffffff",
            color: "#3388ff",
            fillOpacity: 1,
            weight: 2,
            opacity: 0.7,
            setCursor: true,
          },

          // rectangle
          boundsOptions: {
            weight: 2,
            opacity: 1,
            dashArray: [6, 6],
            fill: false,
            noClip: true,
          },

          // rotation handler
          rotateHandleOptions: {
            weight: 1,
            opacity: 1,
            setCursor: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    const shape: any = shapeRef.current;
    if (shape !== null) {
      shape.on("transformed", (e: any) => {
        onTransformed(e);
      });
      shape.on("dragstart", () => {
        shape.setStyle({
          opacity: 1,
        });
      });
      shape.on("dragend", () => {
        shape.setStyle({
          opacity: 0,
        });
      });
    }
  }, [shapeRef]);

  useEffect(() => {
    const shape = shapeRef.current;
    const imageOverlay: any = imageOverlayRef.current;
    if (shape !== null && imageOverlay !== null) {
      enableTransform(shape);

      imageOverlay.setStyle({
        opacity: schematic.opacity,
      });
    }
  }, [schematic.opacity, schematic.uniformScaling]);

  const onTransformed = (e: any) => {
    const imageOverlay: any = imageOverlayRef.current;
    if (imageOverlay !== null) {
      const newPositions: number[][][] = e.target
        .getLatLngs()
        .map((m: any) => m.map((n: any) => [n.lat, n.lng]));

      imageOverlay.reposition(
        newPositions[0][0],
        newPositions[0][1],
        newPositions[0][3]
      );

      dispatch(
        updateSchematicPositions({
          id: schematic.id,
          newPositions: newPositions[0],
        })
      );
    }
  };

  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletEvent) {
        if (
          quotationPlanActiveTool ||
          networkCreatorActiveTool ||
          quotationPlanSelectedObject
        ) {
          return;
        }
        const shape = shapeRef.current;
        if (shape != null) {
          L.DomEvent.stopPropagation(e);
          onClick(schematic);
        }
      },
    }),
    [
      quotationPlanActiveTool,
      networkCreatorActiveTool,
      quotationPlanSelectedObject,
    ]
  );

  useMapEvents({
    click() {
      if (schematics.selectedSchematic) {
        removeTransformPathLayers(map);
        dispatch(selectSchematic(undefined));
        //update overlay pane to back
        const overlayPane = document.querySelector(".leaflet-overlay-pane");
        overlayPane?.classList.remove("overlay-pane-to-front");
      } else return;
    },
  });

  return (
    <>
      <Polygon
        ref={shapeRef}
        positions={schematic.positions as [number, number][]}
        transform={selectedObject}
        draggable={selectedObject}
        pathOptions={{
          weight: 2,
          dashArray: "6, 6",
          opacity: 0,
          fillOpacity: 0,
        }}
        eventHandlers={eventHandlers}
      ></Polygon>
      <ImageOverlayRotated
        ref={imageOverlayRef}
        url={schematic.image}
        topLeft={schematic.positions[0]}
        topRight={schematic.positions[1]}
        bottomLeft={schematic.positions[3]}
        opacity={schematic.opacity}
        interactive={true}
      />
    </>
  );
};

export default SchematicTool;
