import debounce from "lodash.debounce";
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { useCallback, useRef } from "react";
import { EditorState } from "lexical";
import { DrawableAnnotation } from "../../../models/models";

function Placeholder() {
  return <div className="editor-placeholder">Enter text...</div>;
}

const editorConfig = {
  namespace: "MyEditor",
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error: Error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

interface IEditor {
  annotation: DrawableAnnotation;
  onChange: ({ html, lexical }: { html: string; lexical: string }) => void;
}

const Editor = ({ annotation, onChange }: IEditor) => {
  const ref = useRef(null);

  const handler = useCallback(debounce(_onChange, 500), []);

  function _onChange(e: EditorState) {
    const html = (ref.current as any).getElementsByClassName("editor-input")[0]
      .innerHTML;
    const lexical = JSON.stringify(e);
    onChange({ html, lexical });
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin annotation={annotation} />
        <div ref={ref} className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={handler} />
        </div>
      </div>
    </LexicalComposer>
  );
};

export default Editor;
