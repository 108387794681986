import bearing from "@turf/bearing";
import destination from "@turf/destination";
import { point, Units } from "@turf/helpers";
import { Position } from "geojson";
import L, { LatLngBoundsExpression } from "leaflet";
import { LatLngTuple, LeafletEvent } from "leaflet";
import { useMemo, useRef } from "react";
import { Polygon, Polyline } from "react-leaflet";
import { DrawableLine } from "../../../models/models";

interface ILine {
  line: DrawableLine;
  onClick: () => void;
}
const Line = ({ line, onClick }: ILine) => {
  const lineRef = useRef(null);

  const getArrowHead = (positions: Position[]) => {
    const point1 = point([positions[0][1], positions[0][0]]);
    const point2 = point([positions[1][1], positions[1][0]]);

    const _bearing = bearing(point1, point2);

    const _point = point([positions[1][1], positions[1][0]]);
    const distance = line.arrowheadSize;
    const options: { units: Units } = { units: "meters" };

    const coords: LatLngBoundsExpression = [];
    [0 + _bearing, -120 + _bearing, 120 + _bearing].forEach((bearing) => {
      if (!distance) return;
      const _destination = destination(_point, distance, bearing, options);
      coords.push([
        _destination.geometry.coordinates[1],
        _destination.geometry.coordinates[0],
      ]);
    });

    return coords;
  };

  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletEvent) {
        L.DomEvent.stopPropagation(e);
        const line = lineRef.current;
        if (line != null) {
          onClick();
        }
      },
    }),
    []
  );

  return (
    <>
      <Polyline
        ref={lineRef}
        positions={line.positions as LatLngTuple[]}
        pathOptions={{
          color: line.type === "duct-line" ? "#008000" : "#000000",
        }}
        dashArray={line.type === "duct-line" ? "2 4" : ""}
        eventHandlers={eventHandlers}
      ></Polyline>
      {line.type === "arrow-line" && (
        <Polygon
          positions={getArrowHead(line.positions)}
          pathOptions={{ color: "#000000" }}
          fillOpacity={1}
          eventHandlers={eventHandlers}
        ></Polygon>
      )}
    </>
  );
};

export default Line;
