import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { selectLayerToggle, updateLayers } from "./layerToggleSlice";
import { layerOptions } from "./constants";

const LayerToggle = () => {
  const dispatch = useAppDispatch();
  const layerToggle = useAppSelector(selectLayerToggle);
  const { layers } = layerToggle;

  const handleChange = (val: string[]) => dispatch(updateLayers(val));

  return (
    <div
      className="bg-light d-flex flex-column p-2"
      style={{ border: "2px solid black" }}
    >
      <h6 className="text-center">Layer Toggle</h6>
      <ToggleButtonGroup
        type="checkbox"
        size="sm"
        value={layers}
        onChange={handleChange}
        className="mb-2"
        vertical
      >
        {Object.values(layerOptions)
          .filter((f) => !f.ragLayer && f.visible) //show all layers that are NOT RAG layers and are enabled
          .map((opt) => (
            <ToggleButton
              key={opt.id}
              id={opt.id}
              variant="outline-secondary"
              value={opt.value}
            >
              <i
                className={`icon-${
                  layers.includes(opt.value) ? "eye" : "eye-blocked"
                } mr-2`}
              ></i>
              {opt.text}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default LayerToggle;
