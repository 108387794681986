import L from "leaflet";

export function pmtIcon(colour: string | undefined, size = 24): L.DivIcon {
  let ragColour: string;
  colour ? (ragColour = colour) : (ragColour = "red");

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="${size}" height="${size}">
  <path fill=${ragColour} d="M22.4,10.4C22.4,4.7,17.7,0,12,0S1.6,4.7,1.6,10.4c0,5.6,4.4,10.1,9.9,10.4v2.5c0,0.3,0.2,0.6,0.6,0.6 c0.4,0,0.6-0.2,0.6-0.6v-2.5C18.1,20.6,22.4,16,22.4,10.4L22.4,10.4z M12,19.1c-4.8,0-8.7-3.9-8.7-8.7S7.2,1.8,12,1.8 s8.7,3.9,8.7,8.7S16.8,19.1,12,19.1z M15,10.1l-2.4-0.8L14.3,6c0.1-0.2,0.1-0.5-0.2-0.6c-0.2-0.2-0.5-0.1-0.6,0.1l-4.9,4.9 c-0.1,0.1-0.2,0.3-0.2,0.5s0.2,0.3,0.3,0.3l2.4,0.8l-1.6,3.3c-0.1,0.2-0.1,0.5,0.2,0.6c0.2,0.2,0.5,0.1,0.6-0.1l4.9-4.9 c0.1-0.1,0.2-0.3,0.2-0.5C15.3,10.3,15.2,10.2,15,10.1L15,10.1z",
/>
  </svg>`;
  return L.divIcon({
    html: svg,
    iconSize: [size, size],
  });
}
