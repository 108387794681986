import { useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import pointToLineDistance from "@turf/point-to-line-distance";
import { lineString, point } from "@turf/helpers";
import distance from "@turf/distance";

import {
  setAppMode,
  selectApp,
  setCalculate,
  setEditMode,
  EditMode,
  setEditCableType,
  updateDialog,
} from "../../app/appSlice";
import {
  saveEditableNetwork,
  selectNetworkCreator,
} from "../../features/networkCreator/networkCreatorSlice";
import { setActiveTool } from "../../features/networkCreator/networkCreatorSlice";
import { selectExistingNetwork } from "../../features/existingNetwork/existingNetworkSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ExistingNetworkAsset, NetworkAsset } from "../../models/models";
import { Feature, UserRole } from "../../models/enums";
import Toolbar from "../../components/toolbar/Toolbar";
import { FeatureSwitch } from "../../components/featureSwitch/featureSwitch";

const EditToolbar = () => {
  const [showCableOptions, setShowCableOptions] = useState(false);
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const networkCreator = useAppSelector(selectNetworkCreator);
  const existingNetwork = useAppSelector(selectExistingNetwork);

  const {
    calculate,
    editMode,
    inputs: { userRole },
  } = app;
  const { editableNetwork } = networkCreator;
  const { conductingSections, overheadPoles } = existingNetwork;

  /**
   * Finds the existing network asset (cable or pole) that a cable is connected to.
   * @param cable A cable that is connected to the existing network.
   * @returns The Existing Network Asset that the cable is connected to.
   */
  const getExistingAsset = (cable: NetworkAsset) => {
    const existingAsset = [
      cable.firstConnectedAsset,
      cable.secondConnectedAsset,
    ].find(
      (p) => p?.startsWith("AcLineSegment") || p?.startsWith("JunctionIsPole")
    );
    return [...conductingSections, ...overheadPoles].find(
      (asset) => asset.id === existingAsset
    );
  };

  /**
   * Converts cable geometries to a '@turf' lineString
   * @param cable A cable to be converted.
   * @returns A line string from '@turf' or undefined
   */
  const cableToTurfLineString = (
    cable: NetworkAsset | ExistingNetworkAsset
  ) => {
    const hasPolyGeometry = cable.polyGeometry?.map((poly: any) => [
      poly.lng,
      poly.lat,
    ]);
    return hasPolyGeometry ? lineString(hasPolyGeometry) : hasPolyGeometry;
  };

  /**
   * Ensures all joints are connected by comparing the editable joints to the editable joints and existing network and seeing if they are <5mm away from each other.
   * @returns True if all of the editable joints are connected to two assets.
   */
  const validateStudy = (): boolean => {
    // Find the joints and cables from the editableNetwork
    const joints = editableNetwork.filter((n) => n.name === "joint");
    const cables = editableNetwork.filter((n) => n.type === "cable");

    // Populate existingAssets with the cables that the editable cables are connected to
    const existingAssets: ExistingNetworkAsset[] = [];
    cables.forEach((cable) => {
      const existingAsset = getExistingAsset(cable);
      if (existingAsset) {
        const index = existingAssets.findIndex(
          (object) => object.id === existingAsset.id
        );

        //Only add a cable to existingAssets if it is not already in the array
        if (index === -1) {
          existingAssets.push(existingAsset);
        }
      }
    });

    if (userRole === UserRole.developer) {
      const jointTable: {
        joint: string;
        asset: string;
        distance: number | null;
      }[] = [];

      // For each joint in the editable network
      joints.forEach((joint) => {
        if (joint?.markerGeometry) {
          // Create a '@turf' point from the marker geometry
          const pt = point([
            joint?.markerGeometry?.lng,
            joint?.markerGeometry?.lat,
          ]);
          // For each editable cable, existing cable, and existing pole that the editable network is connected to...
          [...cables, ...existingAssets].forEach((asset) => {
            let _distance = null;
            // If the asset is a cable, calculate the distance from the marker point
            if (asset.polyGeometry) {
              const line = cableToTurfLineString(asset);
              if (line) {
                _distance = pointToLineDistance(pt, line, {
                  units: "millimeters",
                });
              }
            }
            // If the asset is a pole, calculate the distance between the joint and pole
            if (asset.markerGeometry) {
              const pt2 = point([
                asset?.markerGeometry?.lng,
                asset?.markerGeometry?.lat,
              ]);
              if (pt2) {
                _distance = distance(pt, pt2, {
                  units: "millimeters",
                });
              }
            }
            // Add the joint, the asset, and the distance between them to a table
            jointTable.push({
              joint: joint.id,
              asset: asset.id,
              distance: _distance,
            });
          });
        }
      });
      // log the table
      console.table(jointTable);
    }

    // For every joint, determine if any of them are not connected to two assets
    const validateCable = joints.some((joint) => {
      // set a count to keep track of how many assets a joint is connected to
      let count = 0;
      if (joint?.markerGeometry) {
        // Create a '@turf' point from the marker geometry
        const pt = point([
          joint?.markerGeometry?.lng,
          joint?.markerGeometry?.lat,
        ]);
        // For each editable cable, existing cable, and existing pole that the editable network is connected to...
        [...cables, ...existingAssets].forEach((asset) => {
          // If the asset is a cable, calculate the distance from the marker point
          if (asset.polyGeometry) {
            const line = cableToTurfLineString(asset);
            if (line) {
              const _distance = pointToLineDistance(pt, line, {
                units: "millimeters",
              });
              // If the distance from the joint to the line is <5mm, increment count 1
              if (_distance < 5) {
                count++;
              }
            }
          }
          // If the asset is a pole, calculate the distance between the joint and pole
          if (asset.markerGeometry) {
            const pt2 = point([
              asset?.markerGeometry?.lng,
              asset?.markerGeometry?.lat,
            ]);
            if (pt2) {
              const _distance = distance(pt, pt2, {
                units: "millimeters",
              });
              // If the distance from the joint to the pole is <5mm, increment count 1
              if (_distance < 5) {
                count++;
              }
            }
          }
        });
      }
      // Return true if the joint is not connected to two assets.
      return count < 2;
    });
    // Return true if all of the editable joints are connected to two assets
    return !validateCable;
  };

  const save = () => {
    const validatedStudy = validateStudy();
    if (validatedStudy) {
      dispatch(saveEditableNetwork());
      dispatch(setCalculate(calculate + 1));
      exit();
    } else {
      dispatch(
        updateDialog({
          props: [],
          type: "warning",
          className: "warning",
          dismissLabel: "OK",
          messages: [
            {
              description: "Please make sure all joints are connected",
            },
          ],
        })
      );
    }
  };

  const exit = () => {
    dispatch(setAppMode(undefined));
    dispatch(setEditMode(undefined));
    dispatch(setEditCableType(undefined));
    dispatch(setActiveTool({ activeTool: "", ngedId: "" }));
  };

  const toggleCable = () => {
    setShowCableOptions(!showCableOptions);
  };

  const cable = (cableType: string) => {
    dispatch(setEditCableType(cableType));
    dispatch(
      setEditMode(
        editMode === EditMode.drawCable ? undefined : EditMode.drawCable
      )
    );
    dispatch(
      setActiveTool({
        activeTool: editMode === undefined ? "cable" : "",
        ngedId: "",
      })
    );
  };

  return (
    <Toolbar canUndoRedo={true}>
      <ButtonGroup>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="save">Save</Tooltip>}
        >
          <Button variant="dark" onClick={() => save()}>
            <i className="icon-save-solid"></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="exit">Exit</Tooltip>}
        >
          <Button variant="danger" onClick={() => exit()}>
            <i className="icon-cross-solid"></i>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
      <FeatureSwitch feature={Feature.editMainsCable} userRole={userRole}>
        <ButtonGroup className="mt-2">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="drawCable">Draw Cable</Tooltip>}
          >
            <Button
              active={editMode === EditMode.drawCable}
              variant="dark"
              className="draw-cable"
              onClick={() => toggleCable()}
            >
              <i className="icon-cable"></i>
            </Button>
          </OverlayTrigger>
          {showCableOptions && (
            <ButtonGroup className="mt-2">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="drawCable">Draw Cable</Tooltip>}
              >
                <Button
                  active={editMode === EditMode.drawCable}
                  variant="dark"
                  className="draw-cable-select"
                  onClick={() => cable("Mains 3")}
                >
                  <span>WF95</span>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="drawCable">Draw Cable</Tooltip>}
              >
                <Button
                  active={editMode === EditMode.drawCable}
                  variant="dark"
                  className="draw-cable-select"
                  onClick={() => cable("Mains 1")}
                >
                  <span>WF300</span>
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          )}
        </ButtonGroup>
      </FeatureSwitch>
    </Toolbar>
  );
};

export default EditToolbar;
