import { Map } from "leaflet";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ratioToZoomLevel } from "../utils/mapScale";
import {
  latLngToGridRef,
  polygonCentroidToLatLng,
} from "../utils/convertToSetView";
import { selectNetworkCreator } from "../features/networkCreator/networkCreatorSlice";
import useScreenshot from "./useScreenshot";
import {
  addQuotationPlanLegend,
  fileToBase64,
  fileToUrl,
  getBannerSvg,
} from "../services/screenshotService";
import { selectApp, setTakingQuotationScreenshot } from "../app/appSlice";

const DEFAULT_SCALE = 750;

export const useQuotationPlanScreenshot = (map: Map) => {
  const dispatch = useAppDispatch();
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { inputs } = useAppSelector(selectApp);
  const { author, searchValue, studyTitle } = inputs;
  const { network } = networkCreator;
  const { takeScreenshot } = useScreenshot();

  const generateQuotationPlanScreenshot = async () => {
    dispatch(setTakingQuotationScreenshot(true));
    const zoomSize = ratioToZoomLevel(DEFAULT_SCALE);
    map.setView(polygonCentroidToLatLng(network), zoomSize);
    const gridRef = latLngToGridRef(map.getCenter());

    const quotationPlanScreenshot = await takeScreenshot(map);

    const quotationPlanSvg = await getBannerSvg(
      author,
      searchValue,
      DEFAULT_SCALE,
      gridRef,
      studyTitle
    );
    const bannerUrl = await fileToUrl(quotationPlanSvg);
    const bannerUrlBase64 = await fileToBase64(bannerUrl);

    const quotationPlan = await addQuotationPlanLegend(
      // @ts-ignore
      quotationPlanScreenshot,
      bannerUrlBase64
    );

    dispatch(setTakingQuotationScreenshot(false));

    return quotationPlan;
  };

  return { generateQuotationPlanScreenshot };
};
