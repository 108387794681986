import { Polygon, useMapEvents } from "react-leaflet";
import { v4 as uuid } from "uuid";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectNetworkCreator } from "../networkCreator/networkCreatorSlice";
import { setOSFeature, selectOSFeatures, OSFeature } from "./osFeaturesSlice";
import { convertCoords, TransformFormat } from "../../utils/convertCoords";
import { useFeatureCollection } from "../../hooks/useFeatureCollection";
import { FeatureType } from "../../models/enums";

const OSFeatures = () => {
  const { getFeatureCollection } = useFeatureCollection();
  const networkCreator = useAppSelector(selectNetworkCreator);
  const { activeTool, groundTypes } = networkCreator;
  const { osFeature, osFeatures } = useAppSelector(selectOSFeatures);
  const dispatch = useAppDispatch();

  useMapEvents({
    click(e) {
      if (!activeTool) {
        const featureCollection = getFeatureCollection(
          e.latlng,
          FeatureType.point,
          groundTypes
        );

        const reversedFeatureCollection = convertCoords(
          featureCollection,
          TransformFormat.reverselatlng
        );
        const features: OSFeature[] = reversedFeatureCollection.features.map(
          (feature: OSFeature) => ({
            id: uuid(),
            geometry: feature.geometry,
            properties: feature.properties,
            type: feature.type,
          })
        );
        dispatch(setOSFeature(features[0]));
      }
    },
  });

  return (
    <>
      {osFeature && (
        <Polygon
          key={osFeature.id}
          weight={1}
          smoothFactor={0}
          positions={[osFeature.geometry.coordinates] as L.LatLng[]}
        />
      )}
      {osFeatures.length > 0 &&
        osFeatures.map((feature: OSFeature) => (
          <Polygon
            key={feature.id}
            weight={1}
            smoothFactor={0}
            positions={[feature.geometry.coordinates] as L.LatLng[]}
          />
        ))}
    </>
  );
};

export default OSFeatures;
