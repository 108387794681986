import axios from "axios";
import { Inputs } from "../app/appSlice";
import { NetworkCreatorState } from "../features/networkCreator/networkCreatorSlice";
import { appConfig } from "./configService";
import { StateWithHistory } from "redux-undo";
import { UserRole } from "../models/enums";

export type StudySaveFile = {
  inputs: Inputs;
  networkCreatorState: StateWithHistory<NetworkCreatorState>;
};

export type LoadStudyAdapter = (input: Inputs) => Promise<StudySaveFile | null>;

export type SaveStudyAdapter = (
  inputs: Inputs,
  networkCreatorState: StateWithHistory<NetworkCreatorState>
) => Promise<void>;

export const loadStudyFromInputs: LoadStudyAdapter = async (inputs: Inputs) => {
  const existingStudy = inputs.existingStudy;

  if (!existingStudy) {
    return null;
  }

  const modifiedInputs: Inputs = {
    ...existingStudy.inputs,
    userRole: UserRole.developer,
  };

  const modifiedNetwork: NetworkCreatorState = {
    network: existingStudy.network,
    editableNetwork: existingStudy.editableNetwork,
    activeTool: undefined,
    ngedId: undefined,
    vertices: [],
    currentSegments: [],
    existingConnectedAssets: [],
    radii: existingStudy.radii,
    topographies: existingStudy.topographies,
    groundTypes: existingStudy.groundTypes,
    ragConductingSectionsAndPoles: existingStudy.ragConductingSectionsAndPoles,
    intersectingSegments: existingStudy.intersectingSegments,
    lvAceGrid: undefined,
    connectionMethod: "manual",
  };

  return {
    inputs: modifiedInputs,
    networkCreatorState: {
      past: [],
      present: modifiedNetwork,
      future: [],
    },
  };
};

export const loadStudyFromWebEnquiryReference: LoadStudyAdapter = async (
  inputs: Inputs
) => {
  const webEnquiryReference = inputs.webEnquiryRef;

  try {
    const saved = await axios.get<LoadStudyResponse>(
      `${appConfig.apiBaseUrl}/v1/study/load/${webEnquiryReference}`
    );
    const stringStudy = saved.data.study;

    if (!stringStudy) {
      return null;
    }

    const restored = JSON.parse(stringStudy) as StudySaveFile;

    return {
      inputs: restored.inputs,
      networkCreatorState: {
        ...restored.networkCreatorState,
      },
    };
  } catch {
    return null;
  }
};

type LoadStudyResponse = {
  reference: string;
  study: string | null;
  dateCreated: string | null;
  isNew: boolean;
  messages: string[] | null;
};

/**
 *
 * @param inputs A set of inputs, could be new, existing JSON upload or webEnquiryRef Load
 * @returns null if it's a new study, otherwise an object containing inputs and network creator state
 */
export const getStudyLoader = (inputs: Inputs): LoadStudyAdapter | null => {
  if (inputs.isNewStudy) {
    return null;
  }

  if (inputs.existingStudy) {
    return loadStudyFromInputs;
  }

  if (inputs.webEnquiryRef) {
    return loadStudyFromWebEnquiryReference;
  }

  return null;
};

export const saveStudyToWebEnquiryReference: SaveStudyAdapter = async (
  inputs: Inputs,
  networkCreatorState: StateWithHistory<NetworkCreatorState>
) => {
  const abridgedState: StateWithHistory<NetworkCreatorState> = {
    ...networkCreatorState,
    past: networkCreatorState.past.map((p) => ({
      ...p,
      groundTypes: [],
      topographies: [],
    })),
    present: { ...networkCreatorState.present, groundTypes: [] },
    future: networkCreatorState.future.map((p) => ({
      ...p,
      groundTypes: [],
      topographies: [],
    })),
    _latestUnfiltered: networkCreatorState._latestUnfiltered && {
      ...networkCreatorState._latestUnfiltered,
      groundTypes: [],
      topographies: [],
    },
  };

  const saveObject: StudySaveFile = {
    inputs: inputs,
    networkCreatorState: abridgedState,
  };

  if (inputs.webEnquiryRef !== "") {
    const requestBody = {
      reference: inputs.webEnquiryRef,
      study: JSON.stringify(saveObject),
    };

    await axios.post(`${appConfig.apiBaseUrl}/v1/study/save`, requestBody);
  }
};
