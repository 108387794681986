import { Position } from "geojson";
import L, { LatLngTuple } from "leaflet";
import { LeafletEvent } from "leaflet";
import { useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, useMap } from "react-leaflet";
import { LatLngObj } from "../../models/models";
import { offsetPosition } from "../../utils/offsetPosition";

interface IDeleteMarker {
  coord: LatLngObj | Position;
  onDeleteMarker: (e: LeafletEvent) => void;
}
const DeleteMarker = ({ coord, onDeleteMarker }: IDeleteMarker) => {
  const map = useMap();
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      click(e: LeafletEvent) {
        const marker = markerRef.current;
        if (marker != null) {
          onDeleteMarker(e);
        }
      },
    }),
    []
  );

  const styleIcon = () => {
    const badge = L.divIcon({
      className: "countMarker",
      html: ReactDOMServer.renderToString(
        <h1
          className={`badge rounded-pill bg-danger border text-light p-1`}
          style={{ fontSize: "100%" }}
        >
          <i className="icon-cross-solid"></i>
        </h1>
      ),
    });
    return badge;
  };

  return (
    <Marker
      icon={styleIcon()}
      eventHandlers={eventHandlers}
      position={offsetPosition(map, coord as LatLngTuple, 15, 20)}
      ref={markerRef}
      zIndexOffset={9999}
    />
  );
};

export default DeleteMarker;
