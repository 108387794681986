import { LatLngObj } from "../../models/models";
import userGuidanceMessages from "./userGuidanceMessages.json";

import drawSiteBoundary from "../../img/1-draw-site-boundary.gif";
import drawCabinet from "../../img/2-draw-cabinet.gif";
import drawExistingPremise from "../../img/2-draw-existing-premise.gif";
import drawNewPremise from "../../img/2-draw-new-premise.gif";
import submit from "../../img/3-submit.gif";

const newPremises = ["new-domestic", "new-non-domestic", "new-mixed"];
const existingPremises = [
  "existing-domestic",
  "existing-non-domestic",
  "existing-mixed",
];

let instruction = "";
let image = "";
let header = "";

export const siteMessages = (activeTool: string, vertices: LatLngObj[]) => {
  switch (true) {
    case !activeTool:
      instruction = userGuidanceMessages["1.1"];
      break;
    case activeTool === "site" && vertices.length < 1:
      instruction = userGuidanceMessages["1.2"];
      break;
    case activeTool === "site" && vertices.length < 3:
      instruction = userGuidanceMessages["1.3"];
      break;
    case activeTool === "site" && vertices.length > 2:
      instruction = userGuidanceMessages["1.4"];
      break;
    default:
      instruction = "";
  }
  return { instruction, image: drawSiteBoundary };
};

export const premiseMessages = (
  activeTool: string,
  vertices: LatLngObj[],
  premisesCount: number,
  premiseCounter: string
) => {
  switch (true) {
    case !activeTool && premisesCount < 1:
      header = `${userGuidanceMessages["2.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.1.1"];
      image = "";
      break;
    case !activeTool && premisesCount > 0:
      header = `${userGuidanceMessages["2.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.1.2"];
      image = "";
      break;
    case newPremises.includes(activeTool) && vertices.length < 1:
      header = `${userGuidanceMessages["2.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.2"];
      image = drawNewPremise;
      break;
    case newPremises.includes(activeTool) && vertices.length < 3:
      header = `${userGuidanceMessages["2.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.3"];
      image = drawNewPremise;
      break;
    case newPremises.includes(activeTool) && vertices.length > 2:
      header = `${userGuidanceMessages["2.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.4"];
      image = drawNewPremise;
      break;
    case existingPremises.includes(activeTool):
      header = `${userGuidanceMessages["2.0.x"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.2"];
      image = drawExistingPremise;
      break;
    case activeTool === "meter":
      header = `${userGuidanceMessages["2"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.3.1"];
      image = drawExistingPremise;
      break;
    case activeTool === "cabinet":
      header = `${userGuidanceMessages["2.x"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.3.2"];
      image = drawCabinet;
      break;
    default:
      instruction = "";
      header = "";
  }
  return { header, instruction, image };
};

export const premiseMessagesWithoutSite = (
  activeTool: string,
  vertices: LatLngObj[],
  premisesCount: number,
  premiseCounter: string
) => {
  switch (true) {
    case !activeTool && premisesCount < 1:
      header = `${userGuidanceMessages["2.a.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.1.1.a"];
      image = "";
      break;
    case !activeTool && premisesCount > 0:
      header = `${userGuidanceMessages["2.a.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.1.2.a"];
      image = "";
      break;
    case newPremises.includes(activeTool) && vertices.length < 1:
      header = `${userGuidanceMessages["2.a.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.2.a"];
      image = drawNewPremise;
      break;
    case newPremises.includes(activeTool) && vertices.length < 3:
      header = `${userGuidanceMessages["2.a.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.3.a"];
      image = drawNewPremise;
      break;
    case newPremises.includes(activeTool) && vertices.length > 2:
      header = `${userGuidanceMessages["2.a.0"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.1.4.a"];
      image = drawNewPremise;
      break;
    case existingPremises.includes(activeTool):
      header = `${userGuidanceMessages["2.a.0.x"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.2.a"];
      image = drawExistingPremise;
      break;
    case activeTool === "meter":
      header = `${userGuidanceMessages["2.a"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.3.1.a"];
      image = drawExistingPremise;
      break;
    case activeTool === "cabinet":
      header = `${userGuidanceMessages["2.a.x"]} ${premiseCounter}`;
      instruction = userGuidanceMessages["2.3.2.a"];
      image = drawCabinet;
      break;
    default:
      instruction = "";
      header = "";
  }
  return { header, instruction, image };
};

export const cableMessages = (
  activeTool: string,
  vertices: LatLngObj[],
  networkHasCables: boolean,
  isFirstConnectedAssetCable: boolean
) => {
  switch (true) {
    case !activeTool:
      instruction = userGuidanceMessages["3.1"];
      break;
    case activeTool === "cable" && vertices.length < 1 && !networkHasCables:
      instruction = userGuidanceMessages["3.2.1"];
      break;
    case activeTool === "cable" && vertices.length < 1 && networkHasCables:
      instruction = userGuidanceMessages["3.2.2"];
      break;
    case activeTool === "cable" &&
      vertices.length > 0 &&
      isFirstConnectedAssetCable:
      instruction = userGuidanceMessages["3.3.1"];
      break;
    case activeTool === "cable" &&
      vertices.length > 0 &&
      !isFirstConnectedAssetCable &&
      !networkHasCables:
      instruction = userGuidanceMessages["3.3.2"];
      break;
    case activeTool === "cable" &&
      vertices.length > 0 &&
      !isFirstConnectedAssetCable &&
      networkHasCables:
      instruction = userGuidanceMessages["3.3.3"];
      break;
    default:
      instruction = "";
  }
  return { instruction, image: "" };
};

export const submitMessages = () => {
  instruction = userGuidanceMessages["4.1"];
  return { instruction, image: submit };
};
