import {
  Feature,
  featureCollection,
  lineString,
  point,
  polygon,
} from "@turf/helpers";
import centroid from "@turf/centroid";
import { NetworkAsset } from "../models/models";
import get from "axios";
import { GT_WGS84, GT_OSGB } from "./geoTools2";
import { xYToLatLng } from "./xYToLatLng";
import L, { LatLng } from "leaflet";
import { appConfig } from "../services/configService";

const { osApiKey } = appConfig;

export const postcodeToLatLng = async (searchValue: string) => {
  const response = await get(
    `https://api.os.uk/search/names/v1/find?query=${searchValue}&key=${osApiKey}&maxresults=1`
  );
  const x: number = response.data.results[0].GAZETTEER_ENTRY.GEOMETRY_X;
  const y: number = response.data.results[0].GAZETTEER_ENTRY.GEOMETRY_Y;
  return L.latLng(xYToLatLng(x, y));
};

export const latLngToGridRef = (latLng: LatLng) => {
  const wgs84 = new GT_WGS84();
  wgs84.setDegrees(latLng.lat, latLng.lng);
  const osgb = wgs84.getOSGB();
  return osgb.getGridRef(6);
};

export const gridRefToLatLng = (searchValue: string) => {
  const osgb = new GT_OSGB();
  var prefixExpression =
    "^((([sS]|[nN])[a-hA-Hj-zJ-Z])|(([tT]|[oO])[abfglmqrvwABFGLMQRVW])|([hH][l-zL-Z])|([jJ][lmqrvwLMQRVW]))$";
  var gridRef = searchValue.replace(/ |,/g, "");
  if (gridRef.substring(0, 2).match(prefixExpression)) {
    osgb.parseGridRef(gridRef.toUpperCase());
  } else {
    osgb.setGridCoordinates(
      gridRef.substring(0, gridRef.length / 2),
      gridRef.substring(gridRef.length / 2)
    );
  }

  const wgs84 = osgb.getWGS84();
  return L.latLng(wgs84.latitude, wgs84.longitude);
};

export const latLngToLatLng = (searchValue: string) => {
  const latLngArr = searchValue.split(",");
  const lat = parseFloat(latLngArr[0]);
  const lng = parseFloat(latLngArr[1]);
  return L.latLng(lat, lng);
};

const networkToFeatureCollection = (network: NetworkAsset[]) => {
  let features: Feature[] = [];
  network.forEach((n: NetworkAsset) => {
    if (n.polyGeometry && n.polyGeometry.length) {
      let poly = n.polyGeometry.map((y: any) => [y.lng, y.lat]);
      if (n.type === "site") {
        features = [...features, polygon([[...poly, poly[0]]])];
      } else {
        features = [...features, lineString(poly)];
      }
    }
    if (n.markerGeometry) {
      let marker = [n.markerGeometry.lng, n.markerGeometry.lat];
      point(marker);
      features = [...features, point(marker)];
    }
  });
  return featureCollection([...features]);
};

export const polygonCentroidToLatLng = (network: NetworkAsset[]) => {
  const collection = networkToFeatureCollection(network);
  const _centroid = centroid(collection);
  return L.latLng(
    _centroid.geometry.coordinates[1],
    _centroid.geometry.coordinates[0]
  );
};
