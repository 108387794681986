import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import undoable, { ActionTypes, excludeAction } from "redux-undo";
import appReducer from "../app/appSlice";
import debuggingReducer from "../features/debugging/debuggingSlice";
import existingNetworkReducer from "../features/existingNetwork/existingNetworkSlice";
import layerToggleReducer from "../features/layerToggle/layerToggleSlice";
import networkCreatorReducer, {
  addGroundTypes,
  addTopographies,
  setActivePremise,
  setActiveTool,
} from "../features/networkCreator/networkCreatorSlice";
import osFeaturesReducer from "../features/osFeatures/osFeaturesSlice";
import selectMapFeaturesReducer from "../features/selectMapFeatures/selectMapFeaturesSlice";
import quotationPlanReducer from "../features/quotationPlan/quotationPlanSlice";
import schematicsReducer from "../features/schematic/schematicsSlice";
import stepsReducer from "../features/steps/stepsSlice";
import { saveMiddleware } from "../middleware/saveMiddleware";

const reducer = combineReducers({
  app: appReducer,
  steps: stepsReducer,
  networkCreator: undoable(networkCreatorReducer, {
    clearHistoryType: ActionTypes.CLEAR_HISTORY,
    syncFilter: true,
    filter: excludeAction([
      addTopographies.type,
      addGroundTypes.type,
      setActivePremise.type,
      setActiveTool.type,
    ]),
  }),
  existingNetwork: existingNetworkReducer,
  osFeatures: osFeaturesReducer,
  debugging: debuggingReducer,
  quotationPlan: quotationPlanReducer,
  schematics: schematicsReducer,
  layerToggle: layerToggleReducer,
  selectMapFeatures: selectMapFeaturesReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).prepend(saveMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
