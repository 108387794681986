import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "../models/enums";
import { ConnectionPoint, Message } from "../models/models";
import type { RootState } from "./store";
import { NetworkCreatorState } from "../features/networkCreator/networkCreatorSlice";

export interface Inputs {
  webEnquiryRef: string;
  company: string;
  isNewStudy: boolean;
  connectionPoints: ConnectionPoint[];
  searchType: string;
  searchValue: string;
  isSiteExcavationCostIncluded: boolean;
  isInPublic: boolean;
  totalkVA: number;
  userRole: UserRole;
  studyTitle: string;
  author: string;
  existingStudy?: NetworkCreatorState & { inputs: Inputs };
  isCompletedStudy: boolean;
  lvAceComplete: boolean;
}

interface Dialog {
  props?: any[];
  type?: string;
  className?: string;
  affirmLabel?: string;
  dismissLabel?: string;
  messages?: { description: string }[];
}

export enum ConsoleOption {
  userGuidance,
  debugger,
  selectMapFeatures,
  edit,
  quotationPlan,
  layerToggle,
  schematic,
}

export enum AppMode {
  loading = "loading",
  edit = "edit",
  screenshot = "screenshot",
  quotationPlan = "site-plan",
  exitQuotationPlan = "exit-site-plan",
  saveStudyResults = "save-study-results",
}

export enum EditMode {
  drawCable = "draw-cable",
}

// Define a type for the slice state
export interface AppState {
  inputs: Inputs;
  dialog: Dialog;
  mouseOverControl: boolean;
  consoleMode: ConsoleOption | undefined;
  appMode: AppMode | undefined;
  takingQuotationScreenshot: boolean;
  calculate: number;
  submit: boolean;
  lvAceMessages: Message[];
  errorMsgs: Message[];
  lvAceActive: boolean;
  editMode: EditMode | undefined;
  screenshot: boolean;
  fullScreen: boolean;
  editCableType: string | undefined;
  showLayerToggle: boolean;
  isReadOnly: boolean;
}

// Define the initial state using that type
export const initialState: AppState = {
  inputs: {
    connectionPoints: [],
    webEnquiryRef: "",
    searchType: "Postcode",
    searchValue: "",
    company: "",
    isNewStudy: true,
    isSiteExcavationCostIncluded: false,
    totalkVA: 0,
    isInPublic: false,
    userRole: UserRole.public,
    studyTitle: "",
    author: "",
    isCompletedStudy: false,
    lvAceComplete: false,
  },
  dialog: {},
  mouseOverControl: true,
  consoleMode: undefined,
  appMode: undefined,
  takingQuotationScreenshot: false,
  calculate: 0,
  submit: false,
  lvAceMessages: [],
  errorMsgs: [],
  lvAceActive: false,
  editMode: undefined,
  screenshot: false,
  fullScreen: false,
  editCableType: undefined,
  showLayerToggle: true,
  isReadOnly: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCalculate: (state, action: PayloadAction<number>) => {
      state.calculate = action.payload;
    },
    setSubmit: (state, action: PayloadAction<boolean>) => {
      state.submit = action.payload;
    },
    setConsoleMode: (
      state,
      action: PayloadAction<ConsoleOption | undefined>
    ) => {
      state.consoleMode = action.payload;
    },
    setAppMode: (state, action: PayloadAction<AppMode | undefined>) => {
      state.appMode = action.payload;
    },
    setTakingQuotationScreenshot: (state, action: PayloadAction<boolean>) => {
      state.takingQuotationScreenshot = action.payload;
    },
    updateDialog: (state, action) => {
      state.dialog = action.payload;
    },
    setInputs: (state, action: PayloadAction<Inputs>) => {
      state.inputs = action.payload;
    },
    setMouseOverControl: (state, action: PayloadAction<boolean>) => {
      state.mouseOverControl = action.payload;
    },
    setLVAceActive: (state, action: PayloadAction<boolean>) => {
      state.lvAceActive = action.payload;
    },
    setEditMode: (state, action: PayloadAction<EditMode | undefined>) => {
      state.editMode = action.payload;
    },
    setScreenshot: (state, action: PayloadAction<boolean>) => {
      state.screenshot = action.payload;
    },
    setFullScreen: (state, action: PayloadAction<boolean>) => {
      state.fullScreen = action.payload;
    },
    setEditCableType: (state, action: PayloadAction<string | undefined>) => {
      state.editCableType = action.payload;
    },
    setLVAceMessages: (state, action) => {
      state.lvAceMessages = action.payload;
    },
    setShowLayerToggle: (state, action: PayloadAction<boolean>) => {
      state.showLayerToggle = action.payload;
    },
    setIsReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload;
    },
    setIsLvAceComplete: (state, action: PayloadAction<boolean>) => {
      state.inputs.lvAceComplete = action.payload;
    },
  },
});

export const {
  setLVAceMessages,
  setCalculate,
  setSubmit,
  setConsoleMode,
  setAppMode,
  setTakingQuotationScreenshot,
  updateDialog,
  setInputs,
  setMouseOverControl,
  setLVAceActive,
  setEditMode,
  setScreenshot,
  setFullScreen,
  setEditCableType,
  setShowLayerToggle,
  setIsReadOnly,
  setIsLvAceComplete,
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;
