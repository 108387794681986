import L from "leaflet";

export const iconMarker = (
  size: number,
  classes: string,
  asset: string = ""
) => {
  const icon = L.divIcon({
    className: `iconMarker ${
      asset === "cabinet"
        ? "roundedIconMarker"
        : asset === "joint"
        ? "squareIconMarker"
        : asset === "pot_end"
        ? "triangleIconMarker"
        : ""
    } ${classes || ""}`,
    iconSize: L.point(size * 16, size * 16),
  });
  return icon;
};
