import { useEffect, Fragment } from "react";
import { Polyline, useMap, useMapEvents } from "react-leaflet";
import TextPath from "react-leaflet-textpath";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLayerToggle } from "../layerToggle/layerToggleSlice";
import {
  EPSGType,
  layerOptions,
  layerOptionValues,
} from "../layerToggle/constants";
import {
  selectExistingNetwork,
  setSelectedAsset,
  updateConductingSections,
  updateEhvs,
  updateHvs,
  updateOverheadPoles,
  updateServiceCables,
  updateThirdParties,
  updateLVMains,
  updateWayleaves,
  updatePowerTransformers,
  updateAcceptedToConnects,
} from "./existingNetworkSlice";
import GeometryTypeFilter from "./GeometryTypeFilter";
import { AppMode, selectApp } from "../../app/appSlice";
import { OverheadPole } from "./OverheadPole";
import { PowerTransformer } from "./powerTransformer/PowerTransformer";
import { TransformerProperties } from "../../models/featureProperties";
import { ExistingNetworkAsset } from "../../models/models";
import { useGeoServer } from "../../hooks/useGeoServer";
import { ATCRequest } from "./acceptedToConnect/ATCRequest";
import { isFeatureEnabled } from "../../services/featureSwitchService";
import {
  Feature,
  PowerTransformerMounting,
  UserRole,
} from "../../models/enums";

interface IExistingNetworkAssets {
  name: string;
  urls: string[];
  layerType: EPSGType;
}

const ExistingNetworkAssets = ({
  name,
  urls,
  layerType,
}: IExistingNetworkAssets) => {
  const map = useMap();
  const { appMode, inputs } = useAppSelector(selectApp);
  const { userRole, totalkVA, connectionPoints } = inputs;
  const existingNetwork = useAppSelector(selectExistingNetwork);
  const {
    conductingSections,
    overheadPoles,
    powerTransformers,
    ehvs,
    hvs,
    thirdparties,
    lvmains,
    wayleaves,
    serviceCables,
    acceptedToConnects,
  } = existingNetwork;
  const toggles = useAppSelector(selectLayerToggle);
  const { getLayer } = useGeoServer();

  const showCableLabels = toggles.layers.includes(
    layerOptionValues.mainsLabels
  );
  const showEhvs = toggles.layers.includes(layerOptions.ehv.value);
  const showHvs = toggles.layers.includes(layerOptions.hv.value);
  const showThirdParties = toggles.layers.includes(
    layerOptions.thirdparties.value
  );
  const showLVMains = toggles.layers.includes(layerOptions.lvmains.value);
  const showWayleaves = toggles.layers.includes(layerOptions.wayleaves.value);
  const showServiceCables = toggles.layers.includes(layerOptions.lv.value);
  const showAcceptedToConnect =
    toggles.layers.includes(layerOptions.acceptedToConnect.value) &&
    isFeatureEnabled(Feature.atcLocation, inputs.userRole);
  const dispatch = useAppDispatch();
  const showPowerTransformer = (
    feature: ExistingNetworkAsset<TransformerProperties>
  ): boolean => {
    switch (feature.properties?.Mounting) {
      case PowerTransformerMounting.pmt:
        return userRole !== UserRole.public;
      case PowerTransformerMounting.gmt:
        return toggles.layers.includes(layerOptionValues.acceptedToConnect);
      default:
        return false;
    }
  };

  useEffect(() => {
    if (name !== layerOptionValues.acceptedToConnect || showAcceptedToConnect)
      updateFeatures();
  }, []);

  const updateFeatures = async () => {
    let features = await getLayer(
      urls,
      layerType,
      totalkVA,
      connectionPoints,
      undefined,
      map.getBounds()
    );
    switch (name) {
      case layerOptionValues.conductingSections:
        dispatch(updateConductingSections(features));
        break;
      case layerOptionValues.overheadPoles:
        dispatch(updateOverheadPoles(features));
        break;
      case layerOptionValues.powerTransformers:
        dispatch(
          updatePowerTransformers(
            features as ExistingNetworkAsset<TransformerProperties>[]
          )
        );
        break;
      case layerOptionValues.ehvs:
        dispatch(updateEhvs(features));
        break;
      case layerOptionValues.hvs:
        dispatch(updateHvs(features));
        break;
      case layerOptionValues.thirdParties:
        dispatch(updateThirdParties(features));
        break;
      case layerOptionValues.lvMains:
        dispatch(updateLVMains(features));
        break;
      case layerOptionValues.wayleaves:
        dispatch(updateWayleaves(features));
        break;
      case layerOptionValues.lvServices:
        dispatch(updateServiceCables(features));
        break;
      case layerOptionValues.acceptedToConnect:
        dispatch(updateAcceptedToConnects(features));
    }
  };

  useMapEvents({
    moveend() {
      if (name !== layerOptionValues.acceptedToConnect || showAcceptedToConnect)
        updateFeatures();
    },
  });

  const getCableLabelText = (asset: any) => {
    return `      ${asset.properties?.p20}      `;
  };

  const featureClickHandler = (feature: any, type: layerOptionValues) => {
    dispatch(
      setSelectedAsset({
        ...feature,
        ...{ assetType: type },
      })
    );
  };

  const getFeatureColor = (feature: any) => {
    return appMode === AppMode.screenshot || appMode === AppMode.quotationPlan
      ? "blue"
      : feature.properties.color;
  };

  return (
    <>
      {name === layerOptionValues.conductingSections &&
        conductingSections.length > 0 &&
        conductingSections
          .filter((f) => f.properties?.Class === "underground-cable")
          .map((feature: any) => (
            <Fragment key={feature.id}>
              {showCableLabels ? (
                <TextPath
                  key={feature.id}
                  positions={feature.polyGeometry}
                  text={getCableLabelText(feature)}
                  color={getFeatureColor(feature)}
                  weight={2}
                  repeat
                  below
                  center
                  offset={14}
                  eventHandlers={{
                    click: () => {
                      featureClickHandler(
                        feature,
                        layerOptionValues.conductingSections
                      );
                    },
                  }}
                />
              ) : (
                <Polyline
                  key={feature.id}
                  positions={feature.polyGeometry}
                  pathOptions={{ color: getFeatureColor(feature) }}
                  weight={2}
                  eventHandlers={{
                    click: () => {
                      featureClickHandler(
                        feature,
                        layerOptionValues.conductingSections
                      );
                    },
                  }}
                ></Polyline>
              )}
            </Fragment>
          ))}
      {name === layerOptionValues.conductingSections &&
        conductingSections.length > 0 &&
        conductingSections
          .filter((f) => f.properties?.Class === "overhead-line")
          .map((feature: any) => (
            <Fragment key={feature.id}>
              {showCableLabels ? (
                <TextPath
                  key={feature.id}
                  positions={feature.polyGeometry}
                  text={getCableLabelText(feature)}
                  color={getFeatureColor(feature)}
                  weight={2}
                  dashArray={[5, 5]}
                  repeat
                  below
                  center
                  offset={14}
                  eventHandlers={{
                    click: () => {
                      featureClickHandler(
                        feature,
                        layerOptionValues.conductingSections
                      );
                    },
                  }}
                />
              ) : (
                <Polyline
                  key={feature.id}
                  positions={feature.polyGeometry}
                  pathOptions={{
                    color: getFeatureColor(feature),
                  }}
                  weight={2}
                  dashArray={[5, 5]}
                  eventHandlers={{
                    click: () => {
                      featureClickHandler(
                        feature,
                        layerOptionValues.conductingSections
                      );
                    },
                  }}
                ></Polyline>
              )}
            </Fragment>
          ))}
      {name === layerOptionValues.overheadPoles &&
        overheadPoles.map((feature) => (
          <OverheadPole
            key={feature.id}
            userRole={userRole}
            overheadPole={feature}
            color={getFeatureColor(feature)}
          />
        ))}
      {name === layerOptionValues.powerTransformers &&
        powerTransformers.map(
          (feature) =>
            showPowerTransformer(feature) && (
              <PowerTransformer
                key={feature.id}
                powerTransformer={feature}
                color={getFeatureColor(feature)}
              />
            )
        )}
      {name === layerOptionValues.ehvs && (
        <GeometryTypeFilter
          showAsset={showEhvs}
          features={ehvs}
          layer={layerOptionValues.ehvs}
        />
      )}
      {name === layerOptionValues.hvs && (
        <GeometryTypeFilter
          showAsset={showHvs}
          features={hvs}
          layer={layerOptionValues.hvs}
          onClick={featureClickHandler}
        />
      )}
      {name === layerOptionValues.thirdParties && (
        <GeometryTypeFilter
          showAsset={showThirdParties}
          features={thirdparties}
          layer={layerOptionValues.thirdParties}
          onClick={featureClickHandler}
        />
      )}
      {name === layerOptionValues.lvMains && (
        <GeometryTypeFilter
          showAsset={showLVMains}
          features={lvmains}
          layer={layerOptionValues.lvMains}
          onClick={featureClickHandler}
        />
      )}
      {name === layerOptionValues.wayleaves && (
        <GeometryTypeFilter
          showAsset={showWayleaves}
          features={wayleaves}
          layer={layerOptionValues.wayleaves}
        />
      )}
      {name === layerOptionValues.lvServices && (
        <GeometryTypeFilter
          showAsset={showServiceCables}
          features={serviceCables}
          layer={layerOptionValues.lvServices}
          onClick={featureClickHandler}
        />
      )}
      {showAcceptedToConnect &&
        name === layerOptionValues.acceptedToConnect &&
        acceptedToConnects.map((feature: ExistingNetworkAsset) => (
          <ATCRequest acceptedToConnect={feature} />
        ))}
    </>
  );
};

export default ExistingNetworkAssets;
