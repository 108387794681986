import { useFeatureSwitch } from "../../hooks/useFeatureSwitch";
import { Feature, UserRole } from "../../models/enums";

interface FeatureSwitchProps {
  children: JSX.Element;
  feature: Feature;
  userRole: string;
}

export const FeatureSwitch = ({
  children,
  feature,
  userRole,
}: FeatureSwitchProps) => {
  const isEnabled = useFeatureSwitch(feature, userRole as UserRole);
  return isEnabled ? children : null;
};
